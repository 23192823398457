import { Outlet } from 'react-router-dom';
import { Stack, CssBaseline, Snackbar, Alert, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import SideBar from '../../components/SideBar';
import ViewContainer from '../../components/ViewContainer';
import usePageData from '../../../../store/pageData/hooks/usePageData';
import useAlert from '../../../../store/alert/hooks/useAlert';

export default () => {
  const { loading } = usePageData();
  const { alertMessage, modalOpen, success, closeModal } = useAlert();

  const action = <IconButton size="small" aria-label="close" color="inherit" onClick={closeModal}><CloseIcon fontSize="small" /></IconButton>;

  return (
    <>
      <CssBaseline />
      <Stack direction="row">
        <SideBar />
        {!loading && (
          <Stack direction="column" justifyContent="flex-end" alignItems="flex-end">
            <ViewContainer>
              <Outlet />

              {success && (
                <Snackbar
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                  open={modalOpen}
                  autoHideDuration={2000}
                  onClose={closeModal}
                  message={alertMessage}
                  key="success"
                  action={action}
                />
              )}

              {!success && (
                <Snackbar
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  open={modalOpen}
                  autoHideDuration={6000}
                  key="error"
                  onClose={closeModal}
                >
                  <Alert onClose={closeModal} severity="error">{alertMessage}</Alert>
                </Snackbar>
              )}
            </ViewContainer>
          </Stack>
        )}
      </Stack>
    </>
  );
};
