import useSWR from 'swr';
import useAuth from '../../../store/auth/hooks/useAuth';
import usePageData from '../../../store/pageData/hooks/usePageData';
import httpClient from '../../../utils/httpClient';
import { Product } from './types';
import { PACKAGES_API } from './constants';

const useQuery = () => {
  const { jwt } = useAuth();
  const {
    pageTaxonomies,
    categoryTaxonomies,
    subcategoryTaxonomies,
    categoryDropdownItems,
  } = usePageData();

  const fetchList = (page: number, query: string = '') => {
    const url = `${PACKAGES_API}?page=${page}&search_key=${query}`;
    const fetcher = () => httpClient({ url, jwt }).then((res: { data: Product[], meta: any, message: any }) => res);
    const { data, error } = useSWR(url, fetcher);

    return {
      data: {
        ...data,
        pageTaxonomies,
        categoryTaxonomies,
        subcategoryTaxonomies,
        categoryDropdownItems,
      },
      loading: !error && !data,
      isError: error,
    };
  };

  const fetchDetails = (id: number | null) => {
    const url = `${PACKAGES_API}/${id}`;
    const key = id === null ? null : url;
    const fetcher = () => httpClient({ url, jwt }).then((res: { data: Product, message: any }) => res);
    const { data, error } = useSWR(key, fetcher);

    return {
      data: {
        ...data,
        pageTaxonomies,
        categoryTaxonomies,
        subcategoryTaxonomies,
        categoryDropdownItems,
      },
      loading: !error && !data && id !== null,
      isError: error,
    };
  };

  return {
    fetchList,
    fetchDetails,
  };
};

export default useQuery;
