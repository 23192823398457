import AddIcon from '@material-ui/icons/Add';
import DefaultIconButton from '../../../../@common/components/forms/DefaultIconButton';
import useModal from '../../../store/hooks/useModal';
import PublishContentModal from './PublishContentModal';

export default ({ text }: any) => {
  const { modal, openModal, closeModal } = useModal();

  return (
    <>
      <DefaultIconButton onClick={openModal} startIcon={<AddIcon />} text={text} />
      <PublishContentModal open={modal.open} handleClose={closeModal} />
    </>
  );
};
