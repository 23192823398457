/* eslint-disable no-unused-vars */
export enum OperationState {
  INACTIVE = 'inactive',
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum ModalStatus {
  OPEN = 'open',
  CLOSE = 'close',
}
