/* eslint-disable import/prefer-default-export */
import { Banner } from './types';
import dateToJST from '../../../utils/date';

interface IMapperProp {
  data: any
}

interface IListMapperProp extends IMapperProp {
  banner: Banner
}

export const mapJsonListToObjectList = ({ data, banner }: IListMapperProp) => {
  const banners: Banner[] = [];

  data.data.pages.forEach((i: any) => {
    banners.push({
      id: i.id,
      name: i.name,
      html: i.page_explanation,
      path: i.path,
      bannerImageDesktop: i.banner,
      bannerImageDesktopUrl: `${process.env.REACT_APP_CDN_URL}/${i.banner}`,
      bannerImageMobile: i.mobile_banner,
      bannerImageMobileUrl: `${process.env.REACT_APP_CDN_URL}/${i.mobile_banner}`,
      showBanner: i.show_banner,
      needAuth: i.need_auth,
      createdAt: dateToJST(i.created_at),
      selected: i.id === banner?.id,
    });
  });

  return { ...data, data: banners };
};

export const mapJsonToObject = ({ data }: IMapperProp) => {
  const banner = {} as Banner;
  banner.id = data.id;
  banner.name = data.name;
  banner.html = data.page_explanation;
  banner.path = data.path;
  banner.bannerImageDesktop = data.banner;
  banner.bannerImageDesktopUrl = `${process.env.REACT_APP_CDN_URL}/${data.banner}`;
  banner.bannerImageMobile = data.mobile_banner;
  banner.bannerImageMobileUrl = `${process.env.REACT_APP_CDN_URL}/${data.mobile_banner}`;
  banner.showBanner = data.show_banner;
  banner.needAuth = data.need_auth;

  return banner;
};

export const mapFormFieldsToObject = ({ data }: IMapperProp) => ({
  id: data.id.value,
  name: data.name.value,
  html: data.html.value,
  path: data.path.value,
  bannerImageDesktop: data.bannerImageDesktop.value,
  bannerImageDesktopUrl: `${process.env.REACT_APP_CDN_URL}/${data.bannerImageDesktop.value}`,
  bannerImageMobile: data.bannerImageMobile.value,
  bannerImageMobileUrl: `${process.env.REACT_APP_CDN_URL}/${data.bannerImageMobile.value}`,
  showBanner: data.showBanner.value,
  needAuth: data.needAuth.value,
  createdAt: dateToJST(Date()),
  selected: true,
});
