import Placeholder from '../../../@common/components/details/Placeholder';
import ViewContainer from './components/ViewContainer';
import useProductSelect from '../../store/hooks/useProductSelect';
import useLocal from '../../../../store/local/hooks/useLocal';

export default () => {
  const { renderDefault } = useProductSelect();
  const { text } = useLocal();

  if (renderDefault) return <Placeholder placeholder={text.package.details.defaultText} />;

  return <ViewContainer />;
};
