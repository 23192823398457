import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  Tooltip,
} from '@material-ui/core/';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import InfoIcon from '@material-ui/icons/Info';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Banner } from '../../../store/types';
import useLocal from '../../../../../store/local/hooks/useLocal';

interface IProp {
  item: Banner
  select: Function
}

export default ({ item, select }: IProp) => {
  const { text } = useLocal();
  return (
    <ListItem
      key={`key-${item.id}`}
      button
      divider
      selected={item.selected}
      onClick={() => select(item)}
      disableRipple
    >
      <ListItemAvatar>
        <ListItemAvatar>
          <Avatar variant="circular" alt={item.name}><InfoIcon sx={{ fontSize: 40 }} /></Avatar>
        </ListItemAvatar>
      </ListItemAvatar>
      <ListItemText
        primary={<Typography variant="caption" component="p">{item.name}</Typography>}
        secondary={(
          <Typography variant="caption" component="span" sx={{ display: 'block', fontSize: '0.55rem' }}>
            {item.createdAt}
          </Typography>
        )}
      />
      {item.showBanner
        ? <Tooltip title={text.pages.list.tooltipVisible}><VisibilityIcon sx={{ color: '#888' }} fontSize="small" /></Tooltip>
        : <Tooltip title={text.pages.list.tooltipHidden}><VisibilityOffIcon sx={{ color: '#888' }} fontSize="small" /></Tooltip>}
    </ListItem>
  );
};
