import { useEffect } from 'react';
import AuthenticatedPages from './AuthenticatedPages';
import useAuth from '../../../../store/auth/hooks/useAuth';

export default () => {
  const { user, getAuthenticatedUser } = useAuth();

  useEffect(() => { getAuthenticatedUser(); }, []);

  return user ? <AuthenticatedPages /> : <></>;
};
