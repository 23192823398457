import { useEffect } from 'react';
import AuthenticatedPage from './AuthenticatedPage';
import useAuth from '../../../../../store/auth/hooks/useAuth';

export default () => {
  const { user, getAuthenticatedUser } = useAuth();

  useEffect(() => { getAuthenticatedUser(); }, []);

  return user ? <AuthenticatedPage /> : <></>;
};
