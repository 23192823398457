import { useStateValue } from '../index';
import {
  newProductStarted,
  editProductStarted,
  draftProductStarted,
  productModalClose,
} from '../actions';
import { FormWriteStatus } from '../constants';

const useProductModal = () => {
  const { state, dispatch } = useStateValue();

  const openProductModal = () => dispatch(newProductStarted());
  const openDraftProductModal = () => dispatch(draftProductStarted());
  const closeProductModal = () => dispatch(productModalClose());
  const openProductModalForEdit = () => dispatch(editProductStarted());

  return {
    modal: {
      open: state.productModalOpen,
      showDelete: state.productFormState === FormWriteStatus.EDIT_PUBLISHED,
      action: state.productFormState.includes('create') ? 'create' : 'edit',
      create: state.productFormState === FormWriteStatus.CREATE_DRAFT,
      edit: state.productFormState === FormWriteStatus.EDIT_DRAFT
        || state.productFormState === FormWriteStatus.EDIT_PUBLISHED,
    },
    openProductModal,
    openDraftProductModal,
    closeProductModal,
    openProductModalForEdit,
  };
};

export default useProductModal;
