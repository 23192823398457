import { API } from 'aws-amplify';
import useSWR from 'swr';
import useAuth from '../../../store/auth/hooks/useAuth';

const useQuery = () => {
  const { jwt } = useAuth();

  const fetchList = () => {
    const apiName = 'AdminQueries';
    const path = '/listDeploymentLogs';

    const headers: any = {};
    headers['Content-Type'] = 'application/json';
    headers.Authorization = jwt;

    const payload: any = {};
    payload.headers = headers;

    const fetcher = () => API.get(apiName, path, payload).then((res: any) => res);
    const { data, error, isValidating } = useSWR('deployments', fetcher, { dedupingInterval: 10000, refreshInterval: 15000 });

    return {
      data,
      loading: !error && !data,
      isValidating,
      isError: error,
    };
  };

  return { fetchList };
};

export default useQuery;
