import { Stack } from '@material-ui/core/';

interface IProp {
  children?: any
  justifyContent?: string
}

export default ({ children, justifyContent = 'space-between' }: IProp) => (
  <Stack sx={{ px: 3, height: '48px', background: '#dadada' }} direction="row" justifyContent={justifyContent} alignItems="center" spacing={3}>
    {children}
  </Stack>
);
