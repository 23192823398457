import { styled } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const StyledButton = styled(Button)({
  background: '#e0e0e0',
  borderColor: '#e0e0e0',
  color: '#444',
  minWidth: '90px',
  '&:hover': {
    color: '#333',
    background: '#e0e0e0',
    borderColor: '#e0e0e0',
  },
});

interface IProp {
  text: string
  type?: any
  fullWidth?: boolean
  disabled?: boolean
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

export default ({
  text,
  type = 'button',
  fullWidth = false,
  disabled = false,
  onClick,
}: IProp) => (
  <StyledButton
    type={type}
    fullWidth={fullWidth}
    disabled={disabled}
    variant="outlined"
    onClick={onClick}
  >
    {text}
  </StyledButton>
);
