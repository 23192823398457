/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { Stack, Grid, Box, Container } from '@material-ui/core';
import {
  SectionTitle,
  FullpageDialog,
  FullpageLoader,
  FullWidthTextField,
  ConfirmationDialog,
} from '../../../../../@common/components/forms/index';
import useModal from '../../../store/hooks/useModal';
import useFormSubmit from '../../../store/hooks/useFormSubmit';
import useForm from '../../../store/hooks/useForm';
import useLocal from '../../../../../../store/local/hooks/useLocal';

export default () => {
  const { modal, closeModal } = useModal();
  const { text } = useLocal();
  const { user, submitting, submitForm, deleteUser } = useFormSubmit();

  // DELETE dialog (todo: move this out into its own custom hook)
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const handleConfirmationOpen = () => setOpenConfirmation(true);
  const handleConfirmationClose = () => setOpenConfirmation(false);
  const handleDeleteConfirmation = () => {
    setOpenConfirmation(false);
    deleteUser();
  };

  const getFormFields = (edit: boolean) => ({
    email: { value: edit ? user.email : '', required: true, hasError: false },
    familyName: { value: edit ? user.familyName : '', required: true, hasError: false },
    givenName: { value: edit ? user.givenName : '', required: true, hasError: false },
    sub: { value: edit ? user.sub : '', required: false, hasError: false },
    userStatus: { value: edit ? user.userStatus : '', required: false, hasError: false },
    username: { value: edit ? user.username : '', required: false, hasError: false },
    password: { value: '', required: !edit, hasError: false },
    enabled: { value: edit ? user.enabled : '', required: false, hasError: false },
    createdDate: { value: edit ? user.createdDate : '', required: false, hasError: false },
  });

  const {
    formFields,
    setValue,
    setFormFields,
    submit,
  } = useForm({
    initialValue: getFormFields(modal.edit),
    onSubmit: (fields: any) => submitForm({ fields }),
  });

  useEffect(() => { setFormFields(getFormFields(modal.edit)); }, [modal.open]);

  return (
    <>
      <FullpageDialog
        open={modal.open}
        showDelete={modal.showDelete}
        submitText={text.appBarButtons[modal.action]}
        deleteText={text.appBarButtons.delete}
        appBarTitle={text.admin.appBar.admin[modal.action]}
        onClose={closeModal}
        onSubmit={submit}
        onConfirmDelete={handleConfirmationOpen}
      >
        <Container maxWidth="sm">
          <Box my={16} />
          <SectionTitle text={text.admin.form.title} />

          <Stack direction="column" justifyContent="flex-start">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FullWidthTextField
                  value={formFields.givenName.value}
                  title={text.admin.form.firstNameLabel}
                  onSave={(value: any) => setValue('givenName', value)}
                  hasError={formFields.givenName.hasError}
                  errorMessage={text.admin.form.firstNameErrorMessage}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FullWidthTextField
                  value={formFields.familyName.value}
                  title={text.admin.form.lastNameLabel}
                  onSave={(value: any) => setValue('familyName', value)}
                  hasError={formFields.familyName.hasError}
                  errorMessage={text.admin.form.lastNameErrorMessage}
                />
              </Grid>
            </Grid>
            <FullWidthTextField
              value={formFields.email.value}
              title={text.admin.form.emailLabel}
              onSave={(value: any) => setValue('email', value)}
              hasError={formFields.email.hasError}
              errorMessage={text.admin.form.emailErrorMessage}
            />
            {modal.action === 'create' && (
              <FullWidthTextField
                type="password"
                value={formFields.password.value}
                title={text.admin.form.temporaryPasswordLabel}
                onSave={(value: any) => setValue('password', value)}
                hasError={formFields.password.hasError}
                errorMessage={text.admin.form.temporaryPassworderrorMessage}
                autoComplete="new-password"
              />
            )}
          </Stack>

          <Box my={16} />
        </Container>

        <FullpageLoader open={submitting} />
      </FullpageDialog>

      <ConfirmationDialog
        open={openConfirmation}
        onClose={handleConfirmationClose}
        onConfirm={handleDeleteConfirmation}
        title={text.admin.deleteModal.title}
        content={text.admin.deleteModal.body}
        no={text.admin.deleteModal.cancel}
        ok={text.admin.deleteModal.confirm}
      />
    </>
  );
};
