/* eslint-disable react/require-default-props */
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import teal from '@material-ui/core/colors/teal';

const useStyles = makeStyles((theme) => ({
  default: {
    textTransform: 'none',
    color: theme.palette.text.primary,
    background: theme.palette.background.default,
    outline: 'none',
    '&:hover': { background: theme.palette.background.paper },
    marginLeft: '5px',
  },
  primary: {
    textTransform: 'none',
    color: 'white',
    background: teal[500],
    outline: 'none',
    '&:hover': { background: teal[800], borderColor: teal[800] },
    marginLeft: '5px',
    borderColor: teal[500],
  },
}));

interface IProp {
  text: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  startIcon?: any
  endIcon?: any
  disabled?: boolean
  primary?: boolean
}

export default ({
  text, onClick = () => { },
  startIcon = null,
  endIcon = null,
  disabled = false,
  primary = false,
}: IProp) => {
  const classes = useStyles();
  return (
    <Button
      size="small"
      variant="outlined"
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
      disableRipple
      disabled={disabled}
      disableElevation
      className={primary ? classes.primary : classes.default}
    >
      <Typography variant="caption">{text}</Typography>
    </Button>
  );
};
