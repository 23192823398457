import { ReactNode, useRef } from 'react';
import {
  Box,
  Stack,
  Divider,
} from '@material-ui/core/';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

const styles = { width: '30vw', minWidth: '400px', px: 2, height: '48px' };
const inputBaseStyle = { ml: 1, flex: 1, fontSize: '.80rem' };

const searchInputStyle = {
  my: '10px',
  padding: '0',
  display: 'flex',
  alignItems: 'center',
  bgcolor: 'rgba(85, 85, 85, 0.1)',
  borderRadius: '2px',
  flex: 1,
};

interface IProp {
  newButton: ReactNode
  placeholder: string
  setQuery: Function
  withSearchResult: boolean
}

export default ({ newButton, placeholder, setQuery, withSearchResult }: IProp) => {
  const searchInputRef = useRef<HTMLInputElement>();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setQuery(searchInputRef.current!.value);
  };

  const handleReset = () => {
    searchInputRef.current!.value = '';
    setQuery('');
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Stack sx={styles} direction="row" justifyContent="space-between" alignItems="stretch">
          <Box component="span" sx={searchInputStyle}>
            <InputBase
              inputRef={searchInputRef}
              sx={inputBaseStyle}
              placeholder={placeholder}
              inputProps={{ 'aria-label': 'search package' }}
            />
            {withSearchResult === true && (
              <IconButton onClick={handleReset} size="small" sx={{ p: '5px' }} aria-label="search">
                <CloseIcon sx={{ fontSize: '1rem' }} />
              </IconButton>
            )}
            <Divider sx={{ height: 16 }} orientation="vertical" />
            <IconButton onClick={handleSubmit} size="small" sx={{ p: '5px' }} aria-label="search">
              <SearchIcon sx={{ fontSize: '1rem' }} />
            </IconButton>
          </Box>
          <Stack direction="row" spacing={1} alignItems="center">
            {newButton}
          </Stack>
        </Stack>
      </form>
      <Divider />
    </>
  );
};
