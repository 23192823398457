import { SET_PRODUCTS, SET_PAGES, SET_STUDENTS, SET_ADMINS } from './actions';

export const INITIAL_STATE = { products: null, pages: null, students: null, admins: null };

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_PRODUCTS: return { ...state, products: action.payload };
    case SET_PAGES: return { ...state, pages: action.payload };
    case SET_STUDENTS: return { ...state, students: action.payload };
    case SET_ADMINS: return { ...state, admins: action.payload };
    default: return state;
  }
};
