export const LIST_LOADING = 'banner/LIST_LOADING';
export const LIST_LOADED_SUCCESS = 'banner/LIST_LOADED_SUCCESS';
export const LIST_LOADED_ERROR = 'banner/LIST_LOADED_ERROR';
export const pageLoadingStarted = () => ({ type: LIST_LOADING });
export const pageLoadedWithSuccess = (payload: any) => ({ payload, type: LIST_LOADED_SUCCESS });

export const DETAILS_SELECTED = 'banner/DETAILS_SELECTED';
export const DETAILS_LOADING = 'banner/DETAILS_LOADING';
export const DETAILS_LOADED_CACHE = 'banner/DETAILS_LOADED_CACHE';
export const DETAILS_LOADED_SUCCESS = 'banner/DETAILS_LOADED_SUCCESS';
export const DETAILS_LOADED_ERROR = 'banner/DETAILS_LOADED_ERROR';
export const bannerSelected = (payload: any) => ({ payload, type: DETAILS_SELECTED });
export const bannerDetailsWillLoad = () => ({ type: DETAILS_LOADING });
export const bannerDetailsLoadedWithSuccess = (payload: any) => ({ payload, type: DETAILS_LOADED_SUCCESS });
export const bannerDetailsLoadedWithCache = () => ({ type: DETAILS_LOADED_CACHE });

export const BANNER_DELETING = 'banner/BANNER_DELETING';
export const BANNER_DELETED_SUCCESS = 'banner/BANNER_DELETED_SUCCESS';
export const bannerDeleteConfirmed = () => ({ type: BANNER_DELETING });
export const bannerDeletedWithSuccess = () => ({ type: BANNER_DELETED_SUCCESS });

export const FORM_INACTIVE = 'banner/FORM_INACTIVE';
export const FORM_SUBMITTING = 'banner/FORM_SUBMITTING';
export const FORM_SUBMITTED_SUCCESS = 'banner/FORM_SUBMITTED_SUCCESS';
export const FORM_SUBMITTED_ERROR = 'banner/FORM_SUBMITTED_ERROR';
export const formReset = () => ({ type: FORM_INACTIVE });
export const formSubmitted = () => ({ type: FORM_SUBMITTING });
export const formSubmittedWithSuccess = (payload: any) => ({ payload, type: FORM_SUBMITTED_SUCCESS });
export const formSubmittedWithError = () => ({ type: FORM_SUBMITTED_ERROR });

export const MODAL_NEW_OPEN = 'banner/MODAL_NEW_OPEN';
export const MODAL_EDIT_OPEN = 'banner/MODAL_EDIT_OPEN';
export const newBannerStarted = () => ({ type: MODAL_NEW_OPEN });
export const editBannerStarted = () => ({ type: MODAL_EDIT_OPEN });

export const MODAL_CLOSE = 'banner/MODAL_CLOSE';
export const modalClose = () => ({ type: MODAL_CLOSE });
