/* eslint-disable no-unused-vars */
export enum ListStatus {
  LOADING = 'loading',
  LOADED_SUCCESS = 'loaded_success',
  LOADED_ERROR = 'loaded_error',
  RELOADING = 'reloading',
  RELOADED_SUCCESS = 'reloaded_success',
}

export enum FormWriteStatus {
  CREATE_DRAFT = 'create_draft',
  CREATE_PUBLISHED = 'create_published',
  EDIT_DRAFT = 'edit_draft',
  EDIT_PUBLISHED = 'edit_published',
}

export enum FormStatus {
  INACTIVE = 'inactive',
  SUBMITTING = 'submitting',
  SUBMITTED_SUCCESS = 'submitted_success',
  SUBMITTED_ERROR = 'submitted_error',
}

export enum DetailsStatus {
  EMPTY = 'empty',
  SELECTED = 'selected',
  LOADING = 'loading',
  LOADED_SUCCESS = 'loaded_success',
  LOADED_ERROR = 'loaded_error',
}

export enum UserStatus {
  PENDING = 'FORCE_CHANGE_PASSWORD',
  CONFIRMED = 'CONFIRMED',
}
