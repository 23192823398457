/* eslint-disable no-unused-vars */
import { useEffect } from 'react';
import { Stack, Typography, Fab } from '@material-ui/core';
import { makeStyles, styled } from '@material-ui/core/styles';
import LoadingButton from '@material-ui/lab/LoadingButton';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import CloseIcon from '@material-ui/icons/Close';
import ImagePlaceholder from './ImagePlaceholder';
import ErrorText from '../../../../../@common/components/forms/ErrorText';
import useImageUpload from '../../../../../@common/hooks/useImageUpload';

const useStyles = makeStyles((theme) => ({
  actionButton: {
    maxHeight: '30px',
    textTransform: 'none',
    color: theme.palette.text.primary,
    outline: 'none',
  },
  img: { border: '1px dashed #aaa', width: '100%', height: '180px' },
  closeFab: {
    position: 'absolute',
    top: 16,
    right: 8,
    color: '#fff',
    minHeight: '24px',
    width: '24px',
    height: '24px',
  },
}));

const HiddenInput = styled('input')({ display: 'none' });

interface IProp {
  'label': string
  'inputId': string
  'defaultValue': string
  'dimensionText': string
  'chooseImageText': string
  'onUpload': Function
  'hasError': boolean
  'errorMessage': string
}

export default ({
  label,
  inputId,
  defaultValue,
  dimensionText,
  chooseImageText,
  onUpload,
  hasError,
  errorMessage,
}: IProp) => {
  const classes = useStyles();

  const { imageKey, uploading, upload } = useImageUpload();

  useEffect(() => { if (!uploading && imageKey) onUpload(imageKey); }, [uploading, imageKey]);

  return (
    <>
      <Typography variant="caption" component="p">{label}</Typography>
      <Stack sx={{ mt: 1, mb: 2, position: 'relative' }} direction="column" alignItems="center" justifyContent="center" spacing={1}>
        {!defaultValue && (
          <ImagePlaceholder>
            <CloudUploadIcon />
            <HiddenInput
              accept="image/*"
              id={inputId}
              type="file"
              onChange={(e: any) => upload(e.target.files[0])}
            />
            <label htmlFor={inputId}>
              <LoadingButton variant="outlined" fullWidth component="span" loading={uploading} className={classes.actionButton}>
                <Typography variant="caption">{chooseImageText}</Typography>
              </LoadingButton>
            </label>
            <Typography variant="caption">{dimensionText}</Typography>
          </ImagePlaceholder>
        )}

        {defaultValue && (
          <>
            <Fab
              className={classes.closeFab}
              color="secondary"
              size="small"
              aria-label="delete"
              onClick={() => onUpload('')}
            >
              <CloseIcon sx={{ fontSize: '1rem' }} />
            </Fab>
            <img className={classes.img} src={`${process.env.REACT_APP_CDN_URL}/${defaultValue}`} alt={defaultValue} />
          </>
        )}

        {hasError && <ErrorText text={errorMessage} />}
      </Stack>
    </>
  );
};
