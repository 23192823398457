/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import useSWR from 'swr';
import useAuth from '../../store/auth/hooks/useAuth';
import httpClient from '../../utils/httpClient';
import { Page, Category, Subcategory, Role } from './types';

const PAGE_INFO_API = `${process.env.REACT_APP_API_URL}/page_informations`;

const useQuery = () => {
  const { jwt } = useAuth();

  const fetchPageData = () => {
    const url = PAGE_INFO_API;
    const fetcher = () => httpClient({ url, jwt })
      .then((
        res: {
          data: {
            pages: Page[],
            categories: Category[],
            subcategories: Subcategory[],
            roles: Role[],
          }
        },
      ) => res);
    const { data, error } = useSWR(url, fetcher);

    return {
      data: {
        pages: data ? data.data.pages : [],
        categories: data ? data.data.categories : [],
        subcategories: data ? data.data.subcategories : [],
        roles: data ? data.data.roles : [],
      },
      loading: !error && !data,
      isError: error,
    };
  };

  return { fetchPageData };
};

export default useQuery;
