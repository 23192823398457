import { Paper, Box } from '@material-ui/core/';
import DefaultIconButton from '../../../../../@common/components/forms/DefaultIconButton';
import useLocal from '../../../../../../store/local/hooks/useLocal';
import useModal from '../../../store/hooks/useModal';
import useSelect from '../../../store/hooks/useSelect';
import Placeholder from '../../../../../@common/components/details/Placeholder';
import ContentViewContainer from '../../../../../@common/components/details/ContentViewContainer';
import HeaderViewContainer from '../../../../../@common/components/details/HeaderViewContainer';
import DetailsView from './Details';

export default () => {
  const { text } = useLocal();
  const { user } = useSelect();
  const { openModalForEdit } = useModal();

  if (user === null) return <Placeholder placeholder={text.admin.details.defaultText} />;

  return (
    <Box>
      <HeaderViewContainer justifyContent="flex-end">
        <DefaultIconButton onClick={openModalForEdit} text={text.buttons.edit} />
      </HeaderViewContainer>
      <ContentViewContainer>
        <Paper sx={{ my: 4, p: 2 }}>
          <DetailsView user={user} />
        </Paper>
      </ContentViewContainer>
    </Box>
  );
};
