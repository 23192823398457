interface IProp {
  url: string
  jwt: string
  method?: string
  body?: any
}
export default ({ url, jwt, method, body }: IProp) => {
  const payload: RequestInit = {};
  payload.method = method ?? 'GET';
  payload.body = method === 'POST' || method === 'PUT' ? JSON.stringify(body) : null;
  payload.headers = {
    'Content-Type': 'application/json',
    Authorization: jwt,
  };

  return fetch(url, payload).then((res) => res.json());
};
