/* eslint-disable no-unused-vars */
export enum ListStatus {
  LOADING = 'loading',
  LOADED_SUCCESS = 'loaded_success',
  LOADED_ERROR = 'loaded_error',
  SEARCHING = 'searching',
  SEARCH_SUCCESS = 'search_success',
  SEARCH_ERROR = 'search_error',
  RELOADING = 'reloading',
  RELOADED_SUCCESS = 'reloaded_success',
}

export enum FormWriteStatus {
  CREATE_DRAFT = 'create_draft',
  CREATE_PUBLISHED = 'create_published',
  EDIT_DRAFT = 'edit_draft',
  EDIT_PUBLISHED = 'edit_published',
}

export enum FormStatus {
  INACTIVE = 'inactive',
  SUBMITTING = 'submitting',
  SUBMITTED_SUCCESS = 'submitted_success',
  SUBMITTED_ERROR = 'submitted_error',
}

export enum DetailsStatus {
  EMPTY = 'empty',
  SELECTED = 'selected',
  LOADING = 'loading',
  LOADED_SUCCESS = 'loaded_success',
  LOADED_ERROR = 'loaded_error',
}

export enum PublishStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published',
}

const BASE_API_URL = `${process.env.REACT_APP_API_URL}`;
export const PACKAGES_API = `${BASE_API_URL}/packages`;
export const MATERIALS_API = `${BASE_API_URL}/materials`;
export const MATERIAL_URLS_API = `${BASE_API_URL}/material_urls`;
