import Header from './components/Header';
import Page from './components/Page';
import NewButton from './components/NewButton';
import useAdmins from '../../store/hooks/useAdmins';
import useLocal from '../../../../../store/local/hooks/useLocal';

export default () => {
  const { text } = useLocal();
  const { users, loading } = useAdmins();

  return (
    <div>
      <Header
        headerText={text.admin.list.title}
        newButton={<NewButton text={text.buttons.create} />}
      />
      <Page loading={loading} users={users} />
    </div>
  );
};
