/* eslint-disable no-unused-vars */
import {
  ListStatus,
  FormWriteStatus,
  DetailsStatus,
  FormStatus,
} from './constants';

import {
  LIST_LOADING,
  LIST_LOADED_SUCCESS,
  LIST_LOADED_ERROR,

  DETAILS_SELECTED,
  DETAILS_LOADING,
  DETAILS_LOADED_SUCCESS,
  DETAILS_LOADED_ERROR,

  FORM_INACTIVE,
  FORM_SUBMITTING,
  FORM_SUBMITTED_SUCCESS,
  FORM_SUBMITTED_ERROR,

  MODAL_OPEN,
  MODAL_CLOSE,
} from './actions';

export const INITIAL_STATE = {
  list: [],
  listItem: null,
  modalOpen: false,
  listState: ListStatus.LOADING,
  listItemDetailsState: DetailsStatus.EMPTY,
  formState: FormStatus.INACTIVE,
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case LIST_LOADING:
      return {
        ...state,
        listState: ListStatus.LOADING,
        list: [],
      };
    case LIST_LOADED_SUCCESS:
      return {
        ...state,
        listState: ListStatus.LOADED_SUCCESS,
        list: action.payload,
      };
    case DETAILS_SELECTED:
      return {
        ...state,
        list: action.payload.list,
        listItem: action.payload.listItem,
        listItemDetailsState: DetailsStatus.SELECTED,
      };
    case DETAILS_LOADING:
      return {
        ...state,
        listItemDetailsState: DetailsStatus.LOADING,
      };
    case DETAILS_LOADED_SUCCESS:
      return {
        ...state,
        list: action.payload.list,
        listItem: action.payload.listItem,
        listItemDetailsState: DetailsStatus.LOADED_SUCCESS,
      };
    case FORM_INACTIVE:
      return {
        ...state,
        formState: FormStatus.INACTIVE,
      };
    case FORM_SUBMITTING:
      return {
        ...state,
        formState: FormStatus.SUBMITTING,
      };
    case FORM_SUBMITTED_SUCCESS:
      return {
        ...state,
        formState: FormStatus.SUBMITTED_SUCCESS,
        modalOpen: false,
      };
    case FORM_SUBMITTED_ERROR:
      return {
        ...state,
        formState: FormStatus.SUBMITTED_ERROR,
        modalOpen: false,
      };
    case MODAL_OPEN:
      return {
        ...state,
        modalOpen: true,
      };
    case MODAL_CLOSE:
      return {
        ...state,
        modalOpen: false,
      };
    default:
      return state;
  }
};
