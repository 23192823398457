/* eslint-disable no-unused-vars */
import { useStateValue } from '../../index';
import { ModalStatus, OperationState } from '../constants';
import {
  modalClose,
  asyncOperationCompletedWithError,
  asyncOperationCompletedWithSuccess,
} from '../actions';
import useLocal from '../../local/hooks/useLocal';

const useAlert = () => {
  const { state, dispatch } = useStateValue();
  const { text } = useLocal();
  const { defaultErrorMessage, defaultSuccessMessage } = text.alerts;

  const closeModal = () => dispatch(modalClose());
  const showFailureAlert = (message = defaultErrorMessage) => dispatch(asyncOperationCompletedWithError(message));
  const showSuccessAlert = (message = defaultSuccessMessage) => dispatch(asyncOperationCompletedWithSuccess(message));

  return {
    alertMessage: state.alert.alertMessage,
    modalOpen: state.alert.modalState === ModalStatus.OPEN,
    success: state.alert.operationState === OperationState.SUCCESS,
    closeModal,
    showFailureAlert,
    showSuccessAlert,
  };
};

export default useAlert;
