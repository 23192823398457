import { useState } from 'react';
import {
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

const Title = styled(Typography)(({ theme }) => ({ color: theme.palette.text.primary }));

const TextInput = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(2),
  background: '#fff',
}));

export default ({
  value,
  title,
  placeholder,
  onSave,
}: {
  value: any
  title: string
  placeholder: string
  onSave: Function
}) => {
  const [checked, setChecked] = useState(value?.length > 0);
  const handleChange = (e: any) => {
    const newValue = e.target.value;
    onSave(newValue);
  };
  const handleCheck = (e: any) => {
    const newValue = e.target.checked;
    if (!newValue) {
      onSave('');
    }
    setChecked(newValue);
  };

  return (
    <>
      <FormControlLabel
        control={(<Checkbox checked={checked} onChange={handleCheck} inputProps={{ 'aria-label': 'small' }} size="small" />)}
        label={<Title variant="caption">{title}</Title>}
      />
      <TextInput
        disabled={!checked}
        id="outlined-size-small"
        size="small"
        value={value ?? ''}
        fullWidth
        placeholder={placeholder}
        onChange={handleChange}
      />
    </>
  );
};
