import { Grid, Box, Typography } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import { Deployment } from '../../../store/types';
import useLocal from '../../../../../store/local/hooks/useLocal';

const useStyles = makeStyles({
  label: {
    lineHeight: 0,
    fontSize: '.65rem',
    fontWeight: 'bold',
  },
  htmlContent: {
    fontSize: '.65rem',
    background: '#fafafa',
    border: '1px solid #ccc',
    padding: '5px 10px',
    minHeight: '1.7rem',
  },
});

export default ({ listItem }: { listItem: Deployment }) => {
  const classes = useStyles();
  const { text } = useLocal();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={2}>
          <Typography variant="overline" className={classes.label}>{text.publish.details.emailLabel}</Typography>
        </Grid>
        <Grid item sm={10}>
          <Typography variant="body2" className={classes.htmlContent}>{listItem?.email}</Typography>
        </Grid>
      </Grid>

      <Box mt={2} />

      <Grid container spacing={2}>
        <Grid item sm={2}>
          <Typography variant="overline" className={classes.label}>{text.publish.details.statusLabel}</Typography>
        </Grid>
        <Grid item sm={10}>
          <Typography variant="body2" className={classes.htmlContent}>
            {text.publish.details.statusEnum[listItem?.status]}
          </Typography>
        </Grid>
      </Grid>

      <Box mt={2} />

      <Grid container spacing={2}>
        <Grid item sm={2}>
          <Typography variant="overline" className={classes.label}>{text.publish.details.productCountLabel}</Typography>
        </Grid>
        <Grid item sm={10}>
          <Typography variant="body2" className={classes.htmlContent}>
            {listItem?.productCount}
          </Typography>
        </Grid>
      </Grid>

      <Box mt={2} />

      <Grid container spacing={2}>
        <Grid item sm={2}>
          <Typography variant="overline" className={classes.label}>{text.publish.details.publishDateLabel}</Typography>
        </Grid>
        <Grid item sm={10}>
          <Typography variant="body2" className={classes.htmlContent}>{listItem?.publishDate}</Typography>
        </Grid>
      </Grid>
    </>
  );
};
