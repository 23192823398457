/* eslint-disable no-empty */
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStateValue } from '../../index';
import { setUser } from '../actions';
import {
  authenticate,
  completeNewPassword,
  checkUserSession,
  clearSession,
  IProp,
} from '../mutations';

const useAuth = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useStateValue();
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const user = state?.auth?.user;
  const jwt = user?.signInUserSession?.accessToken?.jwtToken;

  const getAuthenticatedUser = async () => {
    let session = null;

    try {
      session = await checkUserSession();
    } catch (err) {
      navigate('/login');
    }

    dispatch(setUser(session));
  };

  const authenticateUser = async ({ email, password }: IProp) => {
    setHasError(false);
    setIsLoading(true);
    let session = null;

    try {
      session = await authenticate({ email, password });

      const toPage = (session?.challengeName === 'NEW_PASSWORD_REQUIRED')
        ? '/newpassword'
        : '/products';

      setIsLoading(false);
      navigate(toPage);
    } catch (err) {
      session = err;
      setHasError(true);
      setIsLoading(false);
    }

    dispatch(setUser(session));
  };

  const setNewPassword = async ({ password }: { password: string }) => {
    setHasError(false);
    setIsLoading(true);
    let response = null;

    try {
      response = await completeNewPassword({ user, password });
      setIsLoading(false);
      navigate('/products');
    } catch (err) {
      response = err;
      setIsLoading(false);
      setHasError(true);
    }

    dispatch(setUser(response));
  };

  const logoutUser = async () => {
    try {
      await clearSession();
    } catch (_) { }
    navigate('/login', { replace: true });
    dispatch(setUser(null));
  };

  return {
    user,
    jwt,
    isLoading,
    hasError,
    authenticateUser,
    setNewPassword,
    getAuthenticatedUser,
    logoutUser,
  };
};

export default useAuth;
