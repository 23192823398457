/* eslint-disable max-len */
export const PAGINATION_UPDATED = 'product/PAGINATION_UPDATED';
export const paginated = (payload: any) => ({ payload, type: PAGINATION_UPDATED });

export const SEARCH_LOADING = 'product/SEARCH_LOADING';
export const SEARCH_LOADED_SUCCESS = 'product/SEARCH_LOADED_SUCCESS';
export const searchLoadingStarted = (payload: any) => ({ payload, type: SEARCH_LOADING });
export const searchLoadedWithSuccess = (payload: any) => ({ payload, type: SEARCH_LOADED_SUCCESS });

export const LIST_LOADING = 'product/LIST_LOADING';
export const LIST_LOADED_SUCCESS = 'product/LIST_LOADED_SUCCESS';
export const LIST_LOADED_ERROR = 'product/LIST_LOADED_ERROR';
export const pageLoadingStarted = () => ({ type: LIST_LOADING });
export const pageLoadedWithSuccess = (payload: any) => ({ payload, type: LIST_LOADED_SUCCESS });

export const DETAILS_SELECTED = 'product/DETAILS_SELECTED';
export const DETAILS_LOADING = 'product/DETAILS_LOADING';
export const DETAILS_LOADED_CACHE = 'product/DETAILS_LOADED_CACHE';
export const DETAILS_LOADED_SUCCESS = 'product/DETAILS_LOADED_SUCCESS';
export const DETAILS_LOADED_ERROR = 'product/DETAILS_LOADED_ERROR';
export const productSelected = (payload: any) => ({ payload, type: DETAILS_SELECTED });
export const productDetailsWillLoad = () => ({ type: DETAILS_LOADING });
export const productDetailsLoadedWithSuccess = (payload: any) => ({ payload, type: DETAILS_LOADED_SUCCESS });
export const productDetailsLoadedWithCache = () => ({ type: DETAILS_LOADED_CACHE });

export const DRAFT_PRODUCT_ADDED = 'product/DRAFT_PRODUCT_ADDED';
export const DRAFT_PRODUCT_UPDATED = 'product/DRAFT_PRODUCT_UPDATED';
export const DRAFT_PRODUCT_DELETED = 'product/DRAFT_PRODUCT_DELETED';
export const PRODUCT_DELETING = 'product/PRODUCT_DELETING';
export const PRODUCT_DELETED_SUCCESS = 'product/PRODUCT_DELETED_SUCCESS';
export const draftProductAdded = (payload: any) => ({ payload, type: DRAFT_PRODUCT_ADDED });
export const draftProductUpdated = (payload: any) => ({ payload, type: DRAFT_PRODUCT_UPDATED });
export const draftProductDeleted = (payload: any) => ({ payload, type: DRAFT_PRODUCT_DELETED });
export const productDeleteConfirmed = () => ({ type: PRODUCT_DELETING });
export const productDeletedWithSuccess = () => ({ type: PRODUCT_DELETED_SUCCESS });

export const DRAFT_MATERIAL_ADDED = 'product/DRAFT_MATERIAL_ADDED';
export const DRAFT_MATERIAL_UPDATED = 'product/DRAFT_MATERIAL_UPDATED';
export const DRAFT_MATERIAL_DELETED = 'product/DRAFT_MATERIAL_DELETED';
export const MATERIAL_DELETING = 'product/MATERIAL_DELETING';
export const MATERIAL_DELETED_SUCCESS = 'product/MATERIAL_DELETED_SUCCESS';
export const draftMaterialAdded = (payload: any) => ({ payload, type: DRAFT_MATERIAL_ADDED });
export const draftMaterialUpdated = (payload: any) => ({ payload, type: DRAFT_MATERIAL_UPDATED });
export const draftMaterialDeleted = (payload: any) => ({ payload, type: DRAFT_MATERIAL_DELETED });
export const materialDeleteConfirmed = () => ({ type: MATERIAL_DELETING });
export const materialDeletedWithSuccess = (payload: any) => ({ payload, type: MATERIAL_DELETED_SUCCESS });

export const DRAFT_ASSET_ADDED = 'product/DRAFT_ASSET_ADDED';
export const DRAFT_ASSET_UPDATED = 'product/DRAFT_ASSET_UPDATED';
export const DRAFT_ASSET_DELETED = 'product/DRAFT_ASSET_DELETED';
export const ASSET_DELETING = 'product/ASSET_DELETING';
export const ASSET_DELETED_SUCCESS = 'product/ASSET_DELETED_SUCCESS';
export const draftAssetAdded = (payload: any) => ({ payload, type: DRAFT_ASSET_ADDED });
export const draftAssetUpdated = (payload: any) => ({ payload, type: DRAFT_ASSET_UPDATED });
export const draftAssetDeleted = (payload: any) => ({ payload, type: DRAFT_ASSET_DELETED });
export const assetDeleteConfirmed = () => ({ type: ASSET_DELETING });
export const assetDeletedWithSuccess = (payload: any) => ({ payload, type: ASSET_DELETED_SUCCESS });

export const FORM_INACTIVE = 'product/FORM_INACTIVE';
export const FORM_SUBMITTING = 'product/FORM_SUBMITTING';
export const FORM_SUBMITTED_SUCCESS = 'product/FORM_SUBMITTED_SUCCESS';
export const FORM_SUBMITTED_ERROR = 'product/FORM_SUBMITTED_ERROR';
export const formReset = () => ({ type: FORM_INACTIVE });
export const formSubmitted = () => ({ type: FORM_SUBMITTING });
export const formSubmittedWithSuccess = (payload: any) => ({ payload, type: FORM_SUBMITTED_SUCCESS });
export const formSubmittedWithError = () => ({ type: FORM_SUBMITTED_ERROR });

export const MODAL_PRODUCT_NEW_OPEN = 'product/MODAL_PRODUCT_NEW_OPEN';
export const MODAL_PRODUCT_EDIT_OPEN = 'product/MODAL_PRODUCT_EDIT_OPEN';
export const MODAL_PRODUCT_DRAFT_OPEN = 'product/MODAL_PRODUCT_DRAFT_OPEN';
export const newProductStarted = () => ({ type: MODAL_PRODUCT_NEW_OPEN });
export const editProductStarted = () => ({ type: MODAL_PRODUCT_EDIT_OPEN });
export const draftProductStarted = () => ({ type: MODAL_PRODUCT_DRAFT_OPEN });

export const MODAL_MATERIAL_NEW_OPEN = 'product/MODAL_MATERIAL_NEW_OPEN';
export const MODAL_MATERIAL_ADD_OPEN = 'product/MODAL_MATERIAL_ADD_OPEN';
export const MODAL_MATERIAL_EDIT_OPEN = 'product/MODAL_MATERIAL_EDIT_OPEN';
export const MODAL_MATERIAL_DRAFT_OPEN = 'product/MODAL_MATERIAL_DRAFT_OPEN';
export const newMaterialStarted = () => ({ type: MODAL_MATERIAL_NEW_OPEN });
export const addMaterialStarted = () => ({ type: MODAL_MATERIAL_ADD_OPEN });
export const editMaterialStarted = (payload: any) => ({ payload, type: MODAL_MATERIAL_EDIT_OPEN });
export const draftMaterialStarted = (payload: any) => ({ payload, type: MODAL_MATERIAL_DRAFT_OPEN });

export const MODAL_ASSET_NEW_OPEN = 'product/MODAL_ASSET_NEW_OPEN';
export const MODAL_ASSET_ADD_OPEN = 'product/MODAL_ASSET_ADD_OPEN';
export const MODAL_ASSET_EDIT_OPEN = 'product/MODAL_ASSET_EDIT_OPEN';
export const MODAL_ASSET_DRAFT_OPEN = 'product/MODAL_ASSET_DRAFT_OPEN';
export const newAssetStarted = (payload: any) => ({ payload, type: MODAL_ASSET_NEW_OPEN });
export const addAssetStarted = (payload: any) => ({ payload, type: MODAL_ASSET_ADD_OPEN });
export const editAssetStarted = (payload: any) => ({ payload, type: MODAL_ASSET_EDIT_OPEN });
export const draftAssetStarted = (payload: any) => ({ payload, type: MODAL_ASSET_DRAFT_OPEN });

export const MODAL_PRODUCT_CLOSE = 'product/MODAL_PRODUCT_CLOSE';
export const MODAL_MATERIAL_CLOSE = 'product/MODAL_MATERIAL_CLOSE';
export const MODAL_ASSET_CLOSE = 'product/MODAL_ASSET_CLOSE';
export const productModalClose = () => ({ type: MODAL_PRODUCT_CLOSE });
export const materialModalClose = () => ({ type: MODAL_MATERIAL_CLOSE });
export const assetModalClose = () => ({ type: MODAL_ASSET_CLOSE });
