import { useStateValue } from '../index';
import { modalOpen, modalClose } from '../actions';

const useModal = () => {
  const { state, dispatch } = useStateValue();
  const closeModal = () => dispatch(modalClose());
  const openModal = () => dispatch(modalOpen());

  return {
    modal: { open: state.modalOpen },
    closeModal,
    openModal,
  };
};

export default useModal;
