import { useStateValue } from '../index';
import { newUserStarted, editUserStarted, modalClose } from '../actions';
import { FormWriteStatus } from '../constants';

const useModal = () => {
  const { state, dispatch } = useStateValue();

  const closeModal = () => dispatch(modalClose());
  const openModal = () => dispatch(newUserStarted());
  const openModalForEdit = () => dispatch(editUserStarted());

  return {
    modal: {
      open: state.modalOpen,
      showDelete: state.userFormState === FormWriteStatus.EDIT_PUBLISHED,
      action: state.userFormState.includes('create') ? 'create' : 'edit',
      edit: state.userFormState === FormWriteStatus.EDIT_PUBLISHED,
    },
    closeModal,
    openModal,
    openModalForEdit,
  };
};

export default useModal;
