import { Box } from '@material-ui/core/';

export default ({ children }: any) => (
  <Box sx={{
    width: 'calc(55vw)',
    height: 'calc(100vh - 48px)',
    overflowY: 'scroll',
    bgcolor: '#eaeaea',
    px: 3,
  }}
  >
    {children}
  </Box>
);
