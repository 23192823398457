import Header from './components/Header';
import Page from './components/Page';
import NewButton from './components/NewButton';
import useFetchList from '../../store/hooks/useFetchList';
import useLocal from '../../../../store/local/hooks/useLocal';

export default () => {
  const { text } = useLocal();
  const { list, loading } = useFetchList();

  return (
    <div>
      <Header
        headerText={text.publish.list.title}
        newButton={<NewButton text={text.publish.list.publishButtonText} />}
      />
      <Page loading={loading} list={list} />
    </div>
  );
};
