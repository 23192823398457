import { Backdrop, CircularProgress } from '@material-ui/core';

export default ({ open }: {open: boolean}) => (
  <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={open}
  >
    <CircularProgress color="inherit" />
  </Backdrop>
);
