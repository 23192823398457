/* eslint-disable no-unused-vars */
import { mutate } from 'swr';
import { Asset } from '../types';
import { useStateValue } from '../index';
import {
  draftAssetAdded,
  draftAssetUpdated,
  formSubmitted,
  formSubmittedWithSuccess,
  formSubmittedWithError,
  draftAssetDeleted,
  assetDeleteConfirmed,
  assetDeletedWithSuccess,
} from '../actions';
import {
  FormWriteStatus,
  FormStatus,
  PACKAGES_API,
  MATERIALS_API,
  MATERIAL_URLS_API,
} from '../constants';
import { mapAssetFormFieldsToAsset } from '../mappers';
import httpClient from '../../../../utils/httpClient';
import useAuth from '../../../../store/auth/hooks/useAuth';
import useAlert from '../../../../store/alert/hooks/useAlert';

const useAssetForm = () => {
  const { jwt } = useAuth();
  const { state, dispatch } = useStateValue();
  const { products, product } = state;
  const { showSuccessAlert, showFailureAlert } = useAlert();

  const handle500 = ({ _ }: any) => {
    dispatch(formSubmittedWithError());
    showFailureAlert();
  };
  const refetch = () => mutate(`${PACKAGES_API}/${product.id}`);

  const submitForm = (data: any) => {
    const asset = mapAssetFormFieldsToAsset({ data });

    if (state.assetFormState === FormWriteStatus.CREATE_DRAFT) {
      const material = product.materials.find((m: any) => m.selected);
      const assets = [...material.assets, { ...asset, materialId: material.id }];
      const materials = product.materials.map((m: any) => (m.selected ? { ...material, assets } : m));
      const updatedProducts = products.map((p: any) => (p.id === product.id ? { ...product, selected: true, materials } : p));
      dispatch(draftAssetAdded({ products: updatedProducts, product: { ...product, materials } }));
      return;
    }

    if (state.assetFormState === FormWriteStatus.EDIT_DRAFT) {
      const material = product.materials.find((m: any) => m.id === asset.materialId);
      const assets = material.assets.map((a: any) => (a.id === asset.id ? asset : a));
      const materials = product.materials.map((m: any) => (m.id === asset.materialId ? { ...material, assets } : m));
      const updatedProducts = products.map((p: any) => (p.id === product.id ? { ...product, selected: true, materials } : p));
      dispatch(draftAssetUpdated({ products: updatedProducts, product: { ...product, materials } }));
      return;
    }

    if (state.assetFormState === FormWriteStatus.EDIT_PUBLISHED) {
      const { id } = asset;
      const url = `${MATERIAL_URLS_API}/${id}`;

      const body: any = {};
      body.title = asset.title;
      body.content = asset.content;
      body.type = asset.type;
      body.size = asset.size;
      body.buttonType = asset.buttonType;
      body.instruction = asset.instruction;
      body.label = asset.label;

      const handle200 = (response: any) => {
        if (response.data) {
          const material = product.materials.find((m: any) => m.id === asset.materialId);
          const assets = material.assets.map((a: any) => (a.id === asset.id ? asset : a));
          const materials = product.materials.map((m: any) => (m.id === asset.materialId ? { ...material, assets } : m));
          const updatedProduct = { ...product, selected: true, materials };
          const updatedProducts = products.map((p: any) => (p.id === product.id ? updatedProduct : p));
          dispatch(formSubmittedWithSuccess({ products: updatedProducts, product: updatedProduct }));
          refetch();
          showSuccessAlert();
        } else if (response.message) {
          dispatch(formSubmittedWithError());
          showFailureAlert();
        }
      };

      dispatch(formSubmitted());

      httpClient({ url, jwt, method: 'PUT', body })
        .then((res: any) => handle200(res))
        .catch((res: any) => handle500(res));

      return;
    }

    if (state.assetFormState === FormWriteStatus.CREATE_PUBLISHED) {
      const { id } = product.materials.find((m: any) => m.selected);
      const url = `${MATERIALS_API}/${id}/material_urls`;

      const body: any = {};
      body.title = asset.title;
      body.content = asset.content;
      body.type = asset.type;
      body.size = asset.size;
      body.buttonType = asset.buttonType;
      body.instruction = asset.instruction;
      body.label = asset.label;

      const handle200 = (response: any) => {
        if (response.data) {
          const newId = response.data.id;
          const material = product.materials.find((m: any) => m.id === id);
          const assets = [...material.assets, { ...asset, id: newId }];
          const materials = product.materials.map((m: any) => (m.id === id ? { ...material, assets } : m));
          const updatedProduct = { ...product, selected: true, materials };
          const updatedProducts = products.map((p: any) => (p.id === product.id ? updatedProduct : p));
          dispatch(formSubmittedWithSuccess({ products: updatedProducts, product: updatedProduct }));
          refetch();
          showSuccessAlert();
        } else if (response.message) {
          dispatch(formSubmittedWithError());
          showFailureAlert();
        }
      };

      dispatch(formSubmitted());

      httpClient({ url, jwt, method: 'POST', body })
        .then((res: any) => handle200(res))
        .catch((res: any) => handle500(res));
    }
  };

  const deleteDraftAsset = (asset: Asset) => {
    const material = product.materials.find((m: any) => m.id === asset.materialId);
    const assets = material.assets.filter((a: any) => a.id !== asset.id);
    const materials = product.materials.map((m: any) => (m.id === asset.materialId ? { ...material, assets } : m));
    const updatedProducts = products.map((p: any) => (p.id === product.id ? { ...product, materials } : p));
    dispatch(draftAssetDeleted({ products: updatedProducts, product: { ...product, materials } }));
  };

  const deleteAsset = () => {
    const { id, materialId } = state.asset;
    const url = `${MATERIAL_URLS_API}/${id}`;
    dispatch(assetDeleteConfirmed());

    const handle200 = (response: any) => {
      if (response.data) {
        const material = product.materials.find((m: any) => m.id === materialId);
        const assets = material.assets.filter((a: any) => a.id !== id);
        const materials = product.materials.map((m: any) => (m.id === materialId ? { ...material, assets } : m));
        const updatedProducts = products.map((p: any) => (p.id === product.id ? { ...product, selected: true, materials } : p));
        dispatch(assetDeletedWithSuccess({ products: updatedProducts, product: { ...product, materials } }));
        refetch();
        showSuccessAlert();
      } else if (response.message) {
        dispatch(formSubmittedWithError());
        showFailureAlert();
      }
    };

    httpClient({ url, jwt, method: 'DELETE' })
      .then((res: any) => handle200(res))
      .catch((res) => handle500(res));
  };

  return {
    submitting: state.formState === FormStatus.SUBMITTING,
    submittedWithError: state.formState === FormStatus.SUBMITTED_ERROR,
    submitForm,
    deleteAsset,
    deleteDraftAsset,
  };
};

export default useAssetForm;
