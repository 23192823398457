import { useStateValue } from '../index';
import { draftAssetStarted, editAssetStarted } from '../actions';
import { Asset } from '../types';

const useAssetSelect = () => {
  const { state, dispatch } = useStateValue();

  return {
    asset: state.asset as Asset,
    selectAssetForEdit: (asset: Asset) => dispatch(editAssetStarted(asset)),
    selectDraftAssetForEdit: (asset: Asset) => dispatch(draftAssetStarted(asset)),
  };
};

export default useAssetSelect;
