import { LOGIN } from './actions';

export const INITIAL_STATE = { user: null };

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case LOGIN:
      return { user: action.payload };
    default:
      return state;
  }
};
