import {
  Typography,
  Box,
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import ReactQuill from 'react-quill';
import ErrorText from './ErrorText';

import 'react-quill/dist/quill.snow.css';

const Title = styled(Typography)(({ theme }) => ({ color: theme.palette.text.primary }));

const Editor = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(2),
  background: '#fff',
}));

interface IProp {
  'value': any
  'title': string
  'onSave': Function
  'hasError': boolean
  'errorMessage': string
}

export default ({
  value,
  title,
  onSave,
  hasError,
  errorMessage,
}: IProp) => {
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', { color: [] }, { list: 'ordered' }, { list: 'bullet' }, { align: [] }, 'link', 'video'],
    ],
  };

  return (
    <>
      <Title variant="caption">{title}</Title>
      <Editor>
        <ReactQuill theme="snow" value={value} onChange={(html) => onSave(html)} modules={modules} />
        {hasError && <ErrorText text={errorMessage} />}
      </Editor>
    </>
  );
};
