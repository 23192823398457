/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import { useState } from 'react';

interface IProp {
  initialValue: any
  onSubmit: Function
}

const useForm = ({ initialValue, onSubmit }: IProp) => {
  const [formFields, setFormFields] = useState<any>(initialValue);

  const validate = () => {
    const validateField = (field: any) => field.required && (field.value?.length === 0 || field.value === 'None');
    let fields = { ...formFields };
    let errorCount = 0;

    Object
      .entries(fields)
      .forEach(([name, _]: any) => {
        const hasError = validateField(fields[name]);
        const fieldValue = { ...fields[name], hasError };
        fields = { ...fields, [name]: fieldValue };

        errorCount = hasError ? errorCount + 1 : errorCount;
      });

    setFormFields({ ...fields });

    return errorCount === 0;
  };

  const setValue = (field: any, value: any) => {
    setFormFields({
      ...formFields,
      [field]: { ...formFields[field], value },
    });
  };

  const submit = () => {
    if (validate()) {
      onSubmit(formFields);
    }
  };

  return {
    formFields,
    submit,
    setValue,
    setFormFields,
  };
};

export default useForm;
