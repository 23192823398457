/* eslint-disable import/prefer-default-export */
import { Deployment } from './types';
import dateToJST from '../../../utils/date';

export const mapJsonListToObjectList = ({ data }: any) => {
  const listItem: Deployment[] = [];

  data.data.forEach((i: any) => listItem.push({
    id: i.id,
    email: i.email,
    productCount: i.productCount,
    status: i.status,
    inProgress: i.status === 'INPROGRESS',
    publishDate: dateToJST(i.publishDate),
  }));

  return listItem;
};
