import { List } from '@material-ui/core/';
import { Product } from '../../../store/types';
import PageItem from './PageItem';
import Loader from './Loader';
import useProductSelect from '../../../store/hooks/useProductSelect';

const styles = {
  width: '30vw',
  minWidth: '400px',
  height: 'calc(100vh - 102px)',
  bgcolor: 'background.paper',
  overflow: 'scroll',
  py: 0,
};

interface IProp {
  products: Product[]
  loading?: boolean
}

export default ({ products, loading }: IProp) => {
  const { select } = useProductSelect();

  const PageItemsView = products.map((i) => <PageItem select={select} key={`${i.title}-${i.id}`} item={i} />);
  const ListView = <List sx={styles}>{PageItemsView}</List>;

  return loading ? <Loader /> : <>{ListView}</>;
};
