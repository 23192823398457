import { useEffect } from 'react';
import { useStateValue } from '../index';
import { pageDataLoadingStarted, pageDataLoadedWithSuccess } from '../actions';
import useQuery from '../queries';
import { mapStudentListToStudent } from '../mapper';
import useList from '../../../../../store/list/hooks/useList';

const useStudents = (page:number) => {
  const { fetchList } = useQuery();
  const { setStudentsCount } = useList();

  const { state, dispatch } = useStateValue();
  const { data, loading } = fetchList(page);
  const totalCount = data?.meta?.totalCount;

  const setData = () => {
    const students = mapStudentListToStudent({
      data: data || [],
      roles: data.roleTaxonomies,
    });

    setStudentsCount(data?.meta?.totalCount);
    dispatch(pageDataLoadedWithSuccess(students));
  };

  useEffect(() => {
    if (loading) dispatch(pageDataLoadingStarted());
    if (!loading) setData();
  }, [totalCount, page, loading]);

  return {
    students: state.students,
    pagination: state.pagination,
    loading,
  };
};

export default useStudents;
