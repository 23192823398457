import { styled } from '@material-ui/core/styles';
import { Typography, Divider, Box } from '@material-ui/core';

const Title = styled(Typography)(({ theme }) => ({ color: theme.palette.text.primary }));

export default ({ text }: { 'text': string}) => (
  <>
    <Title variant="h6">{text}</Title>
    <Divider light />
    <Box mb={2} />
  </>
);
