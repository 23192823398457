/* eslint-disable max-len */
import useSWR from 'swr';
import useAuth from '../../../store/auth/hooks/useAuth';
import httpClient from '../../../utils/httpClient';
import { Banner } from './types';
import { PAGE_BANNERS_API } from './constants';

const useQuery = () => {
  const { jwt } = useAuth();

  const fetchList = () => {
    const url = `${PAGE_BANNERS_API}`;
    const fetcher = () => httpClient({ url, jwt }).then((res: { data: Banner[], meta: any }) => res);
    const { data, error } = useSWR(url, fetcher);

    return {
      data,
      loading: !error && !data,
      isError: error,
    };
  };

  const fetchDetails = (id: number | null) => {
    const url = `${PAGE_BANNERS_API}/${id}`;
    const key = id === null ? null : url;
    const fetcher = () => httpClient({ url, jwt }).then((res: { data: Banner }) => res);
    const { data, error } = useSWR(key, fetcher);

    return {
      data: data?.data,
      loading: !error && !data && id !== null,
      isError: error,
    };
  };

  return {
    fetchList,
    fetchDetails,
  };
};

export default useQuery;
