import Placeholder from '../../../../@common/components/details/Placeholder';
import ViewContainer from './components/ViewContainer';
import useStudentSelect from '../../store/hooks/useStudentSelect';
import useLocal from '../../../../../store/local/hooks/useLocal';

export default () => {
  const { renderDefault } = useStudentSelect();
  const { text } = useLocal();

  if (renderDefault) return <Placeholder placeholder={text.student.details.defaultText} />;

  return <ViewContainer />;
};
