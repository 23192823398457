import { useEffect } from 'react';
import { useStateValue } from '../index';
import { studentDetailsWillLoad, studentDetailsLoadedWithSuccess } from '../actions';
import { mapStudentDetailsToStudent } from '../mapper';
import useQuery from '../queries';

const useStudent = () => {
  const { fetchDetails } = useQuery();
  const { state, dispatch } = useStateValue();
  const { id } = state.student;
  const { data, loading } = fetchDetails(id);

  const getData = () => {
    const student = mapStudentDetailsToStudent({
      data: data || [],
      roles: data.roleTaxonomies,
    });

    const students = state.students.map((s: any) => (s.id === student.id
      ? { ...student, selected: s.selected, createdAt: s.createdAt }
      : s));

    return { students, student };
  };

  const detailsEventHandler = () => {
    if (id === null) {
      // don't care;
    } else if (loading) {
      dispatch(studentDetailsWillLoad());
    } else {
      dispatch(studentDetailsLoadedWithSuccess(getData()));
    }
  };

  useEffect(() => { detailsEventHandler(); }, [id, loading]);

  return {
    student: state.student,
    renderLoading: loading,
  };
};

export default useStudent;
