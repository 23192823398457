/* eslint-disable no-unused-vars */

import {
  ListStatus,
  FormWriteStatus,
  DetailsStatus,
  FormStatus,
} from './constants';

import {
  LIST_LOADING,
  LIST_LOADED_SUCCESS,
  LIST_LOADED_ERROR,

  DETAILS_SELECTED,
  DETAILS_LOADING,
  DETAILS_LOADED_SUCCESS,
  DETAILS_LOADED_ERROR,

  FORM_INACTIVE,
  FORM_SUBMITTING,
  FORM_SUBMITTED_SUCCESS,
  FORM_SUBMITTED_ERROR,

  MODAL_NEW_OPEN,
  MODAL_EDIT_OPEN,
  MODAL_CLOSE,

  USER_DELETING,
  USER_DELETED_SUCCESS,
} from './actions';

export const INITIAL_STATE = {
  users: [],
  user: null,
  modalOpen: false,
  listState: ListStatus.LOADING,
  userFormState: FormWriteStatus.CREATE_DRAFT,
  userDetailsState: DetailsStatus.EMPTY,
  formState: FormStatus.INACTIVE,
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case LIST_LOADING:
      return {
        ...state,
        listState: ListStatus.LOADING,
        users: [],
      };
    case LIST_LOADED_SUCCESS:
      return {
        ...state,
        listState: ListStatus.LOADED_SUCCESS,
        users: action.payload,
      };
    case DETAILS_SELECTED:
      return {
        ...state,
        user: action.payload.user,
        users: action.payload.users,
        formState: FormStatus.INACTIVE,
        userDetailsState: DetailsStatus.SELECTED,
      };
    case DETAILS_LOADING:
      return {
        ...state,
        userDetailsState: DetailsStatus.LOADING,
      };
    case DETAILS_LOADED_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        users: action.payload.users,
        userDetailsState: DetailsStatus.LOADED_SUCCESS,
      };
    case FORM_INACTIVE:
      return {
        ...state,
        formState: FormStatus.INACTIVE,
      };
    case FORM_SUBMITTING:
      return {
        ...state,
        formState: FormStatus.SUBMITTING,
      };
    case FORM_SUBMITTED_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        users: action.payload.users,
        formState: FormStatus.SUBMITTED_SUCCESS,
        userFormState: FormWriteStatus.CREATE_DRAFT,
        modalOpen: false,
      };
    case FORM_SUBMITTED_ERROR:
      return {
        ...state,
        formState: FormStatus.SUBMITTED_ERROR,
      };
    case MODAL_NEW_OPEN:
      return {
        ...state,
        modalOpen: true,
        userFormState: FormWriteStatus.CREATE_DRAFT,
      };
    case MODAL_EDIT_OPEN:
      return {
        ...state,
        modalOpen: true,
        userFormState: FormWriteStatus.EDIT_PUBLISHED,
      };
    case MODAL_CLOSE:
      return {
        ...state,
        modalOpen: false,
        userFormState: FormWriteStatus.CREATE_DRAFT,
      };
    case USER_DELETING:
      return {
        ...state,
        formState: FormStatus.SUBMITTING,
      };
    case USER_DELETED_SUCCESS:
      return {
        ...state,
        user: null,
        modalOpen: false,
        formState: FormStatus.SUBMITTED_SUCCESS,
        userFormState: FormWriteStatus.CREATE_DRAFT,
        userDetailsState: DetailsStatus.EMPTY,
      };
    default:
      return state;
  }
};
