/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import { mutate } from 'swr';
import { Material } from '../types';
import { useStateValue } from '../index';
import {
  formSubmitted,
  formSubmittedWithSuccess,
  formSubmittedWithError,
  draftMaterialAdded,
  draftMaterialUpdated,
  draftMaterialDeleted,
  materialDeleteConfirmed,
  materialDeletedWithSuccess,
} from '../actions';
import {
  FormWriteStatus,
  FormStatus,
  PACKAGES_API,
  MATERIALS_API,
} from '../constants';
import { mapMaterialFormFieldsToMaterial } from '../mappers';
import httpClient from '../../../../utils/httpClient';
import useAuth from '../../../../store/auth/hooks/useAuth';
import useAlert from '../../../../store/alert/hooks/useAlert';

const useMaterialForm = () => {
  const { jwt } = useAuth();
  const { state, dispatch } = useStateValue();
  const { products, product } = state;
  const { showSuccessAlert, showFailureAlert } = useAlert();

  const handle500 = ({ _ }: any) => {
    dispatch(formSubmittedWithError());
    showFailureAlert();
  };
  const refetch = () => mutate(`${PACKAGES_API}/${product.id}`);

  const deleteMaterial = () => {
    const { id } = state.material;
    const url = `${MATERIALS_API}/${id}`;

    const handle200 = (response: any) => {
      if (response.data) {
        const materials = product.materials.filter((m: any) => m.id !== id);
        const updatedProducts = products.map((p: any) => (p.id === product.id ? { ...product, selected: true, materials } : p));
        dispatch(materialDeletedWithSuccess({ products: updatedProducts, product: { ...product, materials } }));
        refetch();
        showSuccessAlert();
      } else if (response.message) {
        dispatch(formSubmittedWithError());
        showFailureAlert();
      }
    };

    dispatch(materialDeleteConfirmed());

    httpClient({ url, jwt, method: 'DELETE' })
      .then((res: any) => handle200(res))
      .catch((res) => handle500(res));
  };

  const deleteDraftMaterial = (material: Material) => {
    const { id } = material;
    const materials = product.materials.filter((m: any) => m.id !== id);
    const updatedProducts = products.map((p: any) => (p.id === product.id ? { ...product, selected: true, materials } : p));
    dispatch(draftMaterialDeleted({ products: updatedProducts, product: { ...product, materials } }));
  };

  const submitForm = (data: any) => {
    const material = mapMaterialFormFieldsToMaterial({ data });

    if (state.materialFormState === FormWriteStatus.CREATE_DRAFT) {
      const materials = [...state.product.materials, material];
      const updatedProducts = products.map((p: any) => (p.id === product.id ? { ...product, selected: true, materials } : p));
      dispatch(draftMaterialAdded({ products: updatedProducts, product: { ...product, materials } }));
      return;
    }

    if (state.materialFormState === FormWriteStatus.EDIT_DRAFT) {
      const materials = product.materials.map((m: any) => (m.id === material.id ? { ...material, assets: m.assets } : m));
      const updatedProducts = products.map((p: any) => (p.id === product.id ? { ...product, selected: true, materials } : p));
      dispatch(draftMaterialUpdated({ products: updatedProducts, product: { ...product, materials } }));
      return;
    }

    if (state.materialFormState === FormWriteStatus.EDIT_PUBLISHED) {
      const { id } = material;
      const url = `${MATERIALS_API}/${id}`;

      const body: any = {};
      body.productId = material.productId;
      body.title = material.title;
      body.header = material.header;
      body.image = material.image;
      body.htmlData = material.html;

      const handle200 = (response: any) => {
        if (response.data) {
          const materials = product.materials.map((m: any) => (m.id === material.id ? { ...material, assets: m.assets } : m));
          const updatedProduct = { ...product, selected: true, materials };
          const updatedProducts = products.map((p: any) => (p.id === product.id ? updatedProduct : p));
          dispatch(formSubmittedWithSuccess({ products: updatedProducts, product: updatedProduct }));
          refetch();
          showSuccessAlert();
        } else if (response.message) {
          dispatch(formSubmittedWithError());
          showFailureAlert();
        }
      };

      dispatch(formSubmitted());

      httpClient({ url, jwt, method: 'PUT', body })
        .then((res: any) => handle200(res))
        .catch((res: any) => handle500(res));

      return;
    }

    if (state.materialFormState === FormWriteStatus.CREATE_PUBLISHED) {
      const packageId = product.id;
      const url = `${PACKAGES_API}/${packageId}/materials`;

      const body: any = {};
      body.productId = material.productId;
      body.title = material.title;
      body.header = material.header;
      body.image = material.image;
      body.htmlData = material.html;

      const handle200 = (response: any) => {
        if (response.data) {
          // we are doing this temporarily, need to change the api response format;
          const { id } = response.data.materials[response.data.materials?.length - 1];
          const materials = [...product.materials, { ...material, id }];
          const updatedProducts = products.map((p: any) => (p.id === product.id ? { ...product, selected: true, materials } : p));
          dispatch(formSubmittedWithSuccess({ products: updatedProducts, product: { ...product, materials } }));
          refetch();
          showSuccessAlert();
        } else if (response.message) {
          dispatch(formSubmittedWithError());
          showFailureAlert();
        }
      };

      dispatch(formSubmitted());

      httpClient({ url, jwt, method: 'POST', body })
        .then((res: any) => handle200(res))
        .catch((res: any) => handle500(res));
    }
  };

  return {
    submitting: state.formState === FormStatus.SUBMITTING,
    submittedWithError: state.formState === FormStatus.SUBMITTED_ERROR,
    submitForm,
    deleteMaterial,
    deleteDraftMaterial,
  };
};

export default useMaterialForm;
