/* eslint-disable max-len */
import { useEffect } from 'react';
import { useStateValue } from '../../index';
import { pageDataLoaded } from '../actions';
import { mapCategoriesToCategoryDropdownItems } from '../mappers';
import useQuery from '../queries';

const usePageData = () => {
  const { fetchPageData } = useQuery();
  const { state, dispatch } = useStateValue();
  const { data, loading } = fetchPageData();

  const setData = () => {
    const categories = data.categories.map((c: any) => ({ id: c.id, pageId: c.page_id, title: c.title }));
    const subcategories = data.subcategories.map((s: any) => ({ id: s.id, categoryId: s.category_id, title: s.title }));
    const newData = { ...data, categories, subcategories };

    dispatch(pageDataLoaded({
      ...newData,
      categoryDropdownItems: mapCategoriesToCategoryDropdownItems(
        newData.pages,
        newData.categories,
        newData.subcategories,
      ),
    }));
  };

  useEffect(() => { if (!loading) setData(); }, [loading]);

  return {
    pageTaxonomies: state.pageData.pageTaxonomies,
    categoryTaxonomies: state.pageData.categoryTaxonomies,
    subcategoryTaxonomies: state.pageData.subcategoryTaxonomies,
    categoryDropdownItems: state.pageData.categoryDropdownItems,
    roleTaxonomies: state.pageData.roleTaxonomies,
    loading,
    setData,
  };
};

export default usePageData;
