import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  ListItemSecondaryAction,
  Tooltip,
} from '@material-ui/core/';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import InsertPhotoOutlinedIcon from '@material-ui/icons/InsertPhotoOutlined';
import { Product } from '../../../store/types';
import { PublishStatus } from '../../../store/constants';
import useLocal from '../../../../../store/local/hooks/useLocal';

interface IProp {
  item: Product
  select: Function
}

const avatarStyle = { height: '56px', width: '40px' };

export default ({ item, select }: IProp) => {
  const { text } = useLocal();

  return (
    <ListItem
      key={`${item.title}-${item.id}`}
      button
      divider
      selected={item.selected}
      onClick={() => select(item)}
      disableRipple
    >
      <ListItemAvatar>
        <ListItemAvatar>
          {item.image
            ? <Avatar sx={avatarStyle} variant="square" alt={item.title} src={item.imageUrl} />
            : <Avatar sx={avatarStyle} variant="square" alt={item.title}><InsertPhotoOutlinedIcon style={{ fontSize: 32 }} /></Avatar>}
        </ListItemAvatar>
      </ListItemAvatar>
      <ListItemText
        primary={<Typography variant="caption" component="p">{item.title}</Typography>}
        secondary={(
          <>
            <Typography variant="caption" sx={{ fontSize: '0.55rem' }}>
              {item.pageName}
              {' / '}
              {item.categoryTitle}
              {' / '}
              {item.subcategoryTitle}
            </Typography>
            <Typography variant="caption" component="span" sx={{ display: 'block', fontSize: '0.55rem' }}>
              {item.createdAt}
            </Typography>
          </>
        )}
      />
      {item.status === PublishStatus.DRAFT && (
        <ListItemSecondaryAction>
          <Typography variant="overline" sx={{ fontSize: '0.55rem' }}>
            {text.package.list.draftText}
          </Typography>
        </ListItemSecondaryAction>
      )}
      {!item.isPublished && item.status !== PublishStatus.DRAFT && <Tooltip title={text.pages.list.tooltipHidden}><VisibilityOffIcon sx={{ color: '#888' }} fontSize="small" /></Tooltip>}
    </ListItem>
  );
};
