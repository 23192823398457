import { Navigate } from 'react-router-dom';

// LAYOUT
import PublicLayout from './pages/@common/layouts/Public';
import PrivateLayout from './pages/@common/layouts/Private';

// PAGES
import LoginPage from './pages/login';
import NewPasswordPage from './pages/new_password';
import ProductsPage from './pages/products';
import AdminUsersPage from './pages/users/admin';
import StudentUsersPage from './pages/users/student';
import PageBannersPage from './pages/page_banners';
import PublishPage from './pages/publish';

const routes = [
  {
    path: '/',
    element: <PrivateLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/products" />,
      },
      {
        path: '/products',
        element: <ProductsPage />,
      },
      {
        path: '/admin/users',
        element: <AdminUsersPage />,
      },
      {
        path: '/admin/students',
        element: <StudentUsersPage />,
      },
      {
        path: '/pages',
        element: <PageBannersPage />,
      },
      {
        path: '/publish',
        element: <PublishPage />,
      },
    ],
  },
  {
    path: '/',
    element: <PublicLayout />,
    children: [
      {
        path: '/login',
        element: <LoginPage />,
      },
      {
        path: '/newpassword',
        element: <NewPasswordPage />,
      },
    ],
  },
];

export default routes;
