/* eslint-disable no-unused-vars */
export enum FormWriteStatus {
  CREATE = 'create',
  EDIT = 'edit',
}

export enum FormStatus {
  INACTIVE = 'inactive',
  SUBMITTING = 'submitting',
  SUBMITTED_SUCCESS = 'submitted_success',
  SUBMITTED_ERROR = 'submitted_error',
}

export enum DetailsStatus {
  EMPTY = 'empty',
  SELECTED = 'selected',
  LOADING = 'loading',
  LOADED_SUCCESS = 'loaded_success',
  LOADED_ERROR = 'loaded_error',
}

const BASE_API_URL = `${process.env.REACT_APP_API_URL}`;
export const USERS_API = `${BASE_API_URL}/users`;
