import { Outlet } from 'react-router-dom';
import {
  CssBaseline,
  Container,
  Box,
} from '@material-ui/core';

export default () => (
  <>
    <CssBaseline />
    <Container component="main" maxWidth="xs">
      <Box pt={16}>
        <Outlet />
      </Box>
    </Container>
  </>
);
