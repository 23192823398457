import { styled } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const StyledButton = styled(Button)({
  background: '#fde2e2',
  color: '#a51b1b',
  borderColor: '#fde2e2',
  marginLeft: '10px',
  marginRight: '10px',
  minWidth: '90px',
  '&:hover': {
    color: '#a51b1b',
    background: '#fde2e2',
    borderColor: '#fde2e2',
    boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
  },
});

interface IProp {
  text: string
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

export default ({ text, onClick }: IProp) => <StyledButton variant="outlined" onClick={onClick}>{text}</StyledButton>;
