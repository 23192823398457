import { useState } from 'react';
import { Storage } from 'aws-amplify';

const useImageUpload = () => {
  const [imageKey, setImageKey] = useState();
  const [uploading, setUploading] = useState(false);

  const upload = async (file: any) => {
    setUploading(true);

    const result: any = await Storage.put(`public/${file.name}`, file, {
      contentType: 'image/png',
      customPrefix: { public: '' },
    });

    setImageKey(result.key);
    setUploading(false);
  };

  return {
    imageKey,
    uploading,
    upload,
  };
};

export default useImageUpload;
