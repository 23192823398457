import { useStateValue } from '../../index';
import { setListProducts, setListPages, setListStudents, setListAdmins } from '../actions';

const useList = () => {
  const { state, dispatch } = useStateValue();

  const setProductsCount = (count: number) => dispatch(setListProducts(count));
  const setPagesCount = (count: number) => dispatch(setListPages(count));
  const setStudentsCount = (count: number) => dispatch(setListStudents(count));
  const setAdminsCount = (count: number) => dispatch(setListAdmins(count));

  return {
    productsCount: state?.menuList?.products,
    pagesCount: state?.menuList?.pages,
    studentsCount: state?.menuList?.students,
    adminsCount: state?.menuList?.admins,
    setProductsCount,
    setPagesCount,
    setStudentsCount,
    setAdminsCount,
  };
};

export default useList;
