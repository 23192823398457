import { useState } from 'react';
import { Box, Stack, Paper, ToggleButton, ToggleButtonGroup } from '@material-ui/core/';
import LoadingButton from '@material-ui/lab/LoadingButton';
import TranslateIcon from '@material-ui/icons/Translate';
import { DefaultButton, FullWidthTextField, ErrorText } from '../@common/components/forms';
import useAuth from '../../store/auth/hooks/useAuth';
import useLocal from '../../store/local/hooks/useLocal';

export default () => {
  const { isLoading, hasError, authenticateUser } = useAuth();
  const { text, language, setLanguage } = useLocal();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = () => authenticateUser({ email, password });
  const handleChange = (event: React.MouseEvent<HTMLElement>, newLang: string) => setLanguage(newLang);

  return (
    <>
      <Paper elevation={3} sx={{ p: 4 }}>
        <form onSubmit={handleSubmit}>
          <FullWidthTextField
            autoFocus
            value={email}
            title={text.login.emailLabel}
            onSave={(value: any) => setEmail(value)}
            hasError={false}
            errorMessage=""
          />

          <FullWidthTextField
            value={password}
            title={text.login.passwordLabel}
            type="password"
            onSave={(value: any) => setPassword(value)}
            hasError={false}
            errorMessage=""
            autoComplete="new-password"
          />

          <Box height={10} />

          {isLoading && (
            <LoadingButton fullWidth loading variant="outlined">
              Submit
            </LoadingButton>
          )}

          {!isLoading && (
            <DefaultButton
              text={text.login.loginButtonText}
              type="submit"
              fullWidth
              disabled={email.length === 0 || password.length === 0}
              onClick={handleSubmit}
            />
          )}

          {hasError && <ErrorText text={text.login.loginErrorMessage} />}
        </form>
      </Paper>

      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        sx={{ mt: 2 }}
      >
        <TranslateIcon fontSize="small" />
        <ToggleButtonGroup
          color="primary"
          value={language}
          exclusive
          onChange={handleChange}
        >
          <ToggleButton sx={{ fontSize: '0.65rem', padding: '5px 7px' }} value="en">en</ToggleButton>
          <ToggleButton sx={{ fontSize: '0.65rem', padding: '5px 7px' }} value="jp">jp</ToggleButton>
        </ToggleButtonGroup>
      </Stack>
    </>
  );
};
