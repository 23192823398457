/* eslint-disable no-nested-ternary */
import { Paper, Box, Typography, Link } from '@material-ui/core/';
import { OpenInNew as OpenInNewIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { PublishStatus } from '../../../store/constants';
import { DefaultIconButton, FullpageLoader } from '../../../../@common/components/forms';
import useLocal from '../../../../../store/local/hooks/useLocal';
import useProductForm from '../../../store/hooks/useProductForm';
import useProductModal from '../../../store/hooks/useProductModal';
import useProduct from '../../../store/hooks/useProduct';
import ContentViewContainer from '../../../../@common/components/details/ContentViewContainer';
import HeaderViewContainer from '../../../../@common/components/details/HeaderViewContainer';
import ProductDetailsView from './ProductDetails';
import MaterialDetailsView from './MaterialDetails';

const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
    fontSize: '.75rem',
  },
  label: {
    lineHeight: 0,
    fontSize: '.65rem',
    fontWeight: 'bold',
  },
  value: {
    fontSize: '.65rem',
    overflowWrap: 'break-word',
  },
  htmlContent: {
    fontSize: '.65rem',
    background: '#fafafa',
    border: '1px solid #ccc',
    padding: '5px 10px',
    minHeight: '1.5rem',
  },
});

export default () => {
  const classes = useStyles();
  const { text } = useLocal();
  const { product, renderLoading } = useProduct();
  const { submitting, deleteDraftProduct, submitCreateProduct } = useProductForm();
  const { openProductModalForEdit, openDraftProductModal } = useProductModal();

  const utilsGetIdFromPassword = (password: string) => {
    if (product.productId === '9200508') {
      return '01888';
    }

    return password.substring(1, password.length - 1);
  };
  const allowFormSubmit = product?.materials.length > 0;

  const linkToWeb = product.needAuth
    ? `${process.env.REACT_APP_PORTAL_URL}/dl/${product.productId}/${utilsGetIdFromPassword(product.password)}`
    : `${process.env.REACT_APP_PORTAL_URL}/dl/${product.productId}`;

  return (
    <>
      <Box>
        <HeaderViewContainer>
          <Link target="_blank" rel="noreferrer" href={linkToWeb}>
            <Typography variant="body2" className={classes.title}>
              {product.title}
              {' '}
              <OpenInNewIcon sx={{ fontSize: '1rem' }} />
            </Typography>
          </Link>
          <Box>
            {product.status === PublishStatus.DRAFT && (
              <>
                <DefaultIconButton onClick={deleteDraftProduct} text={text.buttons.delete} />
                <DefaultIconButton onClick={openDraftProductModal} text={text.buttons.edit} />
                <DefaultIconButton disabled={!allowFormSubmit} onClick={submitCreateProduct} primary={allowFormSubmit} text={text.buttons.submitPublished} />
              </>
            )}

            {product.status === PublishStatus.PUBLISHED && (
              <DefaultIconButton onClick={openProductModalForEdit} text={text.buttons.edit} />
            )}
          </Box>
        </HeaderViewContainer>
        <ContentViewContainer>
          <Paper elevation={0} sx={{ my: 4, bgcolor: 'inherit' }}>
            <ProductDetailsView loading={renderLoading} product={product} />
            <MaterialDetailsView loading={renderLoading} product={product} />
          </Paper>
        </ContentViewContainer>
      </Box>
      <FullpageLoader open={submitting} />
    </>
  );
};
