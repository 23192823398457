import { useState, useEffect } from 'react';
import { Box, Grid, Stack, Container } from '@material-ui/core';

import {
  FullWidthTextField,
  TextEditor,
  SectionTitle,
  FullpageDialog,
  FullpageLoader,
  ConfirmationDialog,
} from '../../../../@common/components/forms';

import ImageUpload from '../../../components/ImageUpload';
import useMaterialForm from '../../../store/hooks/useMaterialForm';
import useMaterialModal from '../../../store/hooks/useMaterialModal';
import useMaterialSelect from '../../../store/hooks/useMaterialSelect';

import useForm from '../../../store/hooks/useForm';
import useLocal from '../../../../../store/local/hooks/useLocal';

export default () => {
  const { modal, closeMaterialModal } = useMaterialModal();
  const { material } = useMaterialSelect();
  const { text } = useLocal();
  const {
    submitting,
    submitForm,
    deleteMaterial,
  } = useMaterialForm();

  // DELETE dialog (todo: move this out into its own custom hook)
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const handleConfirmationOpen = () => setOpenConfirmation(true);
  const handleConfirmationClose = () => setOpenConfirmation(false);
  const handleDeleteConfirmation = () => {
    setOpenConfirmation(false);
    deleteMaterial();
  };

  const getFormFields = (edit: boolean) => ({
    id: { value: edit ? material.id : Date.now(), required: false, hasError: false },
    bookTitle: { value: edit ? material.title : '', required: false, hasError: false },
    productId: { value: edit ? material.productId : '', required: false, hasError: false },
    bookImageUrl: { value: edit ? material.image : '', url: edit ? material.imageUrl : '', required: false, hasError: false },
    header: { value: edit ? material.header : '', required: false, hasError: false },
    content: { value: edit ? material.html : '', required: false, hasError: false },
  });

  const {
    formFields,
    submit,
    setValue,
    setFormFields,
  } = useForm({
    initialValue: getFormFields(modal.edit),
    onSubmit: submitForm,
  });

  useEffect(() => { setFormFields(getFormFields(modal.edit)); }, [modal.open]);

  return (
    <>
      <FullpageDialog
        open={modal.open}
        showDelete={modal.showDelete}
        submitText={text.appBarButtons[modal.action]}
        deleteText={text.appBarButtons.delete}
        appBarTitle={text.package.appBar.material[modal.action]}
        onClose={closeMaterialModal}
        onSubmit={submit}
        onConfirmDelete={handleConfirmationOpen}
      >
        <Container maxWidth="sm">
          <Box my={16} />
          <SectionTitle text={text.package.form.materialFormTitle} />
          <Grid container>
            <Grid item xs={12} sm={8}>
              <Stack direction="column" justifyContent="flex-start">
                <FullWidthTextField
                  value={formFields.bookTitle.value}
                  title={text.package.form.titleLabel}
                  onSave={(value: any) => setValue('bookTitle', value)}
                  hasError={formFields.bookTitle.hasError}
                  errorMessage={text.package.form.titleErrorMessage}
                />
                <FullWidthTextField
                  value={formFields.productId.value}
                  title={text.package.form.productIdLabel}
                  onSave={(value: any) => setValue('productId', value)}
                  hasError={formFields.productId.hasError}
                  errorMessage={text.package.form.productIdErrorMessage}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Stack direction="row" justifyContent="flex-end">
                <ImageUpload
                  inputId="book-image"
                  defaultValue={formFields.bookImageUrl.value}
                  placeholder={text.package.form.imagePlaceholderText}
                  chooseImageText={text.package.form.imageChooseImageText}
                  onUpload={(value: any) => setValue('bookImageUrl', value)}
                  hasError={formFields.bookImageUrl.hasError}
                  errorMessage={text.package.form.imageErrorMessage}
                />
              </Stack>
            </Grid>
          </Grid>

          <TextEditor
            value={formFields.header.value}
            title={text.package.form.headerLabel}
            onSave={(value: any) => setValue('header', value)}
            hasError={formFields.header.hasError}
            errorMessage=""
          />

          <TextEditor
            value={formFields.content.value}
            title={text.package.form.htmlLabel}
            onSave={(value: any) => setValue('content', value)}
            hasError={formFields.content.hasError}
            errorMessage=""
          />
          <Box my={16} />
        </Container>

        <FullpageLoader open={submitting} />
      </FullpageDialog>

      <ConfirmationDialog
        open={openConfirmation}
        onClose={handleConfirmationClose}
        onConfirm={handleDeleteConfirmation}
        title={text.package.deleteModal.title}
        content={text.package.deleteModal.body}
        no={text.package.deleteModal.cancel}
        ok={text.package.deleteModal.confirm}
      />
    </>
  );
};
