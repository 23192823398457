/* eslint-disable no-unused-vars */
import { useEffect } from 'react';
import { Box, Container } from '@material-ui/core';
import {
  SectionTitle,
  TextEditor,
  FullpageDialog,
  FullpageLoader,
  RadioList,
} from '../../../../@common/components/forms';
import ImageUpload from './components/ImageUpload';
import useFormSubmit from '../../../store/hooks/useFormSubmit';
import useModal from '../../../store/hooks/useModal';
import useForm from '../../../store/hooks/useForm';
import useLocal from '../../../../../store/local/hooks/useLocal';

export default () => {
  const { modal, closeModal } = useModal();
  const { text } = useLocal();
  const { banner, submitting, submitForm } = useFormSubmit();

  const getFormFields = (edit: boolean) => ({
    id: { value: edit ? banner.id : null, required: false, hasError: false },
    name: { value: edit ? banner.name : '', required: false, hasError: false },
    path: { value: edit ? banner.path : '', required: false, hasError: false },
    html: { value: edit ? banner.html : '', required: false, hasError: false },
    bannerImageDesktop: { value: edit ? banner.bannerImageDesktop : '', required: false, hasError: false },
    bannerImageMobile: { value: edit ? banner.bannerImageMobile : '', required: false, hasError: false },
    showBanner: { value: edit ? banner.showBanner : '', required: true, hasError: false },
    needAuth: { value: edit ? banner.needAuth : '', required: false, hasError: false },
  });

  const {
    formFields,
    setValue,
    setFormFields,
    submit,
  } = useForm({
    initialValue: getFormFields(modal.edit),
    onSubmit: (fields: any) => submitForm({ fields }),
  });

  const visibilityOptions = [
    { name: 'Yes' },
    { name: 'No' },
  ];

  useEffect(() => { setFormFields(getFormFields(modal.edit)); }, [modal.open]);

  return (
    <>
      <FullpageDialog
        open={modal.open}
        showDelete={modal.showDelete}
        submitText={text.appBarButtons[modal.action]}
        deleteText={text.appBarButtons.delete}
        appBarTitle={text.pages.appBar.page[modal.action]}
        onClose={closeModal}
        onSubmit={submit}
      >
        <Container maxWidth="sm">
          <Box my={16} />
          <SectionTitle text={text.pages.form.bannerFormTitle} />

          <RadioList
            title={text.pages.form.visibilityLabel}
            defaultValue={formFields.showBanner.value === 1 ? 'Yes' : 'No'}
            items={visibilityOptions}
            changeHandler={(selected: any) => setValue('showBanner', selected === 'Yes' ? 1 : 0)}
          />

          <TextEditor
            value={formFields.html.value}
            title={text.pages.form.explanationLabel}
            onSave={(value: any) => setValue('html', value)}
            hasError={formFields.html.hasError}
            errorMessage=""
          />

          <ImageUpload
            label={text.pages.form.desktopBannerLabel}
            defaultValue={formFields.bannerImageDesktop.value}
            inputId="desktop-image"
            dimensionText={text.pages.form.desktopBannerDimensionText}
            chooseImageText={text.pages.form.imageChooseImageText}
            onUpload={(value: any) => setValue('bannerImageDesktop', value)}
            hasError={formFields.bannerImageDesktop.hasError}
            errorMessage={text.pages.form.imageErrorMessage}
          />

          <ImageUpload
            label={text.pages.form.mobileBannerLabel}
            defaultValue={formFields.bannerImageMobile.value}
            inputId="mobile-image"
            dimensionText={text.pages.form.mobileBannerDimensionText}
            chooseImageText={text.pages.form.imageChooseImageText}
            onUpload={(value: any) => setValue('bannerImageMobile', value)}
            hasError={formFields.bannerImageDesktop.hasError}
            errorMessage={text.pages.form.imageErrorMessage}
          />
          <Box my={16} />
        </Container>

        <FullpageLoader open={submitting} />
      </FullpageDialog>
    </>
  );
};
