import { Paper, Box } from '@material-ui/core/';
import useLocal from '../../../../../store/local/hooks/useLocal';
import useSelect from '../../../store/hooks/useSelect';
import Placeholder from '../../../../@common/components/details/Placeholder';
import ContentViewContainer from '../../../../@common/components/details/ContentViewContainer';
import HeaderViewContainer from '../../../../@common/components/details/HeaderViewContainer';
import DetailsView from './Details';

export default () => {
  const { text } = useLocal();
  const { listItem } = useSelect();

  if (listItem === null) return <Placeholder placeholder={text.publish.details.defaultText} />;

  return (
    <Box>
      <HeaderViewContainer justifyContent="flex-end" />
      <ContentViewContainer>
        <Paper sx={{ my: 4, p: 2 }}>
          <DetailsView listItem={listItem} />
        </Paper>
      </ContentViewContainer>
    </Box>
  );
};
