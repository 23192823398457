import en from './en.json';
import jp from './jp.json';
import { LANG_SELECTED } from './actions';

export const INITIAL_STATE = {
  language: 'jp',
  en,
  jp,
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case LANG_SELECTED: return { ...state, language: action.payload };
    default: return state;
  }
};
