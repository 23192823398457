import authReducer from './auth/reducers';
import listReducer from './list/reducers';
import localReducer from './local/reducers';
import pageDataReducer from './pageData/reducers';
import alertReducer from './alert/reducers';

export default ({ auth, menuList, local, pageData, alert }: any, action: any) => ({
  auth: authReducer(auth, action),
  menuList: listReducer(menuList, action),
  local: localReducer(local, action),
  pageData: pageDataReducer(pageData, action),
  alert: alertReducer(alert, action),
});
