import { List } from '@material-ui/core/';
import { Banner } from '../../../store/types';
import PageItem from './PageItem';
import Loader from './Loader';
import useSelect from '../../../store/hooks/useSelect';

const styles = {
  width: '30vw',
  minWidth: '400px',
  height: 'calc(100vh - 49px)',
  bgcolor: 'background.paper',
  overflow: 'scroll',
  py: 0,
};

interface IProp {
  banners: Banner[]
  loading?: boolean
}

export default ({ banners, loading }: IProp) => {
  const { select } = useSelect();

  const PageItemsView = banners.map((i) => <PageItem select={select} key={`key-${i.id}`} item={i} />);
  const ListView = <List sx={styles}>{PageItemsView}</List>;

  return loading ? <Loader /> : <>{ListView}</>;
};
