import { useState } from 'react';
import Header from './components/Header';
import Page from './components/Page';
import Pagination from './components/Pagination';
import useStudents from '../../store/hooks/useStudents';
import useLocal from '../../../../../store/local/hooks/useLocal';

export default () => {
  const { text } = useLocal();
  const [page, setPage] = useState(1);
  const {
    students,
    pagination,
    loading,
  } = useStudents(page);

  return (
    <div>
      <Header headerText={text.student.list.title} />
      <Page loading={loading} students={students} />
      <Pagination
        currentPage={pagination?.currentPage}
        perPage={pagination?.perPage}
        totalCount={pagination?.totalCount}
        lastPage={pagination?.lastPage}
        paginate={(index: number) => setPage(index)}
      />
    </div>
  );
};
