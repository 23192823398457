/* eslint-disable no-unused-vars */
import { useEffect } from 'react';
import { useStateValue } from '../index';
import { pageLoadingStarted, pageLoadedWithSuccess } from '../actions';
import { mapJsonListToObjectList } from '../mappers';
import useQuery from '../queries';
import useList from '../../../../../store/list/hooks/useList';

const useProducts = () => {
  const { fetchList } = useQuery();
  const { setAdminsCount } = useList();

  const { state, dispatch } = useStateValue();
  const { data, loading } = fetchList();
  const totalCount = data?.Users?.length;

  const setData = () => {
    const mapped = mapJsonListToObjectList({
      user: state.user,
      data: data || [],
    });

    setAdminsCount(mapped.length);
    dispatch(pageLoadedWithSuccess(mapped));
  };

  const listEventHandler = () => (loading ? dispatch(pageLoadingStarted()) : setData());

  useEffect(() => { listEventHandler(); }, [totalCount, loading]);

  return {
    users: state.users,
    loading,
  };
};

export default useProducts;
