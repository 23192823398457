export const LIST_LOADING = 'admin/LIST_LOADING';
export const LIST_LOADED_SUCCESS = 'admin/LIST_LOADED_SUCCESS';
export const LIST_LOADED_ERROR = 'admin/LIST_LOADED_ERROR';
export const pageLoadingStarted = () => ({ type: LIST_LOADING });
export const pageLoadedWithSuccess = (payload: any) => ({ payload, type: LIST_LOADED_SUCCESS });

export const DETAILS_SELECTED = 'admin/DETAILS_SELECTED';
export const DETAILS_LOADING = 'admin/DETAILS_LOADING';
export const DETAILS_LOADED_CACHE = 'admin/DETAILS_LOADED_CACHE';
export const DETAILS_LOADED_SUCCESS = 'admin/DETAILS_LOADED_SUCCESS';
export const DETAILS_LOADED_ERROR = 'admin/DETAILS_LOADED_ERROR';
export const userSelected = (payload: any) => ({ payload, type: DETAILS_SELECTED });
export const userDetailsWillLoad = () => ({ type: DETAILS_LOADING });
export const userDetailsLoadedWithSuccess = (payload: any) => ({ payload, type: DETAILS_LOADED_SUCCESS });
export const userDetailsLoadedWithCache = () => ({ type: DETAILS_LOADED_CACHE });

export const MODAL_NEW_OPEN = 'admin/MODAL_NEW_OPEN';
export const MODAL_EDIT_OPEN = 'admin/MODAL_EDIT_OPEN';
export const MODAL_CLOSE = 'admin/MODAL_CLOSE';
export const newUserStarted = () => ({ type: MODAL_NEW_OPEN });
export const editUserStarted = () => ({ type: MODAL_EDIT_OPEN });
export const modalClose = () => ({ type: MODAL_CLOSE });

export const FORM_INACTIVE = 'admin/FORM_INACTIVE';
export const FORM_SUBMITTING = 'admin/FORM_SUBMITTING';
export const FORM_SUBMITTED_SUCCESS = 'admin/FORM_SUBMITTED_SUCCESS';
export const FORM_SUBMITTED_ERROR = 'admin/FORM_SUBMITTED_ERROR';
export const formReset = () => ({ type: FORM_INACTIVE });
export const formSubmitted = () => ({ type: FORM_SUBMITTING });
export const formSubmittedWithSuccess = (payload: any) => ({ payload, type: FORM_SUBMITTED_SUCCESS });
export const formSubmittedWithError = () => ({ type: FORM_SUBMITTED_ERROR });

export const USER_DELETING = 'admin/USER_DELETING';
export const USER_DELETED_SUCCESS = 'admin/USER_DELETED_SUCCESS';
export const userDeleteConfirmed = () => ({ type: USER_DELETING });
export const userDeletedWithSuccess = () => ({ type: USER_DELETED_SUCCESS });
