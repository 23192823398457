/* eslint-disable react/no-danger */
import {
  Container,
  Box,
  Stack,
  Typography,
  Divider,
  IconButton,
  List,
  ListItem,
  ListSubheader,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Skeleton,
} from '@material-ui/core/';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import InsertPhotoOutlinedIcon from '@material-ui/icons/InsertPhotoOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { DefaultIconButton } from '../../../../@common/components/forms';
import { Product } from '../../../store/types';
import { PublishStatus } from '../../../store/constants';
import useMaterialForm from '../../../store/hooks/useMaterialForm';
import useMaterialModal from '../../../store/hooks/useMaterialModal';
import useMaterialSelect from '../../../store/hooks/useMaterialSelect';
import useLocal from '../../../../../store/local/hooks/useLocal';
import AssetDetailsView from './AssetDetails';

const useStyles = makeStyles({
  label: {
    lineHeight: 0,
    fontSize: '.65rem',
    fontWeight: 'bold',
  },
  value: {
    fontSize: '.65rem',
    overflowWrap: 'break-word',
  },
  htmlContent: {
    fontSize: '.65rem',
    background: '#fafafa',
    border: '1px solid #ccc',
    padding: '5px 10px',
    minHeight: '1.7rem',
  },
  list: {
    marginTop: 16,
    border: '1px solid #e8c49e',
    background: '#fff8ed',
  },
  subheader: {
    lineHeight: 0,
    paddingTop: 8,
    paddingBottom: 8,
    background: '#f8e7d4',
  },
  materialTitle: {
    color: '#785514',
    fontWeight: 'bold',
    fontSize: '.75rem',
  },
  listItem: {
    alignItems: 'flex-start',
    padding: '8px 16px',
  },
  container: { padding: '0 !important', marginTop: 16 },
  avatar: { height: 80, width: 60, marginTop: 8 },
});

export default ({ product, loading }: {
  product: Product
  loading: boolean
}) => {
  const classes = useStyles();
  const { text } = useLocal();
  const { selectMaterialForEdit, selectDraftMaterialForEdit } = useMaterialSelect();
  const { openNewMaterialModal, openDraftMaterialModal } = useMaterialModal();
  const { deleteDraftMaterial } = useMaterialForm();

  return (
    <>
      <Container className={classes.container}>
        <Typography variant="overline" className={classes.label}>
          {text.package.details.materialsLabel}
          {' '}
          (
          {product.materials.length}
          )
        </Typography>

        <IconButton
          aria-label="open-modal"
          size="small"
          onClick={(product.status === PublishStatus.DRAFT ? openDraftMaterialModal : openNewMaterialModal)}
        >
          <AddCircleOutlineIcon sx={{ fontSize: '1rem' }} />
        </IconButton>

        <Divider light />

        {loading && (
          <List
            disablePadding
            component="div"
            className={classes.list}
            subheader={(
              <ListSubheader disableSticky className={classes.subheader} component="div">
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={3}>
                  <Typography variant="body2" className={classes.materialTitle} />
                  <DefaultIconButton onClick={() => { }} text={text.buttons.edit} />
                </Stack>
              </ListSubheader>
            )}
          >
            <ListItem key="loading" dense className={classes.listItem}>
              <ListItemAvatar>
                <ListItemAvatar>
                  <Skeleton variant="rectangular" className={classes.avatar} />
                </ListItemAvatar>
              </ListItemAvatar>
              <ListItemText
                sx={{ ml: 2 }}
                primary={(
                  <>
                    <Box>
                      <Typography variant="overline" className={classes.label}>{text.package.details.productIdLabel}</Typography>
                      <Typography variant="body2" className={classes.htmlContent} />
                    </Box>

                    <Box mt={2}>
                      <Typography variant="overline" className={classes.label}>{text.package.details.materialHeaderLabel}</Typography>
                      <div
                        className={classes.htmlContent}
                        dangerouslySetInnerHTML={{ __html: '' }}
                      />
                    </Box>
                  </>
                )}
              />
            </ListItem>
          </List>
        )}

        {!loading && product.materials.length === 0 && (
          <List disablePadding component="div">
            <ListItem key="default" component="div">
              <Box sx={{ textAlign: 'center', width: '100%' }}>
                <Typography component="p" sx={{ color: '#888', my: 1 }} variant="caption">{text.package.details.emptyMaterialsText}</Typography>
                <DefaultIconButton
                  primary
                  text={text.package.details.addNewMaterialText}
                  onClick={(product.status === PublishStatus.DRAFT ? openDraftMaterialModal : openNewMaterialModal)}
                />
              </Box>
            </ListItem>
          </List>
        )}

        {product.materials.map((i) => (
          <List
            disablePadding
            component="div"
            key={i.productId}
            className={classes.list}
            subheader={(
              <ListSubheader disableSticky className={classes.subheader} component="div" id={i.title}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography variant="body2" className={classes.materialTitle}>{i.title}</Typography>
                  {product.status === PublishStatus.DRAFT && (
                    <Box>
                      <DefaultIconButton onClick={() => deleteDraftMaterial(i)} text={text.buttons.delete} />
                      <DefaultIconButton onClick={() => selectDraftMaterialForEdit(i)} text={text.buttons.edit} />
                    </Box>
                  )}
                  {product.status === PublishStatus.PUBLISHED && <DefaultIconButton onClick={() => selectMaterialForEdit(i)} text={text.buttons.edit} />}
                </Stack>
              </ListSubheader>
            )}
          >
            <ListItem key={i.title} dense className={classes.listItem}>
              <ListItemAvatar>
                <ListItemAvatar>
                  {i.image
                    ? <Avatar className={classes.avatar} variant="square" alt={i.title} src={i.imageUrl} />
                    : <Avatar className={classes.avatar} variant="square" alt={i.title}><InsertPhotoOutlinedIcon style={{ fontSize: 40 }} /></Avatar>}
                </ListItemAvatar>
              </ListItemAvatar>
              <ListItemText
                sx={{ ml: 2 }}
                primary={(
                  <>
                    <Box>
                      <Typography variant="overline" className={classes.label}>{text.package.details.productIdLabel}</Typography>
                      <Typography variant="body2" className={classes.htmlContent}>{i.productId}</Typography>
                    </Box>
                    <Box mt={2}>
                      <Typography variant="overline" className={classes.label}>{text.package.details.materialHeaderLabel}</Typography>
                      <div
                        className={classes.htmlContent}
                        dangerouslySetInnerHTML={{ __html: i.header || '' }}
                      />
                    </Box>

                    {i.html
                      ? (
                        <Box mt={2}>
                          <Typography variant="overline" className={classes.label}>{text.package.details.materialHTMLLabel}</Typography>
                          <div
                            className={classes.htmlContent}
                            dangerouslySetInnerHTML={{ __html: i.html || '' }}
                          />
                        </Box>
                      )
                      : <AssetDetailsView material={i} isDraft={product.status === PublishStatus.DRAFT} />}
                  </>
                )}
              />
            </ListItem>
          </List>
        ))}
      </Container>
    </>
  );
};
