import { Paper, Box } from '@material-ui/core/';
import { DefaultIconButton } from '../../../../../@common/components/forms';
import useLocal from '../../../../../../store/local/hooks/useLocal';
import useStudentModal from '../../../store/hooks/useStudentModal';
import useStudent from '../../../store/hooks/useStudent';
import ContentViewContainer from '../../../../../@common/components/details/ContentViewContainer';
import HeaderViewContainer from '../../../../../@common/components/details/HeaderViewContainer';
import DetailsView from './Details';

export default () => {
  const { text } = useLocal();
  const { openStudentModalForEdit } = useStudentModal();
  const { student, renderLoading } = useStudent();

  return (
    <Box>
      <HeaderViewContainer justifyContent="flex-end">
        <DefaultIconButton onClick={openStudentModalForEdit} text={text.buttons.edit} />
      </HeaderViewContainer>
      <ContentViewContainer>
        <Paper sx={{ my: 4, p: 2 }}>
          <DetailsView loading={renderLoading} student={student} />
        </Paper>
      </ContentViewContainer>
    </Box>
  );
};
