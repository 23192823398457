import StateProvider from './store';
import AdminUserList from './sections/list';
import AdminUserDetails from './sections/details';
import AdminUserForm from './sections/forms';

export default () => (
  <StateProvider>
    <AdminUserList />
    <AdminUserDetails />
    <AdminUserForm />
  </StateProvider>
);
