import CognitoUser, { Auth } from 'aws-amplify';

export interface IPassword {
  user: typeof CognitoUser
  password: string
}

export interface IProp {
  email: string
  password: string
}

export const authenticate = async ({ email, password }: IProp) => Auth.signIn(email, password);
export const completeNewPassword = async ({ user, password }: IPassword) => Auth.completeNewPassword(user, password);
export const clearSession = async () => Auth.signOut();
export const checkUserSession = async () => {
  const cognitoUser = await Auth.currentAuthenticatedUser();
  const session = await Auth.currentSession();
  return { ...cognitoUser, signInUserSession: session };
};
