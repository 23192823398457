import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  Tooltip,
  CircularProgress,
} from '@material-ui/core/';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import { Deployment } from '../../../store/types';
import useLocal from '../../../../../store/local/hooks/useLocal';

interface IProp {
  item: Deployment
  select: Function
}

export default ({ item, select }: IProp) => {
  const { text } = useLocal();

  return (
    <ListItem
      key={`key-${item.id}`}
      button
      divider
      selected={item.selected}
      onClick={() => select(item)}
      disableRipple
    >
      <ListItemAvatar>
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: 'transparent', color: '#aaa' }} alt={item.email}><EventAvailableIcon /></Avatar>
        </ListItemAvatar>
      </ListItemAvatar>
      <ListItemText primary={<Typography variant="overline" component="p">{item.publishDate}</Typography>} />
      {item.inProgress && <Tooltip title={text.pages.list.tooltipVisible}><CircularProgress sx={{ color: '#888', width: '24px !important', height: '24px !important' }} /></Tooltip>}
    </ListItem>
  );
};
