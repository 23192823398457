/* eslint-disable no-restricted-globals */
import { useEffect } from 'react';
import { useStateValue } from '../index';
import { pageLoadingStarted, pageLoadedWithSuccess } from '../actions';
import { mapJsonListToObjectList } from '../mappers';
import useQuery from '../queries';

const useFetchList = () => {
  const { fetchList } = useQuery();
  const { state, dispatch } = useStateValue();
  const { data, loading, isError, isValidating } = fetchList();

  const setData = () => {
    const mapped = mapJsonListToObjectList({ data: data || [] });
    dispatch(pageLoadedWithSuccess(mapped));
  };

  const listEventHandler = () => (loading ? dispatch(pageLoadingStarted()) : setData());
  useEffect(() => { listEventHandler(); }, [loading, isValidating]);
  useEffect(() => { if (isError) location.reload(); }, [isError]);

  return {
    list: state.list,
    loading,
  };
};

export default useFetchList;
