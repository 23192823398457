/* eslint-disable import/prefer-default-export */
import { Admin } from './types';
import dateToJST from '../../../../utils/date';

interface IMapperProp {
  data: any
}

interface IListMapperProp extends IMapperProp {
  user: Admin
}

export const mapJsonListToObjectList = ({ data, user }: IListMapperProp) => {
  const users: Admin[] = [];

  const getTime = (date: any) => new Date(date).getTime();
  const dateDiff = (b: any, a: any) => getTime(b.UserCreateDate) - getTime(a.UserCreateDate);
  const sortByCreatedDateDesc = (u: any[]) => u.sort((a: any, b: any) => dateDiff(b, a));
  const snakeToCamel = (str: string) => str.toLowerCase().replace(/[-_][a-z]/g, (group) => group.slice(-1).toUpperCase());

  sortByCreatedDateDesc(data.Users).forEach((adminUser: any) => {
    const attributes: any = {};

    adminUser.Attributes.forEach((attribute: any) => { attributes[snakeToCamel(attribute.Name)] = attribute.Value; });

    users.push({
      email: attributes.email,
      familyName: attributes.familyName,
      givenName: attributes.givenName,
      sub: attributes.sub,
      createdDate: dateToJST(adminUser.UserCreateDate),
      modifiedDate: dateToJST(adminUser.UserLastModifiedDate),
      userStatus: adminUser.UserStatus,
      username: adminUser.Username,
      enabled: adminUser.Enabled,
      selected: user?.username === adminUser?.Username,
    });
  });

  return users;
};

export const mapFormFieldsToObject = ({ data }: IMapperProp) => ({
  email: data.email.value,
  familyName: data.familyName.value,
  givenName: data.givenName.value,
  sub: data.sub.value,
  userStatus: data.userStatus.value,
  username: data.username.value,
  enabled: data.enabled.value,
  password: data.password.value,
  createdDate: data.createdDate.value,
  selected: true,
});
