import Header from './components/Header';
import Page from './components/Page';
import useBanners from '../../store/hooks/useBanners';
import useLocal from '../../../../store/local/hooks/useLocal';

export default () => {
  const { text } = useLocal();
  const { banners, loading } = useBanners();

  return (
    <div>
      <Header headerText={text.pages.list.title} />
      <Page loading={loading} banners={banners} />
    </div>
  );
};
