/* eslint-disable react/no-danger */
import { Box, Typography, Paper, Stack } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import { Banner } from '../../../store/types';
import useLocal from '../../../../../store/local/hooks/useLocal';

const useStyles = makeStyles({
  label: {
    lineHeight: 0,
    fontSize: '.65rem',
    fontWeight: 'bold',
  },
  value: {
    fontSize: '.65rem',
    overflowWrap: 'break-word',
  },
  htmlContent: {
    fontSize: '.65rem',
    background: '#fafafa',
    border: '1px solid #ccc',
    padding: '5px 10px',
    minHeight: '1.5rem',
  },
  explanation: {
    height: 120,
    overflowY: 'scroll',
  },
});

export default ({ banner, loading }: {
  banner: Banner
  loading: boolean
}) => {
  const classes = useStyles();
  const { text } = useLocal();

  return (
    <>
      <Box>
        <Typography variant="overline" className={classes.label}>{text.pages.details.explanationLabel}</Typography>
        <p
          className={`${classes.htmlContent} ${classes.explanation}`}
          dangerouslySetInnerHTML={{ __html: loading ? '' : banner.html }}
        />
      </Box>

      <Box mt={2}>
        <Typography variant="overline" className={classes.label}>{text.pages.details.desktopBannerLabel}</Typography>
        <Paper elevation={1}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ height: 200 }}
          >
            {!banner.bannerImageDesktop && <Typography variant="body1">{text.pages.details.desktopBannerDimensionText}</Typography>}
            {banner.bannerImageDesktop && <img src={banner.bannerImageDesktopUrl} alt="desktop" />}
          </Stack>
        </Paper>
      </Box>

      <Box mt={2}>
        <Typography variant="overline" className={classes.label}>{text.pages.details.mobileBannerLabel}</Typography>
        <Paper elevation={1}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ height: 160 }}
          >
            {!banner.bannerImageMobile && <Typography variant="body1">{text.pages.details.mobileBannerDimensionText}</Typography>}
            {banner.bannerImageMobile && <img src={banner.bannerImageMobileUrl} alt="desktop" />}
          </Stack>
        </Paper>
      </Box>
    </>
  );
};
