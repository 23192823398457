import { useStateValue } from '../index';
import { Product } from '../types';
import { productSelected } from '../actions';
import { DetailsStatus } from '../constants';

const useProductSelect = () => {
  const { state, dispatch } = useStateValue();

  const select = (product: Product) => {
    // NOTE: avoid unnecessary re-renders if clicking the same product twice;
    if (product.id === state.product?.id) return;

    const products = state.products.map((p: any) => ({ ...p, selected: p.id === product.id }));
    dispatch(productSelected({ products, product }));
  };

  return {
    product: state.product,
    renderDefault: state.productDetailsState === DetailsStatus.EMPTY,
    select,
  };
};

export default useProductSelect;
