import ProductDialog from './product';
import MaterialDialog from './material';
import AssetDialog from './asset';

export default () => (
  <>
    <ProductDialog />
    <MaterialDialog />
    <AssetDialog />
  </>
);
