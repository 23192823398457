import { useStateValue } from '../index';
import { studentSelected } from '../actions';
import { Student } from '../types';
import { DetailsStatus } from '../constants';

const useStudentSelect = () => {
  const { state, dispatch } = useStateValue();

  const select = (student: Student) => {
    // NOTE: avoid unnecessary re-renders if clicking the same banner twice;
    if (student.id === state.student?.id) return;

    const students = state.students.map((s: any) => ({ ...s, selected: s.id === student.id }));
    dispatch(studentSelected({ students, student }));
  };

  return {
    student: state.student,
    renderDefault: state.studentDetailsState === DetailsStatus.EMPTY,
    select,
  };
};

export default useStudentSelect;
