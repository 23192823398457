import { Box, Stack, Typography, Divider } from '@material-ui/core/';

const styles = { width: '30vw', minWidth: '400px', px: 2, height: '48px' };

interface IProp {
  headerText: string
}

export default ({ headerText }: IProp) => (
  <>
    <Stack sx={styles} direction="row" justifyContent="space-between" alignItems="center">
      <Box component="span">
        <Typography variant="body1">{headerText}</Typography>
      </Box>
    </Stack>
    <Divider />
  </>
);
