import { API } from 'aws-amplify';
import useSWR from 'swr';
import useAuth from '../../../../store/auth/hooks/useAuth';

const useQuery = () => {
  const { jwt } = useAuth();

  const fetchList = () => {
    const apiName = 'AdminQueries';
    const path = '/listUsersInGroup';

    const headers: any = {};
    headers['Content-Type'] = 'application/json';
    headers.Authorization = jwt;

    const payload: any = {};
    payload.queryStringParameters = { groupname: 'Admin' };
    payload.headers = headers;

    const fetcher = () => API.get(apiName, path, payload).then((res: any) => res);
    const { data, error } = useSWR('users', fetcher);

    return {
      data,
      loading: !error && !data,
      isError: error,
    };
  };

  return { fetchList };
};

export default useQuery;
