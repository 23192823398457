import { Paper, Box, Typography, Link } from '@material-ui/core/';
import { OpenInNew as OpenInNewIcon } from '@material-ui/icons';
import { DefaultIconButton } from '../../../../@common/components/forms';
import useLocal from '../../../../../store/local/hooks/useLocal';
import useBanner from '../../../store/hooks/useBanner';
import useModal from '../../../store/hooks/useModal';
import ContentViewContainer from '../../../../@common/components/details/ContentViewContainer';
import HeaderViewContainer from '../../../../@common/components/details/HeaderViewContainer';
import BannerDetailsView from './Details';

export default () => {
  const { text } = useLocal();
  const { banner, renderLoading } = useBanner();
  const { openModalForEdit } = useModal();
  const titleStyle = { fontWeight: 'bold', fontSize: '.75rem' };

  return (
    <Box>
      <HeaderViewContainer>
        <Link target="_blank" rel="noreferrer" href={banner.path}>
          <Typography variant="body2" sx={titleStyle}>
            {banner.path}
            {' '}
            <OpenInNewIcon sx={{ fontSize: '1rem' }} />
          </Typography>
        </Link>
        <Box>
          <DefaultIconButton onClick={openModalForEdit} text={text.buttons.edit} />
        </Box>
      </HeaderViewContainer>
      <ContentViewContainer>
        <Paper elevation={0} sx={{ my: 4, bgcolor: 'inherit' }}>
          <BannerDetailsView loading={renderLoading} banner={banner} />
        </Paper>
      </ContentViewContainer>
    </Box>
  );
};
