/* eslint-disable import/prefer-default-export */
import useSWR from 'swr';
import useAuth from '../../../../store/auth/hooks/useAuth';
import httpClient from '../../../../utils/httpClient';
import usePageData from '../../../../store/pageData/hooks/usePageData';
import { Student } from './types';

import { USERS_API } from './constants';

const useQuery = () => {
  const { jwt } = useAuth();

  const { roleTaxonomies } = usePageData();

  const fetchList = (page: number) => {
    const url = `${USERS_API}?page=${page}`;
    const fetcher = () => httpClient({ url, jwt }).then((res: {data: Student[], meta: any}) => res);
    const { data, error } = useSWR(url, fetcher);

    return {
      data: { ...data, roleTaxonomies },
      loading: !error && !data,
      isError: error,
    };
  };
  const fetchDetails = (id: number) => {
    const url = `${USERS_API}/${id}`;
    const fetcher = () => httpClient({ url, jwt }).then((res: { data: Student }) => res);
    const { data, error } = useSWR(url, fetcher);

    return {
      data: { ...data, roleTaxonomies },
      loading: !error && !data,
      isError: error,
    };
  };

  return {
    fetchList,
    fetchDetails,
  };
};

export default useQuery;
