import { useStateValue } from '../index';
import { Deployment } from '../types';
import { listItemSelected } from '../actions';
import { DetailsStatus } from '../constants';

const useSelect = () => {
  const { state, dispatch } = useStateValue();

  const select = (listItem: Deployment) => {
    // NOTE: avoid unnecessary re-renders if clicking the same user twice;
    if (listItem.id === state.listeItem?.id) return;

    const list = state.list.map((l: any) => ({ ...l, selected: l.id === listItem.id }));
    dispatch(listItemSelected({ list, listItem }));
  };

  return {
    listItem: state.listItem,
    renderDefault: state.liteItemDetailsState === DetailsStatus.EMPTY,
    select,
  };
};

export default useSelect;
