/* eslint-disable no-unused-vars */
import {
  ListStatus,
  FormWriteStatus,
  DetailsStatus,
  FormStatus,
} from './constants';

import {
  PAGINATION_UPDATED,

  SEARCH_LOADING,
  SEARCH_LOADED_SUCCESS,
  LIST_LOADING,
  LIST_LOADED_SUCCESS,
  LIST_LOADED_ERROR,

  DETAILS_SELECTED,
  DETAILS_LOADING,
  DETAILS_LOADED_SUCCESS,
  DETAILS_LOADED_CACHE,
  DETAILS_LOADED_ERROR,

  DRAFT_PRODUCT_ADDED,
  DRAFT_PRODUCT_UPDATED,
  DRAFT_PRODUCT_DELETED,
  PRODUCT_DELETING,
  PRODUCT_DELETED_SUCCESS,

  DRAFT_MATERIAL_ADDED,
  DRAFT_MATERIAL_UPDATED,
  DRAFT_MATERIAL_DELETED,
  MATERIAL_DELETING,
  MATERIAL_DELETED_SUCCESS,

  DRAFT_ASSET_ADDED,
  DRAFT_ASSET_UPDATED,
  DRAFT_ASSET_DELETED,
  ASSET_DELETING,
  ASSET_DELETED_SUCCESS,

  FORM_INACTIVE,
  FORM_SUBMITTING,
  FORM_SUBMITTED_SUCCESS,
  FORM_SUBMITTED_ERROR,

  MODAL_ASSET_NEW_OPEN,
  MODAL_ASSET_ADD_OPEN,
  MODAL_ASSET_EDIT_OPEN,
  MODAL_ASSET_DRAFT_OPEN,
  MODAL_MATERIAL_NEW_OPEN,
  MODAL_MATERIAL_ADD_OPEN,
  MODAL_MATERIAL_EDIT_OPEN,
  MODAL_MATERIAL_DRAFT_OPEN,
  MODAL_PRODUCT_NEW_OPEN,
  MODAL_PRODUCT_EDIT_OPEN,
  MODAL_PRODUCT_DRAFT_OPEN,
  MODAL_PRODUCT_CLOSE,
  MODAL_MATERIAL_CLOSE,
  MODAL_ASSET_CLOSE,
} from './actions';

export const INITIAL_STATE = {
  products: [],
  query: '',
  pagination: {
    currentPage: 1,
    lastPage: null,
    nextPage: null,
    perPage: null,
    totalCount: null,
  },
  product: null,
  material: null,
  asset: null,
  productModalOpen: false,
  materialModalOpen: false,
  assetModalOpen: false,
  listState: ListStatus.LOADING,
  productFormState: FormWriteStatus.CREATE_DRAFT,
  materialFormState: FormWriteStatus.CREATE_DRAFT,
  assetFormState: FormWriteStatus.CREATE_DRAFT,
  productDetailsState: DetailsStatus.EMPTY,
  formState: FormStatus.INACTIVE,
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case PAGINATION_UPDATED:
      return {
        ...state,
        pagination: { ...state.pagination, currentPage: action.payload },
      };
    case SEARCH_LOADING:
      return {
        ...state,
        query: action.payload,
        listState: ListStatus.SEARCHING,
        products: [],
        product: null,
        productDetailsState: DetailsStatus.EMPTY,
        pagination: {
          currentPage: 1,
          lastPage: null,
          nextPage: null,
          perPage: null,
          totalCount: null,
        },
      };
    case SEARCH_LOADED_SUCCESS:
      return {
        ...state,
        listState: ListStatus.SEARCH_SUCCESS,
        products: action.payload.data,
        pagination: action.payload.meta,
      };
    case LIST_LOADING:
      return {
        ...state,
        listState: ListStatus.LOADING,
        products: [],
        pagination: state.pagination,
      };
    case LIST_LOADED_SUCCESS:
      return {
        ...state,
        listState: ListStatus.LOADED_SUCCESS,
        products: action.payload.data,
        pagination: action.payload.meta,
      };
    case DETAILS_SELECTED:
      return {
        ...state,
        product: action.payload.product,
        products: action.payload.products,
        formState: FormStatus.INACTIVE,
        productDetailsState: DetailsStatus.SELECTED,
      };
    case DETAILS_LOADING:
      return {
        ...state,
        productDetailsState: DetailsStatus.LOADING,
      };
    case DETAILS_LOADED_SUCCESS:
      return {
        ...state,
        product: action.payload.product,
        products: action.payload.products,
        productDetailsState: DetailsStatus.LOADED_SUCCESS,
      };
    case DETAILS_LOADED_CACHE:
      return {
        ...state,
        productDetailsState: DetailsStatus.LOADED_SUCCESS,
      };
    case DRAFT_PRODUCT_ADDED:
    case DRAFT_PRODUCT_UPDATED:
    case DRAFT_MATERIAL_ADDED:
    case DRAFT_MATERIAL_UPDATED:
    case DRAFT_ASSET_ADDED:
    case DRAFT_ASSET_UPDATED:
      return {
        ...state,
        product: action.payload.product,
        products: action.payload.products,
        productDetailsState: DetailsStatus.LOADED_SUCCESS,
        productModalOpen: false,
        materialModalOpen: false,
        assetModalOpen: false,
      };
    case DRAFT_PRODUCT_DELETED:
      return {
        ...state,
        product: null,
        products: action.payload,
        productDetailsState: DetailsStatus.EMPTY,
        productFormState: FormWriteStatus.CREATE_DRAFT,
      };
    case DRAFT_MATERIAL_DELETED:
    case DRAFT_ASSET_DELETED:
      return {
        ...state,
        product: action.payload.product,
        products: action.payload.products,
      };
    case PRODUCT_DELETING:
    case MATERIAL_DELETING:
    case ASSET_DELETING:
      return {
        ...state,
        formState: FormStatus.SUBMITTING,
      };
    case PRODUCT_DELETED_SUCCESS:
      return {
        ...state,
        product: null,
        productModalOpen: false,
        formState: FormStatus.SUBMITTED_SUCCESS,
        productFormState: FormWriteStatus.CREATE_DRAFT,
        productDetailsState: DetailsStatus.EMPTY,
      };
    case MATERIAL_DELETED_SUCCESS:
    case ASSET_DELETED_SUCCESS:
      return {
        ...state,
        product: action.payload.product,
        products: action.payload.products,
        formState: FormStatus.SUBMITTED_SUCCESS,
        productFormState: FormWriteStatus.CREATE_DRAFT,
        productModalOpen: false,
        materialModalOpen: false,
        assetModalOpen: false,
      };
    case FORM_INACTIVE:
      return {
        ...state,
        formState: FormStatus.INACTIVE,
      };
    case FORM_SUBMITTING:
      return {
        ...state,
        formState: FormStatus.SUBMITTING,
      };
    case FORM_SUBMITTED_SUCCESS:
      return {
        ...state,
        product: action.payload.product,
        products: action.payload.products,
        formState: FormStatus.SUBMITTED_SUCCESS,
        productFormState: FormWriteStatus.CREATE_DRAFT,
        materialFormState: FormWriteStatus.CREATE_DRAFT,
        productModalOpen: false,
        materialModalOpen: false,
        assetModalOpen: false,
      };
    case FORM_SUBMITTED_ERROR:
      return {
        ...state,
        formState: FormStatus.SUBMITTED_ERROR,
      };
    case MODAL_ASSET_NEW_OPEN:
      return {
        ...state,
        product: action.payload,
        assetFormState: FormWriteStatus.CREATE_PUBLISHED,
        assetModalOpen: true,
      };
    case MODAL_ASSET_ADD_OPEN:
      return {
        ...state,
        product: action.payload,
        assetFormState: FormWriteStatus.CREATE_DRAFT,
        assetModalOpen: true,
      };
    case MODAL_ASSET_EDIT_OPEN:
      return {
        ...state,
        asset: action.payload,
        assetFormState: FormWriteStatus.EDIT_PUBLISHED,
        assetModalOpen: true,
      };
    case MODAL_ASSET_DRAFT_OPEN:
      return {
        ...state,
        asset: action.payload,
        assetFormState: FormWriteStatus.EDIT_DRAFT,
        assetModalOpen: true,
      };
    case MODAL_MATERIAL_NEW_OPEN:
      return {
        ...state,
        materialFormState: FormWriteStatus.CREATE_PUBLISHED,
        materialModalOpen: true,
      };
    case MODAL_MATERIAL_ADD_OPEN:
      return {
        ...state,
        materialFormState: FormWriteStatus.CREATE_DRAFT,
        materialModalOpen: true,
      };
    case MODAL_MATERIAL_EDIT_OPEN:
      return {
        ...state,
        material: action.payload.material,
        product: action.payload.product,
        materialFormState: FormWriteStatus.EDIT_PUBLISHED,
        materialModalOpen: true,
      };
    case MODAL_MATERIAL_DRAFT_OPEN:
      return {
        ...state,
        material: action.payload.material,
        product: action.payload.product,
        materialFormState: FormWriteStatus.EDIT_DRAFT,
        materialModalOpen: true,
      };
    case MODAL_PRODUCT_NEW_OPEN:
      return {
        ...state,
        productModalOpen: true,
        productFormState: FormWriteStatus.CREATE_DRAFT,
      };
    case MODAL_PRODUCT_EDIT_OPEN:
      return {
        ...state,
        productModalOpen: true,
        productFormState: FormWriteStatus.EDIT_PUBLISHED,
      };
    case MODAL_PRODUCT_DRAFT_OPEN:
      return {
        ...state,
        productModalOpen: true,
        productFormState: FormWriteStatus.EDIT_DRAFT,
      };
    case MODAL_PRODUCT_CLOSE:
      return {
        ...state,
        productModalOpen: false,
        productFormState: FormWriteStatus.CREATE_DRAFT,
      };
    case MODAL_MATERIAL_CLOSE:
      return {
        ...state,
        materialModalOpen: false,
        materialFormState: FormWriteStatus.CREATE_DRAFT,
      };
    case MODAL_ASSET_CLOSE:
      return {
        ...state,
        assetModalOpen: false,
        assetFormState: FormWriteStatus.CREATE_DRAFT,
      };
    default:
      return state;
  }
};
