/* eslint-disable react/no-danger */
import {
  Grid,
  Box,
  Stack,
  Typography,
  IconButton,
  ListSubheader,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core/';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { makeStyles } from '@material-ui/core/styles';
import { DefaultIconButton } from '../../../../@common/components/forms';
import { Material } from '../../../store/types';
import useLocal from '../../../../../store/local/hooks/useLocal';
import useAssetForm from '../../../store/hooks/useAssetForm';
import useAssetModal from '../../../store/hooks/useAssetModal';
import useAssetSelect from '../../../store/hooks/useAssetSelect';

const useStyles = makeStyles({
  label: {
    lineHeight: 0,
    fontSize: '.65rem',
    fontWeight: 'bold',
  },
  value: {
    fontSize: '.65rem',
    overflowWrap: 'anywhere',
  },
});

const materialUrlListStyle = {
  width: '100%',
  bgcolor: 'background.paper',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  borderRadius: 2,
  paddingTop: 0,
  paddingBottom: 0,
};

export default ({ material, isDraft }: { material: Material, isDraft: boolean }) => {
  const classes = useStyles();
  const { text } = useLocal();
  const { selectAssetForEdit, selectDraftAssetForEdit } = useAssetSelect();
  const { openNewAssetModal, openDraftAssetModal } = useAssetModal();
  const { deleteDraftAsset } = useAssetForm();

  return (
    <Box mt={2}>
      <Typography variant="overline" className={classes.label}>
        {text.package.details.materialUrlsLabel}
        {' '}
        (
        {material.assets.length}
        )
      </Typography>

      <IconButton
        aria-label="open-modal"
        size="small"
        onClick={() => (isDraft ? openDraftAssetModal(material.id) : openNewAssetModal(material.id))}
      >
        <AddCircleOutlineIcon sx={{ fontSize: '1rem' }} />
      </IconButton>

      <List
        component="div"
        disablePadding
        sx={materialUrlListStyle}
        subheader={(
          <ListSubheader sx={{ background: '#fafafa' }} component="div" id="nested-list-subheader">
            <Stack direction="row">
              <Grid container sx={{ px: 0 }}>
                <Grid item sm={4}>
                  <Typography variant="overline" className={classes.label}>{text.package.details.materialUrlTitleLabel}</Typography>
                </Grid>
                <Grid item sm={3}>
                  <Typography variant="overline" className={classes.label}>{text.package.details.materialUrlContentUrlLabel}</Typography>
                </Grid>
                <Grid item sm={1}>
                  <Typography variant="overline" className={classes.label}>{text.package.details.materialUrlTypeLabel}</Typography>
                </Grid>
                <Grid item sm={1}>
                  <Typography variant="overline" className={classes.label}>{text.package.details.materialUrlSizeLabel}</Typography>
                </Grid>
                <Grid item sm={3}>
                  <Typography variant="overline" className={classes.label}>{text.package.details.materialUrlButtonTypeLabel}</Typography>
                </Grid>
              </Grid>
              {isDraft && <Box sx={{ width: '200px' }} />}
              {!isDraft && <Box sx={{ width: '100px' }} />}
            </Stack>
          </ListSubheader>
        )}
      >
        {material.assets.length === 0 && (
          <ListItem component="div">
            <Box sx={{ textAlign: 'center', width: '100%' }}>
              <Typography component="p" sx={{ color: '#888', my: 1 }} variant="caption">{text.package.details.emptyMaterialUrlText}</Typography>
              <DefaultIconButton
                primary
                text={text.package.details.addNewMaterialUrlText}
                onClick={() => (isDraft ? openDraftAssetModal(material.id) : openNewAssetModal(material.id))}
              />
            </Box>
          </ListItem>
        )}

        {material.assets.map((x) => (
          <ListItem
            component="div"
            dense
            divider
            key={x.id}
          >
            <ListItemText
              primary={(
                <Stack direction="row">
                  <Grid container spacing={1}>
                    <Grid item sm={4}>
                      <p
                        className={classes.value}
                        dangerouslySetInnerHTML={{ __html: x.title }}
                      />
                    </Grid>
                    <Grid item sm={3}>
                      <Typography variant="body2" className={classes.value}>{x.content}</Typography>
                    </Grid>
                    <Grid item sm={1}>
                      <Typography variant="body2" className={classes.value}>{x.type}</Typography>
                    </Grid>
                    <Grid item sm={1}>
                      <Typography variant="body2" className={classes.value}>{x.size}</Typography>
                    </Grid>
                    <Grid item sm={3}>
                      <img src={x.buttonTypeUrl} alt={x.type} width="150" height="27" />
                    </Grid>
                  </Grid>
                  {isDraft
                    ? (
                      <Box sx={{ width: '200px', textAlign: 'right' }}>
                        <DefaultIconButton onClick={() => deleteDraftAsset(x)} text={text.buttons.delete} />
                        <DefaultIconButton onClick={() => selectDraftAssetForEdit(x)} text={text.buttons.edit} />
                      </Box>
                    )
                    : (
                      <Box sx={{ width: '100px', textAlign: 'right' }}>
                        <DefaultIconButton onClick={() => selectAssetForEdit(x)} text={text.buttons.edit} />
                      </Box>
                    )}
                </Stack>
              )}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};
