import { Student } from './types';
import { Role } from '../../../../store/pageData/types';

import dateToJST from '../../../../utils/date';

interface IMapperProp {
  data: any
  roles: Role[]
}

const roleDesc = (
  userRoles: string, roles: any,
) => {
  const array = userRoles.split(',').map(Number).sort((a: any, b: any) => a - b);
  let s = '';
  array.forEach((a: any, index: number) => {
    s += roles.find((r: any) => a === r.id)!.title;
    if (index !== array.length - 1) {
      s += ', ';
    }
  });

  return s;
};

export const mapStudentListToStudent = ({
  data,
  roles,
}: IMapperProp) => {
  const students: Student[] = [];

  data.data.forEach((i: any) => {
    students.push({
      id: i.id,
      email: i.email,
      deleted: i.deleted,
      validAt: dateToJST(i.validAt),
      createdAt: dateToJST(i.createdAt),
      confirmedAt: dateToJST(i.confirmedAt),
      userRoles: i.userRoles,
      userRolesDesc: roleDesc(i.userRoles, roles),
    });
  });

  return { ...data, data: students }; // overwrite data.data, meta stays the same
};

export const mapStudentDetailsToStudent = ({ data, roles }: IMapperProp) => {
  const student = {} as Student;
  student.id = data.data.id;
  student.email = data.data.email;
  student.deleted = data.data.deleted;
  student.validAt = dateToJST(data.data.validAt);
  student.createdAt = dateToJST(data.data.createdAt);
  student.confirmedAt = dateToJST(data.data.confirmedAt);
  student.userRoles = data.data.userRoles;
  student.userRolesDesc = roleDesc(data.data.userRoles, roles);
  return student;
};

export const mapStudentFormFieldsToStudent = ({ data, roles }: IMapperProp) => ({
  id: data.id.value,
  email: data.email.value,
  deleted: data.deleted.value,
  validAt: dateToJST(data.validAt.value),
  confirmedAt: dateToJST(data.confirmedAt.value),
  createdAt: dateToJST(data.createdAt.value),
  userRoles: data.userRoles.value,
  userRolesDesc: roleDesc(data.userRoles.value, roles),
  selected: true,
});
