/* eslint-disable react/no-array-index-key */
import {
  Box,
  Stack,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  FormControl,
  Select,
  MenuItem,
  Divider,
} from '@material-ui/core/';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

interface IProp {
  currentPage: number
  perPage: number
  totalCount: number
  lastPage: number
  paginate: Function
}

export default ({
  currentPage,
  perPage,
  totalCount,
  lastPage,
  paginate,
}: IProp) => {
  const handleChange = (event: any) => paginate(event.target.value as number);
  const totalPageCount = new Array<number>(lastPage).fill(0);
  const lastPageNumber = lastPage === currentPage ? totalCount : currentPage * perPage;

  return (
    <Box>
      <Divider />
      <Stack sx={{ px: 2, height: '52px' }} direction="row" justifyContent="flex-end" alignItems="center" spacing={3}>
        {totalCount && (
          <Typography variant="caption">
            {(currentPage * perPage) - perPage + 1}
            {' - '}
            {lastPageNumber}
            {' '}
            of
            {' '}
            {totalCount}
          </Typography>
        )}

        <FormControl variant="standard">
          <Select value={currentPage || ''} onChange={handleChange}>
            {totalPageCount.map((_: number, i: number) => <MenuItem key={i} value={i + 1}><Typography variant="caption">{i + 1}</Typography></MenuItem>)}
          </Select>
        </FormControl>

        <ToggleButtonGroup
          size="small"
          color="primary"
          value=""
          exclusive
          onChange={() => { }}
        >
          <ToggleButton disabled={currentPage <= 1} value={currentPage || 0} onClick={() => paginate(currentPage - 1)}>
            <ChevronLeftIcon />
          </ToggleButton>
          <ToggleButton disabled={currentPage >= lastPage} value={currentPage || 0} onClick={() => paginate(currentPage + 1)}>
            <ChevronRightIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>
    </Box>
  );
};
