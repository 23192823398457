import { useEffect } from 'react';
import { useStateValue } from '../index';
import { productDetailsWillLoad, productDetailsLoadedWithSuccess, productDetailsLoadedWithCache } from '../actions';
import { mapProductDetailsToProduct } from '../mappers';
import { DetailsStatus } from '../constants';
import useQuery from '../queries';
import useAlert from '../../../../store/alert/hooks/useAlert';

const useProduct = () => {
  const { fetchDetails } = useQuery();
  const { state, dispatch } = useStateValue();
  const { id } = state.product;
  const { data, loading } = fetchDetails(id);
  const { showFailureAlert } = useAlert();

  const setData = () => {
    if (data && data.message) {
      showFailureAlert();
      return;
    }

    const product = mapProductDetailsToProduct({
      pages: data.pageTaxonomies,
      categories: data.categoryTaxonomies,
      subcategories: data.subcategoryTaxonomies,
      data: data || [],
    });

    const products = state.products.map((p: any) => (p.id === product.id
      ? { ...product, materials: product.materials, selected: p.selected, createdAt: p.createdAt }
      : p));

    dispatch(productDetailsLoadedWithSuccess({ products, product }));
  };

  const detailsEventHandler = () => {
    if (id === null) {
      dispatch(productDetailsLoadedWithCache());
    } else if (loading) {
      dispatch(productDetailsWillLoad());
    } else {
      setData();
    }
  };

  useEffect(() => { detailsEventHandler(); }, [id, loading]);

  return {
    product: state.product,
    renderLoading: state.productDetailsState !== DetailsStatus.LOADED_SUCCESS,
  };
};

export default useProduct;
