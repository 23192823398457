import { OperationState, ModalStatus } from './constants';
import { ASYNC_OPERATION_SUCCESS, ASYNC_OPERATION_ERROR, MODAL_CLOSE } from './actions';

export const INITIAL_STATE = {
  alertMessage: '',
  operationState: OperationState.INACTIVE,
  modalState: ModalStatus.CLOSE,
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case ASYNC_OPERATION_SUCCESS:
      return {
        ...state,
        alertMessage: action.payload,
        operationState: OperationState.SUCCESS,
        modalState: ModalStatus.OPEN,
      };
    case ASYNC_OPERATION_ERROR:
      return {
        ...state,
        alertMessage: action.payload,
        operationState: OperationState.ERROR,
        modalState: ModalStatus.OPEN,
      };
    case MODAL_CLOSE:
      return {
        ...state,
        operationState: OperationState.INACTIVE,
        modalState: ModalStatus.CLOSE,
      };
    default:
      return state;
  }
};
