import StateProvider from './store';
import BannerList from './sections/list';
import BannerDetails from './sections/details';
import BannerForms from './sections/forms';

export default () => (
  <StateProvider>
    <BannerList />
    <BannerDetails />
    <BannerForms />
  </StateProvider>
);
