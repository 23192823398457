import { List } from '@material-ui/core/';
import { Student } from '../../../store/types';
import PageItem from './PageItem';
import useStudentSelect from '../../../store/hooks/useStudentSelect';
import Loader from './Loader';

const styles = {
  width: '30vw',
  minWidth: '400px',
  height: 'calc(100vh - 102px)',
  bgcolor: 'background.paper',
  overflow: 'scroll',
  py: 0,
};

interface IProp {
  students: Student[]
  loading?: boolean
}

export default ({ students, loading }: IProp) => {
  const { select } = useStudentSelect();

  const PageItemsView = students.map((i) => <PageItem select={select} key={`key-${i.id}`} item={i} />);
  const ListView = <List sx={styles}>{PageItemsView}</List>;

  return loading ? <Loader /> : <>{ListView}</>;
};
