import { useEffect } from 'react';
import { useStateValue } from '../index';
import {
  paginated,
  searchLoadingStarted,
  searchLoadedWithSuccess,
  pageLoadingStarted,
  pageLoadedWithSuccess,
} from '../actions';
import { mapProductListToProduct } from '../mappers';
import { ListStatus } from '../constants';
import useQuery from '../queries';
import useList from '../../../../store/list/hooks/useList';
import useAlert from '../../../../store/alert/hooks/useAlert';

const useProducts = () => {
  const { fetchList } = useQuery();
  const { setProductsCount } = useList();
  const { showFailureAlert } = useAlert();

  const { state, dispatch } = useStateValue();
  const { query, listState, pagination: { currentPage } } = state;
  const { data, loading } = fetchList(currentPage, query);
  const hasQuery = query.length > 0;
  const totalCount = data?.meta?.totalCount;

  const setData = () => {
    if (data && data.message) {
      showFailureAlert();
      return;
    }

    const mapped = mapProductListToProduct({
      pages: data.pageTaxonomies,
      categories: data.categoryTaxonomies,
      subcategories: data.subcategoryTaxonomies,
      product: state.product,
      data: data || [],
    });

    const products = state.listState === ListStatus.SEARCHING
      ? searchLoadedWithSuccess(mapped)
      : pageLoadedWithSuccess(mapped);

    if (!hasQuery) setProductsCount(data.meta.totalCount);

    dispatch(products);
  };

  const paginate = (page: number) => dispatch(paginated(page));
  const search = (params: string) => dispatch(searchLoadingStarted(params));
  const listEventHandler = () => (loading ? dispatch(pageLoadingStarted()) : setData());

  useEffect(() => { listEventHandler(); }, [totalCount, listState, currentPage, query, loading]);

  return {
    products: state.products,
    pagination: state.pagination,
    loading,
    hasQuery,
    paginate,
    search,
  };
};

export default useProducts;
