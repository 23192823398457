import StateProvider from './store';
import StudentList from './sections/list';
import StudentDetails from './sections/details';
import StudentForms from './sections/forms';

export default () => (
  <StateProvider>
    <StudentList />
    <StudentDetails />
    <StudentForms />
  </StateProvider>
);
