/* eslint-disable no-unused-vars */
import { mutate } from 'swr';
import { useStateValue } from '../index';
import {
  formSubmitted,
  formSubmittedWithSuccess,
  formSubmittedWithError,
  formStateReset,
  studentDeletedWithSuccess,
  studentDeleteConfirmed,
} from '../actions';
import { FormWriteStatus, FormStatus, USERS_API } from '../constants';
import { mapStudentFormFieldsToStudent } from '../mapper';
import httpClient from '../../../../../utils/httpClient';
import useAuth from '../../../../../store/auth/hooks/useAuth';
import useAlert from '../../../../../store/alert/hooks/useAlert';

const useStudentForm = () => {
  const { jwt } = useAuth();
  const { state, dispatch } = useStateValue();
  const { showSuccessAlert, showFailureAlert } = useAlert();

  const handle500 = ({ _ }: any) => {
    dispatch(formSubmittedWithError());
    showFailureAlert();
  };
  const refetch = () => mutate(`${USERS_API}?page=${state.pagination.currentPage}`);
  const refetchDetails = () => mutate(`${USERS_API}/${state.student.id}`);

  const deleteStudent = () => {
    let student = state.students.find((s: any) => s.selected);
    const url = `${USERS_API}/${student.id}`;

    const handle200 = (response: any) => {
      if (response.data) {
        const students = state.students.map((s: any) => (s.id === student.id ? student : s));
        student = { ...student, deleted: 1 };
        dispatch(studentDeletedWithSuccess({ students, student }));
        refetch();
        refetchDetails();
        showSuccessAlert();
      } else if (response.message) {
        dispatch(formSubmittedWithError());
        showFailureAlert();
      }
    };

    const body: any = {};
    body.user_roles = student.userRoles;
    body.deleted = '1';

    dispatch(studentDeleteConfirmed());

    httpClient({ url, jwt, method: 'PUT', body })
      .then((res: any) => handle200(res))
      .catch((res) => handle500(res));
  };

  const submitForm = ({ fields, roleTaxonomies }: any) => {
    const student = mapStudentFormFieldsToStudent({ data: fields, roles: roleTaxonomies });
    if (state.studentFormState === FormWriteStatus.EDIT) {
      const url = `${USERS_API}/${student.id}`;

      const handle200 = (response: any) => {
        if (response.data) {
          const students = state.students.map((s: any) => (s.id === student.id ? student : s));
          dispatch(formSubmittedWithSuccess({ students, student }));
          refetch();
          refetchDetails();
          showSuccessAlert();
        } else if (response.message) {
          dispatch(formSubmittedWithError());
          showFailureAlert();
        }
      };

      const body: any = {};
      body.user_roles = student.userRoles;
      body.deleted = student.deleted.toString();

      dispatch(formSubmitted());

      httpClient({ url, jwt, method: 'PUT', body })
        .then((res: any) => handle200(res))
        .catch((error) => handle500(error));
    }
  };

  const resetFormState = () => dispatch(formStateReset());

  return {
    submitting: state.formState === FormStatus.SUBMITTING,
    submittedWithSuccess: state.formState === FormStatus.SUBMITTED_SUCCESS,
    submittedWithError: state.formState === FormStatus.SUBMITTED_ERROR,
    submitForm,
    resetFormState,
    deleteStudent,
  };
};

export default useStudentForm;
