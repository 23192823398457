import { useStateValue } from '../../index';
import { languageSelected } from '../actions';

const useLocal = () => {
  const { state, dispatch } = useStateValue();

  const setLanguage = (lang: string) => {
    if (!lang) return;
    window.localStorage.setItem('lang', JSON.stringify({ value: lang }));
    dispatch(languageSelected(lang));
  };

  const getLanguage = () => {
    const item = window.localStorage.getItem('lang');
    const lang = JSON.parse(item!);
    return lang === null ? 'jp' : lang.value;
  };

  const lang = getLanguage();

  return {
    text: lang === 'jp' ? state.local.jp : state.local.en,
    language: lang,
    setLanguage,
  };
};

export default useLocal;
