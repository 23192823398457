import {
  Typography,
  List,
  ListItem,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { makeStyles, styled } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  icon: { color: 'inherit !important' },
  formLabelTitle: { color: theme.palette.text.primary },
  labelTitle: {
    lineHeight: 1,
    fontWeight: 600,
  },
}));

const style = {
  width: '100%',
  bgcolor: 'background.paper',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  borderRadius: 4,
  paddingTop: 0,
  paddingBottom: 0,
};

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  display: 'block',
  paddingBottom: theme.spacing(1),
}));

interface IListItem {
  'name': string
}

interface IProp {
  title: string
  changeHandler: Function
  defaultValue: string
  items: IListItem[]
  image?: boolean
}

export default ({
  title,
  defaultValue,
  items,
  changeHandler,
  image,
}: IProp) => {
  const classes = useStyles();

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => changeHandler((event.target as HTMLInputElement).value);

  return (
    <>
      <Title variant="caption">{title}</Title>
      <RadioGroup
        aria-label="page"
        value={defaultValue}
        name="radio-buttons-group"
        onChange={handleChange}
        sx={{ mb: 2 }}
      >
        <List sx={style} component="nav" aria-label="page">
          {items.map((i, index) => (
            <ListItem key={i.name} divider={index + 1 !== items.length}>
              <FormControlLabel
                value={i.name}
                control={<Radio size="small" />}
                label={(
                  image
                    ? <img src={`${process.env.REACT_APP_CDN_URL}/img/${i.name}.jpg`} alt={i.name} />
                    : <Typography className={classes.labelTitle} variant="caption" component="p">{i.name}</Typography>
                )}
              />
            </ListItem>
          ))}
        </List>
      </RadioGroup>
    </>
  );
};
