/* eslint-disable semi */
import { useState, useEffect } from 'react';
import { Box, Grid, Stack, Container } from '@material-ui/core';

import {
  FullWidthTextField,
  FullWidthCheckbox,
  SectionTitle,
  RadioList,
  TextEditor,
  FullpageDialog,
  FullpageLoader,
  ConfirmationDialog,
} from '../../../../@common/components/forms';

import ImageUpload from '../../../components/ImageUpload'
import CategoriesDropdown from './components/CategoriesDropdown';
import useProductForm from '../../../store/hooks/useProductForm';
import useProductModal from '../../../store/hooks/useProductModal';
import useForm from '../../../store/hooks/useForm';
import useLocal from '../../../../../store/local/hooks/useLocal';
import usePageData from '../../../../../store/pageData/hooks/usePageData';

export default () => {
  const { modal, closeProductModal } = useProductModal();
  const { text } = useLocal();
  const {
    pageTaxonomies,
    categoryTaxonomies,
    subcategoryTaxonomies,
    categoryDropdownItems,
  } = usePageData();

  const {
    product,
    submitting,
    submitForm,
    deleteProduct,
  } = useProductForm();

  // DELETE dialog (todo: move this out into its own custom hook)
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const handleConfirmationOpen = () => setOpenConfirmation(true);
  const handleConfirmationClose = () => setOpenConfirmation(false);
  const handleDeleteConfirmation = () => {
    setOpenConfirmation(false);
    deleteProduct();
  };

  const getFormFields = (edit: boolean) => ({
    id: { value: edit ? product.id : null, required: false, hasError: false },
    page: { value: edit ? product.pageName : 'メイン(index)', required: true, hasError: false },
    category: { value: edit ? product.categoryTitle : '', required: true, hasError: false },
    subcategory: { value: edit ? product.subcategoryTitle : 'None', required: true, hasError: false },
    bookImageUrl: { value: edit ? product.image : '', url: edit ? product.imageUrl : '', required: false, hasError: false },
    bookTitle: { value: edit ? product.title : '', required: true, hasError: false },
    productId: { value: edit ? product.productId : '', required: true, hasError: false },
    note: { value: edit ? product.notes : '', required: false, hasError: false },
    metadata: { value: edit ? product.metadata : '', required: false, hasError: false },
    password: { value: edit ? product.password : '', required: false, hasError: false },
    description: { value: edit ? product.description : '', required: false, hasError: false },
    isPublished: { value: edit ? product.isPublished : true, required: true, hasError: false },
  });

  const {
    formFields,
    setValue,
    setCategory,
    setFormFields,
    submit,
  } = useForm({
    initialValue: getFormFields(modal.edit),
    onSubmit: (fields: any) => submitForm({ fields, pageTaxonomies, categoryTaxonomies, subcategoryTaxonomies }),
  });

  const textfields = {
    grid: [{
      value: formFields.bookTitle.value,
      title: text.package.form.titleLabel,
      onSave: (value: any) => setValue('bookTitle', value),
      hasError: formFields.bookTitle.hasError,
      errorMessage: text.package.form.titleErrorMessage,
    }, {
      value: formFields.productId.value,
      title: text.package.form.productIdLabel,
      onSave: (value: any) => setValue('productId', value),
      hasError: formFields.productId.hasError,
      errorMessage: text.package.form.productIdErrorMessage,
    }],
    fullwidth: [{
      value: formFields.metadata.value,
      title: text.package.form.metadataLabel,
      onSave: (value: any) => setValue('metadata', value),
      hasError: formFields.metadata.hasError,
      errorMessage: '',
    }, {
      value: formFields.note.value,
      title: text.package.form.notesLabel,
      onSave: (value: any) => setValue('note', value),
      hasError: formFields.note.hasError,
      errorMessage: '',
    }],
  };

  const visibilityOptions = [
    { name: 'Yes' },
    { name: 'No' },
  ];

  useEffect(() => { setFormFields(getFormFields(modal.edit)) }, [modal.open]);

  return (
    <>
      <FullpageDialog
        open={modal.open}
        showDelete={modal.showDelete}
        submitText={text.appBarButtons[modal.action]}
        deleteText={text.appBarButtons.delete}
        appBarTitle={text.package.appBar.package[modal.action]}
        onClose={closeProductModal}
        onSubmit={submit}
        onConfirmDelete={handleConfirmationOpen}
      >
        <Container maxWidth="sm">
          <Box my={16} />
          <SectionTitle text={text.package.form.packageFormTitle} />

          <RadioList
            title={text.package.form.publishLabel}
            defaultValue={formFields.isPublished.value ? 'Yes' : 'No'}
            items={visibilityOptions}
            changeHandler={(selected: any) => setValue('isPublished', selected === 'Yes' ? 1 : 0)}
          />

          <RadioList
            title={text.package.form.pageTypeLabel}
            defaultValue={formFields.page.value}
            items={pageTaxonomies}
            changeHandler={(selected: any) => setValue('page', selected)}
          />

          <Grid container>
            <Grid item xs={12} sm={8}>
              <Stack direction="column" justifyContent="flex-start">
                <Box>
                  <CategoriesDropdown
                    fieldTitle={text.package.form.categoryLabel}
                    title={text.package.form.categoryButtonText}
                    selected={formFields.subcategory.value}
                    items={categoryDropdownItems.find((i: any) => i.name === formFields.page.value)}
                    listClickHandler={(selected: any) => setCategory(selected)}
                    hasError={formFields.category.hasError}
                    errorMessage={text.package.form.categoryErrorMessage}
                  />
                </Box>
                {textfields.grid.map((i: any) => (
                  <FullWidthTextField
                    key={i.title}
                    value={i.value}
                    title={i.title}
                    onSave={i.onSave}
                    hasError={i.hasError}
                    errorMessage={i.errorMessage}
                  />
                ))}
              </Stack>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Stack direction="row" justifyContent="flex-end">
                <ImageUpload
                  defaultValue={formFields.bookImageUrl.value}
                  inputId="package-image"
                  placeholder={text.package.form.imagePlaceholderText}
                  chooseImageText={text.package.form.imageChooseImageText}
                  onUpload={(value: any) => setValue('bookImageUrl', value)}
                  hasError={formFields.bookImageUrl.hasError}
                  errorMessage={text.package.form.imageErrorMessage}
                />
              </Stack>
            </Grid>
          </Grid>
          {textfields.fullwidth.map((i: any) => (
            <FullWidthTextField
              key={i.title}
              value={i.value}
              title={i.title}
              onSave={i.onSave}
              hasError={i.hasError}
              errorMessage={i.errorMessage}
            />
          ))}
          <FullWidthCheckbox
            value={formFields.password.value}
            title={text.package.form.requirePasswordLabel}
            placeholder={text.package.form.passwordPlaceholderText}
            onSave={(value: any) => setValue('password', value)}
          />
          <TextEditor
            value={formFields.description.value}
            title={text.package.form.descriptionLabel}
            onSave={(value: any) => setValue('description', value)}
            hasError={formFields.description.hasError}
            errorMessage=""
          />
          <Box my={16} />
        </Container>

        <FullpageLoader open={submitting} />
      </FullpageDialog>

      <ConfirmationDialog
        open={openConfirmation}
        onClose={handleConfirmationClose}
        onConfirm={handleDeleteConfirmation}
        title={text.package.deleteModal.title}
        content={text.package.deleteModal.body}
        no={text.package.deleteModal.cancel}
        ok={text.package.deleteModal.confirm}
      />
    </>
  );
};
