/* eslint-disable no-unused-vars */
import { useEffect } from 'react';
import { useStateValue } from '../index';
import { pageLoadingStarted, pageLoadedWithSuccess } from '../actions';
import { mapJsonListToObjectList } from '../mappers';
import useQuery from '../queries';
import useList from '../../../../store/list/hooks/useList';

const useProducts = () => {
  const { fetchList } = useQuery();
  const { setPagesCount } = useList();

  const { state, dispatch } = useStateValue();
  const { data, loading } = fetchList();

  const setData = () => {
    const mapped = mapJsonListToObjectList({
      banner: state.banner,
      data: data || [],
    });

    const banners = pageLoadedWithSuccess(mapped);

    setPagesCount(mapped.data.length);
    dispatch(banners);
  };

  const listEventHandler = () => (loading ? dispatch(pageLoadingStarted()) : setData());

  useEffect(() => { listEventHandler(); }, [loading]);

  return {
    banners: state.banners,
    loading,
  };
};

export default useProducts;
