/* eslint-disable react/require-default-props */
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';

interface IProp {
  open: boolean
  onClose: Function
  onConfirm: Function
  title: string
  content: string
  no: string
  ok: string
}

export default ({
  open,
  onClose,
  onConfirm,
  title,
  content,
  no,
  ok,
}: IProp) => (
  <Dialog
    open={open}
    onClose={() => onClose()}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => onClose()}>{no}</Button>
      <Button onClick={() => onConfirm()} autoFocus>{ok}</Button>
    </DialogActions>
  </Dialog>
);
