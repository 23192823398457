/* eslint-disable no-unused-vars */
import {
  LIST_LOADING,
  LIST_LOADED_SUCCESS,
  LIST_LOADED_ERROR,
  DETAILS_SELECTED,
  DETAILS_LOADING,
  DETAILS_LOADED_SUCCESS,
  MODAL_STUDENT_CLOSE,
  MODAL_STUDENT_EDIT_OPEN,
  FORM_INACTIVE,
  FORM_SUBMITTING,
  FORM_SUBMITTED_SUCCESS,
  FORM_SUBMITTED_ERROR,
  STUDENT_DELETING,
  STUDENT_DELETED_SUCCESS,
} from './actions';
import {
  FormWriteStatus,
  DetailsStatus,
  FormStatus,
} from './constants';

export const INITIAL_STATE = {
  students: [],
  student: null,
  pagination: null,
  studentDetailsState: DetailsStatus.EMPTY,
  studentModalOpen: false,
  studentFormState: FormWriteStatus.CREATE,
  formState: FormStatus.INACTIVE,
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case LIST_LOADING:
      return {
        ...state,
        students: [],
        pagination: state.pagination,
      };
    case LIST_LOADED_SUCCESS:
      return {
        ...state,
        students: action.payload.data,
        pagination: action.payload.meta,
      };
    case DETAILS_SELECTED:
      return {
        ...state,
        student: action.payload.student,
        students: action.payload.students,
        studentDetailsState: DetailsStatus.SELECTED,
      };
    case DETAILS_LOADING:
      return {
        ...state,
        studentDetailsState: DetailsStatus.LOADING,
      };
    case DETAILS_LOADED_SUCCESS:
      return {
        ...state,
        student: action.payload.student,
        students: action.payload.students,
        studentDetailsState: DetailsStatus.LOADED_ERROR,
      };
    case MODAL_STUDENT_CLOSE:
      return {
        ...state,
        studentFormState: FormWriteStatus.CREATE,
        studentModalOpen: false,
      };

    case MODAL_STUDENT_EDIT_OPEN:
      return {
        ...state,
        studentFormState: FormWriteStatus.EDIT,
        studentModalOpen: true,
      };

    case STUDENT_DELETING:
      return {
        ...state,
        formState: FormStatus.SUBMITTING,
      };
    case STUDENT_DELETED_SUCCESS:
      return {
        ...state,
        student: action.payload.student,
        students: action.payload.students,
        formState: FormStatus.SUBMITTED_SUCCESS,
        studentFormState: FormWriteStatus.CREATE,
        studentModalOpen: false,
      };
    case FORM_INACTIVE:
      return {
        ...state,
        formState: FormStatus.INACTIVE,
      };

    case FORM_SUBMITTING:
      return {
        ...state,
        formState: FormStatus.SUBMITTING,
      };
    case FORM_SUBMITTED_SUCCESS:
      return {
        ...state,
        student: action.payload.student,
        students: action.payload.students,
        formState: FormStatus.SUBMITTED_SUCCESS,
        studentFormState: FormWriteStatus.CREATE,
        studentModalOpen: false,
      };
    case FORM_SUBMITTED_ERROR:
      return {
        ...state,
        formState: FormStatus.SUBMITTED_ERROR,
      };
    default:
      return state;
  }
};
