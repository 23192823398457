import { useStateValue } from '../index';
import { Admin } from '../types';
import { userSelected } from '../actions';
import { DetailsStatus } from '../constants';

const useSelect = () => {
  const { state, dispatch } = useStateValue();

  const select = (user: Admin) => {
    // NOTE: avoid unnecessary re-renders if clicking the same user twice;
    if (user.username === state.user?.username) return;

    const users = state.users.map((u: any) => ({ ...u, selected: u.username === user.username }));
    dispatch(userSelected({ users, user }));
  };

  return {
    user: state.user,
    renderDefault: state.userDetailsState === DetailsStatus.EMPTY,
    select,
  };
};

export default useSelect;
