export const SET_PRODUCTS = 'list/SET_PRODUCTS';
export const setListProducts = (payload: any) => ({ payload, type: SET_PRODUCTS });

export const SET_PAGES = 'list/SET_PAGES';
export const setListPages = (payload: any) => ({ payload, type: SET_PAGES });

export const SET_STUDENTS = 'list/SET_STUDENTS';
export const setListStudents = (payload: any) => ({ payload, type: SET_STUDENTS });

export const SET_ADMINS = 'list/SET_ADMINS';
export const setListAdmins = (payload: any) => ({ payload, type: SET_ADMINS });
