/* eslint-disable no-unused-vars */
import {
  PAGE_INFO_LOADED_SUCCESS,
  PAGE_INFO_LOADED_ERROR,
} from './actions';

export const INITIAL_STATE = {
  pageTaxonomies: [],
  categoryTaxonomies: [],
  subcategoryTaxonomies: [],
  categoryDropdownItems: [],
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case PAGE_INFO_LOADED_SUCCESS:
      return {
        pageTaxonomies: action.payload.pages,
        categoryTaxonomies: action.payload.categories,
        subcategoryTaxonomies: action.payload.subcategories,
        categoryDropdownItems: action.payload.categoryDropdownItems,
        roleTaxonomies: action.payload.roles,
      };

    default:
      return state;
  }
};
