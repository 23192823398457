import { useEffect } from 'react';
import { useStateValue } from '../index';
import { bannerDetailsWillLoad, bannerDetailsLoadedWithSuccess } from '../actions';
import { mapJsonToObject } from '../mappers';
import useQuery from '../queries';

const useBanner = () => {
  const { fetchDetails } = useQuery();
  const { state, dispatch } = useStateValue();
  const { id } = state.banner;
  const { data, loading } = fetchDetails(id);

  const getData = () => {
    const banner = mapJsonToObject({ data });

    const banners = state.banners.map((b: any) => (b.id === banner.id
      ? { ...banner, selected: b.selected, createdAt: b.createdAt }
      : b));

    return { banners, banner };
  };

  const detailsEventHandler = () => {
    if (id === null) {
      // don't care;
    } else if (loading) {
      dispatch(bannerDetailsWillLoad());
    } else {
      dispatch(bannerDetailsLoadedWithSuccess(getData()));
    }
  };

  useEffect(() => { detailsEventHandler(); }, [id, loading]);

  return {
    banner: state.banner,
    renderLoading: loading,
  };
};

export default useBanner;
