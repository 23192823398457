import { useStateValue } from '../index';
import {
  editBannerStarted,
  modalClose,
} from '../actions';
import { FormWriteStatus } from '../constants';

const useProductModal = () => {
  const { state, dispatch } = useStateValue();

  const closeModal = () => dispatch(modalClose());
  const openModalForEdit = () => dispatch(editBannerStarted());

  return {
    modal: {
      open: state.modalOpen,
      showDelete: false,
      action: state.bannerFormState.includes('create') ? 'create' : 'edit',
      edit: state.bannerFormState === FormWriteStatus.EDIT_PUBLISHED,
    },
    closeModal,
    openModalForEdit,
  };
};

export default useProductModal;
