/* eslint-disable no-unused-vars */
import {
  Typography,
  List,
  ListItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Snackbar,
  Alert,
} from '@material-ui/core';
import { makeStyles, styled } from '@material-ui/core/styles';

import { useState, useEffect } from 'react';
import { Role } from '../../../../../../store/pageData/types';

const useStyles = makeStyles((theme) => ({
  icon: { color: 'inherit !important' },
  formLabelTitle: { color: theme.palette.text.primary },
  labelTitle: {
    lineHeight: 1,
    fontWeight: 600,
  },
}));

const style = {
  width: '100%',
  bgcolor: 'background.paper',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  borderRadius: 4,
  paddingTop: 0,
  paddingBottom: 0,
};

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  display: 'block',
  paddingBottom: theme.spacing(1),
}));

interface IProp {
  title: string
  changeHandler: Function
  defaultValue: string
  items: Role[]
  hasError: boolean
  errorMessage: string
}

export default ({
  title,
  defaultValue,
  items,
  changeHandler,
  hasError,
  errorMessage,
}: IProp) => {
  const classes = useStyles();
  const [isOpen, setOpen] = useState(hasError);

  useEffect(() => { setOpen(hasError); }, [hasError]);

  let str = defaultValue;

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>, id: Number,
  ) => {
    if (e.target.checked) {
      if (str) {
        str += `,${id.toString()}`;
      } else {
        str += `${id.toString()}`;
      }
    } else {
      str = str.replace(`${id.toString()},`, '');
      str = str.replace(`,${id.toString()}`, '');
      str = str.replace(id.toString(), '');
    }
    changeHandler(str);
  };

  return (
    <>
      <Title variant="caption">{title}</Title>
      <FormGroup
        aria-label="page"
        sx={{ mb: 2 }}
      >
        <List sx={style} component="nav" aria-label="page">
          {items.map((i, index) => (
            <ListItem key={i.id} divider={index + 1 !== items.length}>
              <FormControlLabel
                value={i.id}
                control={<Checkbox size="small" defaultChecked={defaultValue.includes(i.id.toString())} onChange={(e) => handleChange(e, i.id)} />}
                label={(<Typography className={classes.labelTitle} variant="caption" component="p">{i.title}</Typography>
                )}
              />
            </ListItem>
          ))}
        </List>
      </FormGroup>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isOpen}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
      >
        <Alert onClose={() => setOpen(false)} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};
