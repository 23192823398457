import { useState, useMemo, forwardRef } from 'react';
import { useLocation, Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import {
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
  Collapse,
  Chip,
  ToggleButton,
  ToggleButtonGroup,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BookOutlinedIcon from '@material-ui/icons/BookOutlined';
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined';
import AdminPanelSettingsOutlinedIcon from '@material-ui/icons/AdminPanelSettingsOutlined';
import LocalLibraryOutlinedIcon from '@material-ui/icons/LocalLibraryOutlined';
import WebIcon from '@material-ui/icons/Web';
import TranslateIcon from '@material-ui/icons/Translate';
import SettingsIcon from '@material-ui/icons/Settings';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import LogoutIcon from '@material-ui/icons/Logout';
import PublishIcon from '@material-ui/icons/Publish';
import useAuth from '../../../store/auth/hooks/useAuth';
import useList from '../../../store/list/hooks/useList';
import useLocal from '../../../store/local/hooks/useLocal';

const useStyles = makeStyles({
  drawer: {
    width: '15vw',
    minWidth: '250px',
    flexShrink: 0,
  },
  drawerPaper: {
    width: '15vw',
    minWidth: '250px',
    boxSizing: 'border-box',
  },
});

const chipStyle = {
  marginLeft: '5px',
  fontSize: '0.6125rem',
  height: '20px',
};

interface ListItemLinkProps {
  selected: boolean;
  nested?: boolean;
  icon?: React.ReactElement;
  primary: any;
  to: string;
  count?: number;
}

function ListItemLink(props: ListItemLinkProps) {
  const { selected, nested = false, icon, primary, to, count } = props;

  const renderLink = useMemo(
    () => forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
      <RouterLink to={to} ref={ref} {...itemProps} />
    )),
    [to],
  );

  return (
    <li>
      <ListItem disableRipple selected={selected} sx={{ pl: nested ? 4 : 2, color: '#000 !important' }} button component={renderLink}>
        {icon ? <ListItemIcon sx={{ minWidth: 36 }}>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
        {count && <Chip sx={chipStyle} size="small" label={count} variant="outlined" />}
      </ListItem>
    </li>
  );
}

export default () => {
  const classes = useStyles();
  const location = useLocation();
  const { logoutUser } = useAuth();
  const { text, language, setLanguage } = useLocal();
  const { productsCount, pagesCount, studentsCount, adminsCount } = useList();

  const [openUsers, setOpenUsers] = useState(true);
  const toggleUsers = () => setOpenUsers(!openUsers);

  const [openSettings, setOpenSettings] = useState(true);
  const toggleSettings = () => setOpenSettings(!openSettings);

  const handleChange = (event: React.MouseEvent<HTMLElement>, newLang: string) => setLanguage(newLang);

  return (
    <>
      <Drawer
        variant="permanent"
        anchor="left"
        className={classes.drawer}
        classes={{ paper: classes.drawerPaper }}
      >
        <List
          sx={{ width: '15vw', minWidth: '250px', mt: '12px' }}
          subheader={(
            <ListSubheader component="div" id="nested-list-subheader">
              <Typography variant="overline" display="block" component="p" gutterBottom>
                {text.siteName}
              </Typography>
            </ListSubheader>
          )}
        >
          <ListItemLink
            selected={location.pathname === '/products'}
            to="/products"
            primary={<Typography variant="caption">{text.sideNav.packages}</Typography>}
            icon={<BookOutlinedIcon fontSize="small" />}
            count={productsCount}
          />

          <ListItem button key="Users" onClick={toggleUsers}>
            <ListItemIcon sx={{ minWidth: 36 }}>
              <SupervisorAccountOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={<Typography variant="caption">{text.sideNav.users.primary}</Typography>} />
            {openUsers ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />}
          </ListItem>
          <Collapse in={openUsers} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemLink
                selected={location.pathname === '/admin/users'}
                nested
                to="/admin/users"
                primary={<Typography variant="caption">{text.sideNav.users.secondary.admin}</Typography>}
                icon={<AdminPanelSettingsOutlinedIcon fontSize="small" />}
                count={adminsCount}
              />
              <ListItemLink
                selected={location.pathname === '/admin/students'}
                nested
                to="/admin/students"
                primary={<Typography variant="caption">{text.sideNav.users.secondary.dlc}</Typography>}
                icon={<LocalLibraryOutlinedIcon fontSize="small" />}
                count={studentsCount}
              />
            </List>
          </Collapse>

          <ListItemLink
            selected={location.pathname === '/pages'}
            to="/pages"
            primary={<Typography variant="caption">{text.sideNav.pages}</Typography>}
            icon={<WebIcon fontSize="small" />}
            count={pagesCount}
          />

          <Divider />

          <ListItem button key="Settings" onClick={toggleSettings}>
            <ListItemIcon sx={{ minWidth: 36 }}>
              <SettingsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={<Typography variant="caption">{text.sideNav.settings.primary}</Typography>} />
            {openSettings ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />}
          </ListItem>
          <Collapse in={openSettings} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemLink
                selected={location.pathname === '/publish'}
                nested
                to="/publish"
                primary={<Typography variant="caption">{text.sideNav.settings.secondary.publish}</Typography>}
                icon={<PublishIcon fontSize="small" />}
              />

              <ListItem sx={{ pl: 4, color: '#000 !important' }}>
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <TranslateIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={<Typography variant="caption">{text.sideNav.settings.secondary.language}</Typography>} />
                <ToggleButtonGroup
                  color="primary"
                  value={language}
                  exclusive
                  onChange={handleChange}
                >
                  <ToggleButton sx={{ fontSize: '0.65rem', padding: '5px 7px' }} value="en">en</ToggleButton>
                  <ToggleButton sx={{ fontSize: '0.65rem', padding: '5px 7px' }} value="jp">jp</ToggleButton>
                </ToggleButtonGroup>
              </ListItem>
              <ListItem sx={{ pl: 4, color: '#000 !important' }} button key="Logout" onClick={logoutUser}>
                <ListItemIcon sx={{ minWidth: 36 }}>
                  <LogoutIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={<Typography variant="caption">{text.sideNav.settings.secondary.logout}</Typography>} />
              </ListItem>
            </List>
          </Collapse>
        </List>
      </Drawer>
    </>
  );
};
