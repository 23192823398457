import { createContext, useContext, useReducer, useMemo } from 'react';
import reducer, { INITIAL_STATE } from './reducers';

// createContext requires a `defaultValue` and in our case,
// it is whatever the useReducer() hook returns;
const StateContext = createContext<any>([]);

// App/Root needs a reference to the StateProvider
export default ({ children }: any) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);
  return <StateContext.Provider value={contextValue}>{children}</StateContext.Provider>;
};

// Consumer needs a reference to the Global Context
export const useStateValue = () => useContext(StateContext);
