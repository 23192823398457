/* eslint-disable no-unused-vars */
/* eslint-disable react/require-default-props */
import { forwardRef, Ref, ReactElement, ReactEventHandler } from 'react';
import { Typography, Dialog, Slide, AppBar, Toolbar, IconButton } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { Close as CloseIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { DeleteButton, DefaultButton } from '.';

const useStyles = makeStyles({
  dialog: { background: '#fafafa' },
  appBar: { position: 'fixed', background: 'white', color: 'black' },
  title: { marginLeft: 2, flex: 1 },
});

interface IProp {
  children: any
  open: boolean
  showDelete: boolean
  submitText: string
  deleteText: string
  appBarTitle: string
  studentEmail?: string
  onClose: ReactEventHandler
  onSubmit: ReactEventHandler
  onConfirmDelete?: ReactEventHandler
}

const Transition = forwardRef((
  props: TransitionProps & { children?: ReactElement; },
  ref: Ref<unknown>,
) => <Slide direction="up" ref={ref} {...props} />);

export default ({
  children,
  open,
  showDelete,
  submitText,
  deleteText,
  appBarTitle,
  studentEmail = '',
  onClose,
  onSubmit,
  onConfirmDelete = () => { },
}: IProp) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      TransitionComponent={Transition}
      classes={{ paper: classes.dialog }}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="body1" className={classes.title} component="div">
            {appBarTitle}
          </Typography>

          { studentEmail !== ''
          && (
          <Typography variant="subtitle1" component="div" marginRight={2}>
            {studentEmail}
          </Typography>
          )}

          {showDelete && <DeleteButton text={deleteText} onClick={onConfirmDelete} />}
          <DefaultButton text={submitText} onClick={onSubmit} />
        </Toolbar>
      </AppBar>
      {children}
    </Dialog>
  );
};
