/* eslint-disable semi */
/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { Box, Container } from '@material-ui/core';

import {
  SectionTitle,
  FullpageDialog,
  FullpageLoader,
  ConfirmationDialog,
} from '../../../../../@common/components/forms';

import CheckList from '../components/CheckList'
import useStudentForm from '../../../store/hooks/useStudentForm';

import useStudentModal from '../../../store/hooks/useStudentModal';

import useForm from '../../../store/hooks/useForm';

import useLocal from '../../../../../../store/local/hooks/useLocal';

import usePageData from '../../../../../../store/pageData/hooks/usePageData';
import useStudentSelect from '../../../store/hooks/useStudentSelect';

export default () => {
  const { modal, closeStudentModal } = useStudentModal();
  const { student } = useStudentSelect();
  const { text } = useLocal();
  const { roleTaxonomies } = usePageData();

  const { submitting, submitForm, deleteStudent } = useStudentForm();

  // DELETE dialog (todo: move this out into its own custom hook)
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const handleConfirmationOpen = () => setOpenConfirmation(true);
  const handleConfirmationClose = () => setOpenConfirmation(false);
  const handleDeleteConfirmation = () => {
    setOpenConfirmation(false);
    deleteStudent();
  };

  const getFormFields = (edit: boolean) => ({
    userRoles: { value: edit ? student.userRoles : '7', required: true, hasError: false }, // default to general
    id: { value: edit ? student.id : 0, required: false, hasError: false },
    email: { value: edit ? student.email : '', required: false, hasError: false },
    validAt: { value: edit ? student.validAt : '', required: false, hasError: false },
    createdAt: { value: edit ? student.createdAt : '', required: false, hasError: false },
    confirmedAt: { value: edit ? student.confirmedAt : '', required: false, hasError: false },
    deleted: { value: edit ? student.deleted : 0, required: false, hasError: false },
  });

  const {
    formFields,
    setValue,
    setFormFields,
    submit,
  } = useForm({
    initialValue: getFormFields(modal.edit),
    onSubmit: (fields: any) => submitForm({ fields, roleTaxonomies }),
  });

  useEffect(() => { setFormFields(getFormFields(modal.edit)) }, [modal.open]);

  return (
    <>
      <FullpageDialog
        studentEmail={modal.edit ? student.email : ''}
        open={modal.open}
        showDelete={modal.showDelete}
        submitText={text.appBarButtons[modal.action]}
        deleteText={text.appBarButtons.delete}
        appBarTitle={text.student.appBar.student[modal.action]}
        onClose={closeStudentModal}
        onSubmit={submit}
        onConfirmDelete={handleConfirmationOpen}
      >
        <Container maxWidth="sm">
          <Box my={16} />
          <SectionTitle text={text.student.form.studentFormTitle} />
          <CheckList
            title={text.student.form.roleTypeLabel}
            defaultValue={formFields.userRoles.value}
            items={roleTaxonomies}
            changeHandler={(selected: any) => setValue('userRoles', selected)}
            hasError={formFields.userRoles.hasError}
            errorMessage={text.student.form.roleErrorMessage}
          />

          <Box my={16} />
        </Container>

        <FullpageLoader open={submitting} />
      </FullpageDialog>

      <ConfirmationDialog
        open={openConfirmation}
        onClose={handleConfirmationClose}
        onConfirm={handleDeleteConfirmation}
        title={text.student.deleteModal.title}
        content={text.student.deleteModal.body}
        no={text.student.deleteModal.cancel}
        ok={text.student.deleteModal.confirm}
      />
    </>
  );
};
