/* eslint-disable import/prefer-default-export */
import { Page, Category, Subcategory } from './types';

export const mapCategoriesToCategoryDropdownItems = (
  pages: Page[],
  categories: Category[],
  subcategories: Subcategory[],
) => {
  const items = pages.map((p: Page) => {
    const csup = categories.filter((c: Category) => c.pageId === p.id);

    const ssuc = csup.map((c: Category) => {
      const subItems = subcategories.filter((s: Subcategory) => c.id === s.categoryId);
      return { ...c, subItems };
    });

    return { name: p.name, items: ssuc };
  });
  return items;
};
