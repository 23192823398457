import StateProvider from './store';
import ProductList from './sections/list';
import ProductDetails from './sections/details';
import ProductForms from './sections/forms';

import './style.css';

export default () => (
  <StateProvider>
    <ProductList />
    <ProductDetails />
    <ProductForms />
  </StateProvider>
);
