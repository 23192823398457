/* eslint-disable max-len */
import { useStateValue } from '../index';
import { newAssetStarted, addAssetStarted, assetModalClose } from '../actions';
import { FormWriteStatus } from '../constants';

const useAssetModal = () => {
  const { state, dispatch } = useStateValue();

  const fromProduct = (id: number) => {
    const { product } = state;
    const materials = product.materials.map((m: any) => (m.id === id
      ? { ...m, selected: true }
      : { ...m, selected: false }));
    return { ...product, materials };
  };

  const openNewAssetModal = (id: number) => dispatch(newAssetStarted(fromProduct(id)));
  const openDraftAssetModal = (id: number) => dispatch(addAssetStarted(fromProduct(id)));
  const closeAssetModal = () => dispatch(assetModalClose());

  return {
    modal: {
      open: state.assetModalOpen,
      showDelete: state.assetFormState === FormWriteStatus.EDIT_PUBLISHED,
      action: state.assetFormState.includes('create') ? 'create' : 'edit',
      create: state.assetFormState === FormWriteStatus.CREATE_DRAFT,
      edit: state.assetFormState === FormWriteStatus.EDIT_DRAFT || state.assetFormState === FormWriteStatus.EDIT_PUBLISHED,
    },
    openNewAssetModal,
    openDraftAssetModal,
    closeAssetModal,
  };
};

export default useAssetModal;
