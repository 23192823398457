import { useEffect } from 'react';
import { Stack, Typography } from '@material-ui/core';
import { makeStyles, styled } from '@material-ui/core/styles';
import LoadingButton from '@material-ui/lab/LoadingButton';
import ImagePlaceholder from '../../@common/components/forms/ImagePlaceholder';
import ErrorText from '../../@common/components/forms/ErrorText';

import useImageUpload from '../../@common/hooks/useImageUpload';

const useStyles = makeStyles((theme) => ({
  actionButton: {
    maxHeight: '30px',
    textTransform: 'none',
    color: theme.palette.text.primary,
    background: theme.palette.background.default,
    outline: 'none',
    '&:hover': { background: theme.palette.background.paper },
  },
  img: { border: '1px dashed #aaa' },
}));

const HiddenInput = styled('input')({ display: 'none' });

interface IProp {
  'inputId': string
  'defaultValue': string
  'placeholder': string
  'chooseImageText': string
  'onUpload': Function
  'hasError': boolean
  'errorMessage': string
}

export default ({
  inputId,
  defaultValue,
  placeholder,
  chooseImageText,
  onUpload,
  hasError,
  errorMessage,
}: IProp) => {
  const classes = useStyles();

  const { imageKey, uploading, upload } = useImageUpload();

  useEffect(() => { if (!uploading && imageKey) onUpload(imageKey); }, [uploading, imageKey]);

  return (
    <Stack direction="column" justifyContent="flex-end" spacing={1}>
      {!defaultValue && <ImagePlaceholder text={placeholder} />}
      {defaultValue && <img className={classes.img} src={`${process.env.REACT_APP_CDN_URL}/${defaultValue}`} alt={defaultValue} width="140" height="200" />}

      <HiddenInput
        accept="image/*"
        id={inputId}
        type="file"
        onChange={(e: any) => upload(e.target.files[0])}
      />
      <label htmlFor={inputId}>
        <LoadingButton fullWidth component="span" loading={uploading} variant="contained" className={classes.actionButton}>
          <Typography variant="caption">{chooseImageText}</Typography>
        </LoadingButton>
      </label>
      {hasError && <ErrorText text={errorMessage} />}
    </Stack>
  );
};
