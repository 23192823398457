/* eslint-disable no-unused-vars */
import { API } from 'aws-amplify';
import { mutate } from 'swr';
import { Admin } from '../types';
import { useStateValue } from '../index';
import {
  formSubmitted,
  formSubmittedWithSuccess,
  formSubmittedWithError,
  formReset,
  userDeleteConfirmed,
  userDeletedWithSuccess,
} from '../actions';
import { FormWriteStatus, FormStatus } from '../constants';
import { mapFormFieldsToObject } from '../mappers';
import useAuth from '../../../../../store/auth/hooks/useAuth';
import useAlert from '../../../../../store/alert/hooks/useAlert';

const useFormSubmit = () => {
  const { jwt } = useAuth();
  const { state, dispatch } = useStateValue();
  const { showSuccessAlert, showFailureAlert } = useAlert();

  const handle500 = ({ _ }: any) => {
    dispatch(formSubmittedWithError());
    showFailureAlert();
  };
  const refetch = () => mutate('users');

  const submitForm = ({ fields }: any) => {
    const user = mapFormFieldsToObject({ data: fields });

    if (state.userFormState === FormWriteStatus.EDIT_PUBLISHED) {
      const apiName = 'AdminQueries';
      const path = '/updateUserAttributes';

      const handle200 = (_: any) => {
        const users = state.users.map((p: any) => (p.username === user.username ? user : p));
        dispatch(formSubmittedWithSuccess({ users, user }));
        showSuccessAlert();
      };

      const body: any = {};
      body.username = user.username;
      body.email = user.email;
      body.firstName = user.givenName;
      body.lastName = user.familyName;

      const headers: any = {};
      headers['Content-Type'] = 'application/json';
      headers.Authorization = jwt;

      const payload: any = {};
      payload.headers = headers;
      payload.body = body;

      dispatch(formSubmitted());

      API
        .post(apiName, path, payload)
        .then((res: any) => handle200(res))
        .catch((e) => handle500(e));

      return;
    }

    if (state.userFormState === FormWriteStatus.CREATE_DRAFT) {
      const apiName = 'AdminQueries';
      const path = '/createUser';

      const handle200 = (_: any) => {
        const users = state.users.map((p: any) => (p.username === user.username ? user : p));
        dispatch(formSubmittedWithSuccess({ users, user }));
        refetch();
        showSuccessAlert();
      };

      const body: any = {};
      body.username = user.email;
      body.firstName = user.givenName;
      body.lastName = user.familyName;
      body.temporarypassword = user.password;

      const headers: any = {};
      headers['Content-Type'] = 'application/json';
      headers.Authorization = jwt;

      const payload: any = {};
      payload.headers = headers;
      payload.body = body;

      dispatch(formSubmitted());

      API
        .post(apiName, path, payload)
        .then((res: any) => handle200(res))
        .catch((e) => handle500(e));
    }
  };

  const deleteUser = () => {
    const { username } = state.users.find((u: any) => u.selected);
    const apiName = 'AdminQueries';
    const path = '/deleteUser';

    const headers: any = {};
    headers['Content-Type'] = 'application/json';
    headers.Authorization = jwt;

    const body: any = {};
    body.username = username;

    const payload: any = {};
    payload.headers = headers;
    payload.body = body;

    const handle200 = (_: any) => {
      dispatch(userDeletedWithSuccess());
      refetch();
      showSuccessAlert();
    };

    dispatch(userDeleteConfirmed());

    API
      .post(apiName, path, payload)
      .then((res: any) => handle200(res))
      .catch((e) => handle500(e));
  };

  const resetFormState = () => dispatch(formReset());

  return {
    user: state.user as Admin,
    submitting: state.formState === FormStatus.SUBMITTING,
    submittedWithSuccess: state.formState === FormStatus.SUBMITTED_SUCCESS,
    submittedWithError: state.formState === FormStatus.SUBMITTED_ERROR,
    submitForm,
    resetFormState,
    deleteUser,
  };
};

export default useFormSubmit;
