/* eslint-disable no-unused-vars */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { FullpageLoader } from '../../../../@common/components/forms';
import useLocal from '../../../../../store/local/hooks/useLocal';
import usePost from '../../../store/hooks/usePost';

interface IProp {
  open: boolean,
  handleClose: Function,
}

export default ({ open, handleClose }: IProp) => {
  const { submitting, publish } = usePost();
  const { text } = useLocal();

  return (
    <Dialog open={open} onClose={() => handleClose()} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{text.publish.publishModal.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {text.publish.publishModal.body}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ paddingLeft: 24, paddingRight: 24, paddingBottom: 16 }}>
        <Button onClick={() => handleClose()}>
          {text.publish.publishModal.cancel}
        </Button>
        <Button onClick={publish}>
          {text.publish.publishModal.confirm}
        </Button>
      </DialogActions>
      <FullpageLoader open={submitting} />
    </Dialog>
  );
};
