import { useStateValue } from '../index';
import {
  editStudentStarted,
  studentModalClose,
} from '../actions';
import { FormWriteStatus } from '../constants';

const useStudentModal = () => {
  const { state, dispatch } = useStateValue();

  const closeStudentModal = () => dispatch(studentModalClose());
  const openStudentModalForEdit = () => dispatch(editStudentStarted());

  return {
    modal: {
      open: state.studentModalOpen,
      showDelete: state.studentFormState === FormWriteStatus.EDIT,
      action: state.studentFormState.includes('create') ? 'create' : 'edit',
      edit: state.studentFormState === FormWriteStatus.EDIT,
    },
    closeStudentModal,
    openStudentModalForEdit,
  };
};

export default useStudentModal;
