/* eslint-disable react/no-array-index-key */
import {
  ListItem,
  ListItemAvatar,
  List,
  ListItemText,
  Skeleton,
} from '@material-ui/core/';

const styles = {
  width: '30vw',
  minWidth: '400px',
  height: 'calc(100vh - 100px)',
  bgcolor: 'background.paper',
  overflow: 'scroll',
  py: 0,
};

const loaders = new Array<number>(30).fill(0);

export default () => (
  <List sx={styles}>
    {loaders.map((i: any, x: any) => (
      <ListItem key={x} divider>
        <ListItemAvatar>
          <ListItemAvatar>
            <Skeleton animation="wave" variant="rectangular" width={40} height={56} />
          </ListItemAvatar>
        </ListItemAvatar>
        <ListItemText
          primary={<Skeleton />}
          secondary={(
            <>
              <Skeleton width="40%" />
              <Skeleton width="20%" />
            </>
          )}
        />
      </ListItem>
    ))}
  </List>
);
