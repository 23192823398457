/* eslint-disable no-unused-vars */
import { mutate } from 'swr';
import { Banner } from '../types';
import { useStateValue } from '../index';
import {
  formSubmitted,
  formSubmittedWithSuccess,
  formSubmittedWithError,
  formReset,
} from '../actions';
import { FormStatus, PAGE_BANNERS_API } from '../constants';
import { mapFormFieldsToObject } from '../mappers';
import httpClient from '../../../../utils/httpClient';
import useAuth from '../../../../store/auth/hooks/useAuth';
import useAlert from '../../../../store/alert/hooks/useAlert';

const useProductForm = () => {
  const { jwt } = useAuth();
  const { state, dispatch } = useStateValue();
  const { showSuccessAlert, showFailureAlert } = useAlert();

  const handle500 = ({ _ }: any) => {
    dispatch(formSubmittedWithError());
    showFailureAlert();
  };
  const refetch = () => mutate(`${PAGE_BANNERS_API}`);
  const refetchDetails = () => mutate(`${PAGE_BANNERS_API}/${state.banner.id}`);

  const submitForm = ({ fields }: any) => {
    const banner = mapFormFieldsToObject({ data: fields });

    const url = `${PAGE_BANNERS_API}/${banner.id}`;

    const handle200 = (response: any) => {
      if (response.data) {
        const updatedBanner = { ...banner, materials: state.banner.materials };
        const banners = state.banners.map((p: any) => (p.id === updatedBanner.id ? updatedBanner : p));
        dispatch(formSubmittedWithSuccess({ banners, banner: updatedBanner }));
        refetch();
        refetchDetails();
        showSuccessAlert();
      } else if (response.message) {
        dispatch(formSubmittedWithError());
        showFailureAlert();
      }
    };

    const body: any = {};
    body.id = banner.id;
    body.name = banner.name;
    body.path = banner.path;
    body.page_explanation = banner.html;
    body.banner = banner.bannerImageDesktop;
    body.mobile_banner = banner.bannerImageMobile;
    body.show_banner = banner.showBanner;
    body.need_auth = banner.needAuth;

    dispatch(formSubmitted());

    httpClient({ url, jwt, method: 'PUT', body })
      .then((res: any) => handle200(res))
      .catch((error) => handle500(error));
  };

  const resetFormState = () => dispatch(formReset());

  return {
    banner: state.banner as Banner,
    submitting: state.formState === FormStatus.SUBMITTING,
    submittedWithSuccess: state.formState === FormStatus.SUBMITTED_SUCCESS,
    submittedWithError: state.formState === FormStatus.SUBMITTED_ERROR,
    submitForm,
    resetFormState,
  };
};

export default useProductForm;
