import { useState, useEffect } from 'react';
import { Box, Stack, Container } from '@material-ui/core';
import {
  FullWidthTextField,
  SectionTitle,
  RadioList,
  TextEditor,
  FullpageDialog,
  FullpageLoader,
  ConfirmationDialog,
  NestedDropDown,
} from '../../../../@common/components/forms';
import useContentForm from '../../../store/hooks/useAssetForm';
import useAssetModal from '../../../store/hooks/useAssetModal';
import useAssetSelect from '../../../store/hooks/useAssetSelect';
import useForm from '../../../store/hooks/useForm';
import useLocal from '../../../../../store/local/hooks/useLocal';

export default () => {
  const { modal, closeAssetModal } = useAssetModal();
  const { asset } = useAssetSelect();
  const { text } = useLocal();

  const {
    submitting,
    submitForm,
    deleteAsset,
  } = useContentForm();

  const getFormFields = (edit: boolean) => ({
    id: { value: edit ? asset.id : Date.now(), required: false, hasError: false },
    materialId: { value: asset?.materialId, required: false, hasError: false },
    contentTitle: { value: edit ? asset.title : '', required: true, hasError: false },
    label: { value: edit ? asset.label : '', required: false, hasError: false },
    size: { value: edit ? asset.size : '', required: false, hasError: false },
    contentUrl: { value: edit ? asset.content : '', required: true, hasError: false },
    contentType: { value: edit ? asset.type : 'None', required: true, hasError: false },
    buttonType: { value: edit ? asset.buttonType : 'btn_dl', required: true, hasError: false },
    instruction: { value: edit ? asset.instruction : '', required: false, hasError: false },
  });

  const {
    formFields,
    submit,
    setValue,
    setFormFields,
  } = useForm({
    initialValue: getFormFields(modal.edit),
    onSubmit: submitForm,
  });

  // DELETE dialog (todo: move this out into its own custom hook)
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const handleConfirmationOpen = () => setOpenConfirmation(true);
  const handleConfirmationClose = () => setOpenConfirmation(false);
  const handleDeleteConfirmation = () => {
    setOpenConfirmation(false);
    deleteAsset();
  };

  const buttonTypes = [
    { name: 'btn_dl' },
    { name: 'btn_onlinetest' },
    { name: 'btn_play' },
    { name: 'btn_taiken' },
    { name: 'btn_test' },
  ];

  const contentTypes = [{
    title: 'Content Types',
    subItems: [
      { title: 'pdf' },
      { title: 'epub' },
      { title: 'zip' },
      { title: 'mp4' },
      { title: 'other' },
    ],
  }];

  const textfields = {
    fullwidth: [{
      value: formFields.contentTitle.value,
      title: text.package.form.contentTitleLabel,
      onSave: (value: any) => setValue('contentTitle', value),
      hasError: formFields.contentTitle.hasError,
      errorMessage: text.package.form.contentErrorMessage,
    }, {
      value: formFields.label.value,
      title: text.package.form.contentLabel,
      onSave: (value: any) => setValue('label', value),
      hasError: formFields.label.hasError,
      errorMessage: '',
    }, {
      value: formFields.contentUrl.value,
      title: text.package.form.contentUrlLabel,
      onSave: (value: any) => setValue('contentUrl', value),
      hasError: formFields.contentUrl.hasError,
      errorMessage: text.package.form.contentUrlErrorMessage,
    }, {
      value: formFields.size.value,
      title: text.package.form.contentSizeLabel,
      onSave: (value: any) => setValue('size', value),
      hasError: formFields.size.hasError,
      errorMessage: '',
    }],
  };

  useEffect(() => { setFormFields(getFormFields(modal.edit)); }, [modal.open]);

  return (
    <>
      <FullpageDialog
        open={modal.open}
        showDelete={modal.showDelete}
        submitText={text.appBarButtons[modal.action]}
        deleteText={text.appBarButtons.delete}
        appBarTitle={text.package.appBar.materialUrl[modal.action]}
        onClose={closeAssetModal}
        onSubmit={submit}
        onConfirmDelete={handleConfirmationOpen}
      >
        <Container maxWidth="sm">
          <Box my={16} />
          <SectionTitle text={text.package.form.materialUrlFormTitle} />

          <Stack direction="column" justifyContent="flex-start">
            {textfields.fullwidth.map((i: any) => (
              <FullWidthTextField
                key={i.title}
                value={i.value}
                title={i.title}
                onSave={i.onSave}
                hasError={i.hasError}
                errorMessage={i.errorMessage}
              />
            ))}
          </Stack>

          <Box>
            <NestedDropDown
              fieldTitle={text.package.form.contentTypeLabel}
              title={text.package.form.contentTypeButtonText}
              selected={formFields.contentType.value}
              items={contentTypes}
              listClickHandler={(selected: any) => setValue('contentType', selected)}
              hasError={formFields.contentType.hasError}
              errorMessage={text.package.form.contentTypeErrorMessage}
            />
          </Box>

          <Box mt={2} />

          <RadioList
            title={text.package.form.buttonTypeLabel}
            defaultValue={formFields.buttonType.value}
            items={buttonTypes}
            changeHandler={(selected: any) => setValue('buttonType', selected)}
            image
          />

          <Box mt={2} />

          <Stack direction="column" justifyContent="flex-start">
            <TextEditor
              value={formFields.instruction.value}
              title={text.package.form.instructionLabel}
              onSave={(value: any) => setValue('instruction', value)}
              hasError={formFields.instruction.hasError}
              errorMessage=""
            />
          </Stack>
          <Box my={16} />
        </Container>

        <FullpageLoader open={submitting} />
      </FullpageDialog>

      <ConfirmationDialog
        open={openConfirmation}
        onClose={handleConfirmationClose}
        onConfirm={handleDeleteConfirmation}
        title={text.package.deleteModal.title}
        content={text.package.deleteModal.body}
        no={text.package.deleteModal.cancel}
        ok={text.package.deleteModal.confirm}
      />
    </>
  );
};
