/* eslint-disable react/no-danger */
import { Grid, Box, Typography } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import { Product } from '../../../store/types';
import useLocal from '../../../../../store/local/hooks/useLocal';

const useStyles = makeStyles({
  label: {
    lineHeight: 0,
    fontSize: '.65rem',
    fontWeight: 'bold',
  },
  value: {
    fontSize: '.65rem',
    overflowWrap: 'break-word',
  },
  htmlContent: {
    fontSize: '.65rem',
    background: '#fafafa',
    border: '1px solid #ccc',
    padding: '5px 10px',
    minHeight: '1.7rem',
  },
  description: {
    height: 90,
    overflowY: 'scroll',
  },
});

export default ({ product, loading }: {
  product: Product
  loading: boolean
}) => {
  const classes = useStyles();
  const { text } = useLocal();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={4}>
          <Typography variant="overline" className={classes.label}>{text.package.details.pageLabel}</Typography>
          <Typography variant="body2" className={classes.htmlContent}>{loading ? '' : product.pageName}</Typography>
        </Grid>
        <Grid item sm={4}>
          <Typography variant="overline" className={classes.label}>{text.package.details.categoryLabel}</Typography>
          <Typography variant="body2" className={classes.htmlContent}>{loading ? '' : product.categoryTitle}</Typography>
        </Grid>
        <Grid item sm={4}>
          <Typography variant="overline" className={classes.label}>{text.package.details.subcategoryLabel}</Typography>
          <Typography variant="body2" className={classes.htmlContent}>{loading ? '' : product.subcategoryTitle}</Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={0}>
        <Grid item sm={4}>
          <Typography variant="overline" className={classes.label}>{text.package.details.productIdLabel}</Typography>
          <Typography variant="body2" className={classes.htmlContent}>{loading ? '' : product.productId}</Typography>
        </Grid>
        <Grid item sm={4}>
          <Typography variant="overline" className={classes.label}>{text.package.details.passwordLabel}</Typography>
          <Typography variant="body2" className={classes.htmlContent}>{loading ? '' : product.password}</Typography>
        </Grid>
      </Grid>

      <Box mt={2}>
        <Typography variant="overline" className={classes.label}>{text.package.details.metadataLabel}</Typography>
        <p
          className={classes.htmlContent}
          dangerouslySetInnerHTML={{ __html: loading ? '' : product.metadata }}
        />
      </Box>

      <Box mt={2}>
        <Typography variant="overline" className={classes.label}>{text.package.details.notesLabel}</Typography>
        <p
          className={classes.htmlContent}
          dangerouslySetInnerHTML={{ __html: loading ? '' : product.notes ?? '' }}
        />
      </Box>

      <Box mt={2}>
        <Typography variant="overline" className={classes.label}>{text.package.details.descriptionLabel}</Typography>
        <p
          className={`${classes.htmlContent} ${classes.description}`}
          dangerouslySetInnerHTML={{ __html: loading ? '' : product.description }}
        />
      </Box>
    </>
  );
};
