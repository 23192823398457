import { useState } from 'react';
import {
  Typography,
  Button,
  Popover,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Divider,
  Box,
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ErrorText from '../../../../../@common/components/forms/ErrorText';

const ActionButton = styled(Button)(({ theme }) => ({
  maxHeight: '30px',
  textTransform: 'none',
  color: theme.palette.text.primary,
  background: theme.palette.background.default,
  outline: 'none',
  '&:hover': { background: theme.palette.background.paper },
  marginTop: theme.spacing(1),
}));

interface ISubItem {
  'title': string
}

interface IListItem {
  'title': string
  'subItems': ISubItem[]
}

interface Items {
  'items': IListItem[]
  'name': string
}

interface IProp {
  'fieldTitle': string
  'title': string
  'selected': string
  'listClickHandler': Function
  'items': Items
  'hasError': boolean
  'errorMessage': string
}

const Section = ({
  header,
  items,
  onClick,
}: {
  'header': string
  'items': ISubItem[]
  'onClick': Function
}) => {
  const [open, setOpen] = useState(true);
  const handleClick = () => setOpen(!open);

  return (
    <>
      <ListItem
        button
        sx={{ pt: 0, pb: 0 }}
        onClick={handleClick}
        divider
      >
        <ListItemText primary={<Typography variant="caption">{header}</Typography>} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List sx={{ paddingTop: 0, paddingBottom: 0 }} component="div" disablePadding>
          {items.map((i) => (
            <Box key={i.title}>
              <ListItem
                disableRipple
                button
                sx={{ pl: 4, pt: 0, pb: 0 }}
                onClick={() => onClick({ category: header, subcategory: i.title })}
              >
                <ListItemText primary={<Typography variant="caption">{i.title}</Typography>} />
              </ListItem>
              <Divider light />
            </Box>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default ({
  fieldTitle,
  title,
  selected,
  items,
  listClickHandler,
  hasError,
  errorMessage,
}: IProp) => {
  const [el, setEl] = useState<HTMLButtonElement | null>(null);
  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => setEl(event.currentTarget);
  const onClose = () => setEl(null);
  const actionOpen = Boolean(el);
  const actionId = actionOpen ? 'action-popover' : undefined;
  const onSelect = (selectedItem: any) => {
    setEl(null);
    listClickHandler(selectedItem);
  };

  return (
    <>
      <Typography variant="caption" component="p">{fieldTitle}</Typography>
      <Box mb={2}>
        <ActionButton
          variant="contained"
          size="medium"
          endIcon={<ArrowDropDownIcon />}
          disableRipple
          onClick={onClick}
        >
          <Typography variant="caption">
            {title}
            &nbsp;
          </Typography>
          <Typography variant="caption"><b>{selected}</b></Typography>
        </ActionButton>
        {hasError && <ErrorText text={errorMessage} />}
        <Popover
          id={actionId}
          open={actionOpen}
          anchorEl={el}
          onClose={onClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <List sx={{ pt: 0, pb: 0, minWidth: '300px' }} component="nav" aria-label="page">
            {items.items.map((i) => (
              <Section
                key={i.title}
                header={i.title}
                items={i.subItems}
                onClick={onSelect}
              />
            ))}
          </List>
        </Popover>
      </Box>
    </>
  );
};
