import Header from './components/Header';
import NewButton from './components/NewButton';
import Page from './components/Page';
import Pagination from './components/Pagination';
import useProducts from '../../store/hooks/useProducts';
import useLocal from '../../../../store/local/hooks/useLocal';

export default () => {
  const { text } = useLocal();
  const {
    products,
    pagination,
    loading,
    hasQuery,
    paginate,
    search,
  } = useProducts();

  return (
    <div>
      <Header
        placeholder={text.package.list.placeholderText}
        newButton={<NewButton text={text.buttons.create} />}
        withSearchResult={hasQuery}
        setQuery={search}
      />
      <Page loading={loading} products={products} />
      <Pagination
        currentPage={pagination?.currentPage}
        perPage={pagination?.perPage}
        totalCount={pagination?.totalCount}
        lastPage={pagination?.lastPage}
        paginate={(index: number) => paginate(index)}
      />
    </div>
  );
};
