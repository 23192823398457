import { Box, Stack } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

const Placeholder = styled(Box)({
  width: '100%',
  border: '2px dashed #888',
  borderRadius: '4px',
  display: 'flex',
  flexWrap: 'wrap',
  '& > :not(style)': {
    mb: 1,
    height: 200,
  },
});

export default ({ children }: any) => (
  <Placeholder>
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={1}
      sx={{ height: '200px', width: '100%' }}
    >
      {children}
    </Stack>
  </Placeholder>
);
