import { Material, Asset, Product } from './types';
import { PublishStatus } from './constants';
import { Page, Category, Subcategory } from '../../../store/pageData/types';
import dateToJST from '../../../utils/date';

interface IMapperProp {
  pages: Page[]
  categories: Category[]
  subcategories: Subcategory[]
  data: any
}

interface IListMapperProp extends IMapperProp {
  product: Product
}

export const mapProductListToProduct = ({
  pages,
  categories,
  subcategories,
  product,
  data,
}: IListMapperProp) => {
  const products: Product[] = [];

  data.data.forEach((i: any) => {
    const pageEntity = pages.find((p) => p.name === i.pageName)!;
    const categoryEntity = categories.find((c) => c.title === i.categoryTitle)!;
    const subcategoryEntity = subcategories.find((s) => s.title === i.subcategoryTitle)!;

    products.push({
      id: i.id,
      title: i.title,
      image: i.image,
      imageUrl: `${process.env.REACT_APP_CDN_URL}/${i.image}`,
      productId: i.productId,
      pageId: pageEntity.id,
      pageName: pageEntity.name,
      categoryId: categoryEntity.id,
      categoryTitle: categoryEntity.title,
      subcategoryId: subcategoryEntity.id,
      subcategoryTitle: subcategoryEntity.title,
      description: i.description,
      metadata: i.metadata,
      createdAt: dateToJST(i.createdAt),
      materials: [],
      status: i.id === null ? PublishStatus.DRAFT : PublishStatus.PUBLISHED,
      selected: i.id === product?.id,
      isPublished: i.isPublished,
    });
  });

  return { ...data, data: products };
};

export const mapProductDetailsToProduct = ({
  pages,
  categories,
  subcategories,
  data,
}: IMapperProp) => {
  const product = {} as Product;
  const materials: Material[] = [];

  const p = data.data;
  const m = data.data.materials;

  const pageEntity = pages.find((i) => i.id === p.pageId)!;
  const categoryEntity = categories.find((i) => i.id === p.categoryId)!;
  const subcategoryEntity = subcategories.find((i) => i.id === p.subcategoryId)!;

  m.forEach((i: any) => {
    const assets: Asset[] = [];

    i.materialUrls.forEach((x: any) => {
      assets.push({
        id: x.id,
        packageId: p.id,
        materialId: i.id,
        title: x.title.trim().replace('<br/>', '').replace('<strong>', '').replace('</strong>', ''),
        instruction: x.instruction,
        label: x.label,
        content: x.content,
        buttonTypeUrl: `${process.env.REACT_APP_CDN_URL}/img/${x.buttonType}.jpg`,
        buttonType: x.buttonType,
        size: x.size,
        type: x.type,
      });
    });

    materials.push({
      id: i.id,
      packageId: p.id,
      productId: i.productId,
      title: i.title,
      header: i.header,
      html: i.htmlData,
      image: i.image,
      imageUrl: `${process.env.REACT_APP_CDN_URL}/${i.image}`,
      assets,
      formType: i.htmlData?.length > 0 ? 'wysiwyg' : 'form',
    });
  });

  product.id = p.id;
  product.productId = p.productId;
  product.title = p.title;
  product.description = p.description || '';
  product.pageId = pageEntity.id;
  product.pageName = pageEntity.name;
  product.categoryId = categoryEntity.id;
  product.categoryTitle = categoryEntity.title;
  product.subcategoryId = subcategoryEntity.id;
  product.subcategoryTitle = subcategoryEntity.title;
  product.notes = p.notes || '';
  product.metadata = p.searchMetadata || '';
  product.image = p.image;
  product.imageUrl = `${process.env.REACT_APP_CDN_URL}/${p.image}`;
  product.materials = materials;
  product.password = p.password || '';
  product.needAuth = p.needAuth;
  product.isPublished = p.isPublished;
  product.status = PublishStatus.PUBLISHED;

  return product;
};

export const mapProductFormFieldsToProduct = ({
  pages,
  categories,
  subcategories,
  data,
}: IMapperProp) => {
  const pageEntity = pages.find((p) => p.name === data.page.value)!;
  const categoryEntity = categories.find((c) => c.title === data.category.value)!;
  const subcategoryEntity = subcategories.find((s) => s.title === data.subcategory.value)!;

  return {
    id: data.id.value,
    title: data.bookTitle.value,
    image: data.bookImageUrl.value,
    imageUrl: `${process.env.REACT_APP_CDN_URL}/${data.bookImageUrl.value}`,
    productId: data.productId.value,
    pageId: pageEntity.id,
    pageName: pageEntity.name,
    categoryId: categoryEntity.id,
    categoryTitle: categoryEntity.title,
    subcategoryId: subcategoryEntity.id,
    subcategoryTitle: subcategoryEntity.title,
    description: data.description.value || '',
    notes: data.note.value || '',
    metadata: data.metadata.value || '',
    createdAt: dateToJST(Date()),
    materials: [],
    password: data.password.value || '',
    needAuth: data.password.value?.length > 1,
    status: data.id.value === null ? PublishStatus.DRAFT : PublishStatus.PUBLISHED,
    isPublished: data.isPublished.value,
    selected: true,
  };
};

export const mapMaterialFormFieldsToMaterial = ({ data }: any) => ({
  id: data.id.value,
  packageId: Date.now(),
  productId: data.productId.value,
  title: data.bookTitle.value,
  header: data.header.value,
  html: data.content.value === '' || data.content.value === '<p><br></p>' ? null : data.content.value,
  image: data.bookImageUrl.value,
  imageUrl: `${process.env.REACT_APP_CDN_URL}/${data.bookImageUrl.value}`,
  assets: [],
  formType: data.content?.length > 0 ? 'wysiwyg' : 'form',
});

export const mapAssetFormFieldsToAsset = ({ data }: any) => ({
  id: data.id.value,
  materialId: data.materialId.value,
  title: data.contentTitle.value,
  instruction: data.instruction.value,
  label: data.label.value,
  content: data.contentUrl.value,
  buttonTypeUrl: `${process.env.REACT_APP_CDN_URL}/img/${data.buttonType.value}.jpg`,
  buttonType: data.buttonType.value,
  size: data.size.value,
  type: data.contentType.value,
});
