import { useStateValue } from '../index';
import { Banner } from '../types';
import { bannerSelected } from '../actions';
import { DetailsStatus } from '../constants';

const useSelect = () => {
  const { state, dispatch } = useStateValue();

  const select = (banner: Banner) => {
    // NOTE: avoid unnecessary re-renders if clicking the same banner twice;
    if (banner.id === state.banner?.id) return;

    const banners = state.banners.map((p: any) => ({ ...p, selected: p.id === banner.id }));
    dispatch(bannerSelected({ banners, banner }));
  };

  return {
    banner: state.banner,
    renderDefault: state.bannerDetailsState === DetailsStatus.EMPTY,
    select,
  };
};

export default useSelect;
