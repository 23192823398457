import { useStateValue } from '../index';
import { newMaterialStarted, addMaterialStarted, materialModalClose } from '../actions';
import { FormWriteStatus } from '../constants';

const useMaterialModal = () => {
  const { state, dispatch } = useStateValue();

  const openNewMaterialModal = () => dispatch(newMaterialStarted());
  const openDraftMaterialModal = () => dispatch(addMaterialStarted());
  const closeMaterialModal = () => dispatch(materialModalClose());

  return {
    modal: {
      open: state.materialModalOpen,
      showDelete: state.materialFormState === FormWriteStatus.EDIT_PUBLISHED,
      action: state.materialFormState.includes('create') ? 'create' : 'edit',
      create: state.materialFormState === FormWriteStatus.CREATE_DRAFT,
      edit: state.materialFormState === FormWriteStatus.EDIT_DRAFT
        || state.materialFormState === FormWriteStatus.EDIT_PUBLISHED,
    },
    openDraftMaterialModal,
    openNewMaterialModal,
    closeMaterialModal,
  };
};

export default useMaterialModal;
