import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
} from '@material-ui/core/';

import PersonIcon from '@material-ui/icons/Person';
import { Student } from '../../../store/types';

interface IProp {
  item: Student
  select: Function
}

export default ({ item, select }: IProp) => (
  <ListItem
    key={`${item.email}-${item.id}`}
    button
    divider
    selected={item.selected}
    onClick={() => select(item)}
    disableRipple
  >
    <ListItemAvatar>
      <ListItemAvatar>
        <Avatar>
          <PersonIcon />
        </Avatar>
      </ListItemAvatar>
    </ListItemAvatar>
    <ListItemText
      primary={<Typography variant="caption" component="span">{item.email}</Typography>}
      secondary={(
        <Typography variant="caption" component="span" sx={{ display: 'block', fontSize: '0.55rem' }}>
          {item.validAt}
        </Typography>
      )}
    />
  </ListItem>
);
