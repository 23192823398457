/* eslint-disable no-return-await */
import { useState } from 'react';
import { Box, Paper } from '@material-ui/core/';
import LoadingButton from '@material-ui/lab/LoadingButton';
import useLocal from '../../store/local/hooks/useLocal';
import { DefaultButton, FullWidthTextField } from '../@common/components/forms';
import useAuth from '../../store/auth/hooks/useAuth';

export default () => {
  const { isLoading, setNewPassword } = useAuth();
  const { text } = useLocal();

  const [password, setPassword] = useState('');

  const handleSubmit = () => {
    setNewPassword({ password });
  };

  return (
    <Paper elevation={3} sx={{ p: 4 }}>
      <form onSubmit={handleSubmit}>
        <FullWidthTextField
          value={password}
          title={text.newpassword.passwordLabel}
          type="password"
          onSave={(value: any) => setPassword(value)}
          hasError={false}
          errorMessage=""
          autoComplete="new-password"
        />

        <Box height={10} />

        {isLoading && (
          <LoadingButton fullWidth loading variant="outlined">
            Submit
          </LoadingButton>
        )}

        {!isLoading && (
          <DefaultButton
            text={text.newpassword.updateButtonText}
            type="submit"
            fullWidth
            disabled={password.length === 0}
            onClick={handleSubmit}
          />
        )}
      </form>
    </Paper>
  );
};
