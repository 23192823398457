import { Typography, TextField } from '@material-ui/core';
import { makeStyles, styled } from '@material-ui/core/styles';

const useStyles = makeStyles({
  helperText: {
    marginTop: 0,
    marginLeft: 0,
    marginRight: 0,
    paddingTop: '4px',
    background: '#fafafa',
  },
});

const Title = styled(Typography)(({ theme }) => ({ color: theme.palette.text.primary }));

const TextInput = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(2),
  background: '#fff',
}));

interface IProp {
  autoFocus?: boolean
  value: any
  title: string
  type?: string
  onSave: Function
  hasError: boolean
  errorMessage: string
  autoComplete?: string
  disabled?: boolean
}

export default ({
  autoFocus = false,
  value,
  title,
  type = 'text',
  onSave,
  hasError,
  errorMessage,
  autoComplete = 'off',
  disabled = false,
}: IProp) => {
  const classes = useStyles();

  return (
    <>
      <Title variant="caption">{title}</Title>
      <TextInput
        autoFocus={autoFocus}
        value={value ?? ''}
        size="small"
        fullWidth
        type={type}
        onChange={(e) => onSave(e.target.value)}
        error={hasError}
        helperText={hasError ? errorMessage : ''}
        FormHelperTextProps={{ className: classes.helperText }}
        autoComplete={autoComplete}
        disabled={disabled}
      />
    </>
  );
};
