import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { SWRConfig } from 'swr';

import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';

import { StateProvider } from './store';
import { INITIAL_STATE as AUTH_INITIAL_STATE } from './store/auth/reducers';
import { INITIAL_STATE as LIST_INITIAL_STATE } from './store/list/reducers';
import { INITIAL_STATE as LOCAL_INITIAL_STATE } from './store/local/reducers';
import { INITIAL_STATE as PAGE_DATA_INITIAL_STATE } from './store/pageData/reducers';
import { INITIAL_STATE as ALERT_INITIAL_STATE } from './store/alert/reducers';

import reducers from './store/reducers';
import theme from './pages/@common/styles';
import routes from './routes';
import fetcher from './utils/httpClient';

Amplify.configure(awsconfig);

const Routes = () => useRoutes(routes);

const initialState = {
  auth: AUTH_INITIAL_STATE,
  menuList: LIST_INITIAL_STATE,
  local: LOCAL_INITIAL_STATE,
  pageData: PAGE_DATA_INITIAL_STATE,
  alert: ALERT_INITIAL_STATE,
};

export default () => (
  <ThemeProvider theme={theme}>
    <StateProvider initialState={initialState} reducer={reducers}>
      <SWRConfig value={{ fetcher, revalidateOnFocus: false, dedupingInterval: 3600000 }}>
        <Router>
          <Routes />
        </Router>
      </SWRConfig>
    </StateProvider>
  </ThemeProvider>
);
