/* eslint-disable max-len */
import { useStateValue } from '../index';
import { editMaterialStarted, draftMaterialStarted } from '../actions';
import { Material } from '../types';

const useMaterialSelect = () => {
  const { state, dispatch } = useStateValue();

  const fromProduct = (id: number) => {
    const { product } = state;
    const materials = product.materials.map((m: any) => (m.id === id ? { ...m, selected: true } : { ...m, selected: false }));
    return { ...product, materials };
  };

  const selectMaterialForEdit = (material: Material) => dispatch(editMaterialStarted({ product: fromProduct(material.id), material }));
  const selectDraftMaterialForEdit = (material: Material) => dispatch(draftMaterialStarted({ product: fromProduct(material.id), material }));

  return {
    material: state.material as Material,
    selectMaterialForEdit,
    selectDraftMaterialForEdit,
  };
};

export default useMaterialSelect;
