import {
  Box,
  Paper,
  Stack,
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

const Content = styled(Paper)(({ theme }) => ({ background: theme.palette.background.paper }));

const Placeholder = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  '& > :not(style)': {
    mb: 1,
    width: 140,
    height: 200,
  },
});

export default ({ text }: { text: string }) => (
  <Placeholder>
    <Content>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        sx={{ height: '200px' }}
      >
        <Box>{text}</Box>
      </Stack>
    </Content>
  </Placeholder>
);
