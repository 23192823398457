/* eslint-disable max-len */
export const DETAILS_SELECTED = 'student/DETAILS_SELECTED';
export const DETAILS_LOADING = 'student/DETAILS_LOADING';
export const DETAILS_LOADED_SUCCESS = 'student/DETAILS_LOADED_SUCCESS';
export const DETAILS_LOADED_ERROR = 'student/DETAILS_LOADED_ERROR';
export const studentSelected = (payload: any) => ({ payload, type: DETAILS_SELECTED });
export const studentDetailsWillLoad = () => ({ type: DETAILS_LOADING });
export const studentDetailsLoadedWithSuccess = (payload: any) => ({ payload, type: DETAILS_LOADED_SUCCESS });

export const LIST_LOADING = 'student/LIST_LOADING';
export const LIST_LOADED_SUCCESS = 'student/LIST_LOADED_SUCCESS';
export const LIST_LOADED_ERROR = 'student/LIST_LOADED_ERROR';
export const pageDataLoadingStarted = () => ({ type: LIST_LOADING });
export const pageDataLoadedWithSuccess = (payload: any) => ({ payload, type: LIST_LOADED_SUCCESS });

export const MODAL_STUDENT_EDIT_OPEN = 'student/MODAL_STUDENT_EDIT_OPEN';
export const editStudentStarted = () => ({ type: MODAL_STUDENT_EDIT_OPEN });

export const MODAL_STUDENT_CLOSE = 'student/MODAL_STUDENT_CLOSE';
export const studentModalClose = () => ({ type: MODAL_STUDENT_CLOSE });

export const STUDENT_DELETING = 'student/STUDENT_DELETING';
export const STUDENT_DELETED_SUCCESS = 'student/STUDENT_DELETED_SUCCESS';
export const studentDeleteConfirmed = () => ({ type: STUDENT_DELETING });
export const studentDeletedWithSuccess = (payload: any) => ({ payload, type: STUDENT_DELETED_SUCCESS });

export const FORM_INACTIVE = 'student/FORM_INACTIVE';
export const FORM_SUBMITTING = 'student/FORM_SUBMITTING';
export const FORM_SUBMITTED_SUCCESS = 'student/FORM_SUBMITTED_SUCCESS';
export const FORM_SUBMITTED_ERROR = 'student/FORM_SUBMITTED_ERROR';
export const formStateReset = () => ({ type: FORM_INACTIVE });
export const formSubmitted = () => ({ type: FORM_SUBMITTING });
export const formSubmittedWithSuccess = (payload: any) => ({ payload, type: FORM_SUBMITTED_SUCCESS });
export const formSubmittedWithError = () => ({ type: FORM_SUBMITTED_ERROR });
