import { Box, Stack, Typography } from '@material-ui/core/';

const TextPlaceholder = ({ text }: any) => (
  <Box>
    <Box sx={{
      width: 'calc(55vw)',
      height: 'calc(100vh)',
      bgcolor: '#eaeaea',
      overflowY: 'scroll',
      px: 4,
    }}
    >
      <Stack
        sx={{ height: '100%' }}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="overline">{text}</Typography>
      </Stack>
    </Box>
  </Box>
);

interface IProp {
  placeholder?: string
}

export default ({ placeholder = '' }: IProp) => <TextPlaceholder text={placeholder} />;
