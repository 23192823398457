import { createMuiTheme } from '@material-ui/core';
import teal from '@material-ui/core/colors/teal';

const theme = createMuiTheme({
  palette: {
    mode: 'light',
    primary: {
      light: teal[900],
      main: '#000',
      dark: teal[500],
      contrastText: '#fff',
    },
    secondary: {
      light: teal[200],
      main: teal[900],
      dark: teal[900],
      contrastText: '#000',
    },
    text: {
      primary: '#000',
      secondary: '#333',
    },
    background: { default: '#fff' },
    // action: {
    //   hover: teal[200],
    //   hoverOpacity: 0.04,
    //   focus: teal[200],
    //   focusOpacity: 0.04,
    //   selected: teal[200],
    //   selectedOpacity: 0.5,
    // },
  },
  shape: { borderRadius: 2 },
});

export default theme;
