/* eslint-disable no-unused-vars */
import {
  ListStatus,
  FormWriteStatus,
  DetailsStatus,
  FormStatus,
} from './constants';

import {
  LIST_LOADING,
  LIST_LOADED_SUCCESS,
  LIST_LOADED_ERROR,

  DETAILS_SELECTED,
  DETAILS_LOADING,
  DETAILS_LOADED_SUCCESS,
  DETAILS_LOADED_ERROR,

  BANNER_DELETING,
  BANNER_DELETED_SUCCESS,

  FORM_INACTIVE,
  FORM_SUBMITTING,
  FORM_SUBMITTED_SUCCESS,
  FORM_SUBMITTED_ERROR,

  MODAL_NEW_OPEN,
  MODAL_EDIT_OPEN,
  MODAL_CLOSE,
} from './actions';

export const INITIAL_STATE = {
  banners: [],
  banner: null,
  modalOpen: false,
  listState: ListStatus.LOADING,
  bannerFormState: FormWriteStatus.CREATE_DRAFT,
  bannerDetailsState: DetailsStatus.EMPTY,
  formState: FormStatus.INACTIVE,
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case LIST_LOADING:
      return {
        ...state,
        listState: ListStatus.LOADING,
        banners: [],
      };
    case LIST_LOADED_SUCCESS:
      return {
        ...state,
        listState: ListStatus.LOADED_SUCCESS,
        banners: action.payload.data,
      };
    case DETAILS_SELECTED:
      return {
        ...state,
        banner: action.payload.banner,
        banners: action.payload.banners,
        formState: FormStatus.INACTIVE,
        bannerDetailsState: DetailsStatus.SELECTED,
      };
    case DETAILS_LOADING:
      return {
        ...state,
        bannerDetailsState: DetailsStatus.LOADING,
      };
    case DETAILS_LOADED_SUCCESS:
      return {
        ...state,
        banner: action.payload.banner,
        banners: action.payload.banners,
        bannerDetailsState: DetailsStatus.LOADED_SUCCESS,
      };
    case BANNER_DELETING:
      return {
        ...state,
        formState: FormStatus.SUBMITTING,
      };
    case BANNER_DELETED_SUCCESS:
      return {
        ...state,
        banner: null,
        modalOpen: false,
        formState: FormStatus.SUBMITTED_SUCCESS,
        bannerFormState: FormWriteStatus.CREATE_DRAFT,
        bannerDetailsState: DetailsStatus.EMPTY,
      };
    case FORM_INACTIVE:
      return {
        ...state,
        formState: FormStatus.INACTIVE,
      };
    case FORM_SUBMITTING:
      return {
        ...state,
        formState: FormStatus.SUBMITTING,
      };
    case FORM_SUBMITTED_SUCCESS:
      return {
        ...state,
        banner: action.payload.banner,
        banners: action.payload.banners,
        formState: FormStatus.SUBMITTED_SUCCESS,
        bannerFormState: FormWriteStatus.CREATE_DRAFT,
        modalOpen: false,
      };
    case FORM_SUBMITTED_ERROR:
      return {
        ...state,
        formState: FormStatus.SUBMITTED_ERROR,
      };
    case MODAL_NEW_OPEN:
      return {
        ...state,
        modalOpen: true,
        bannerFormState: FormWriteStatus.CREATE_DRAFT,
      };
    case MODAL_EDIT_OPEN:
      return {
        ...state,
        modalOpen: true,
        bannerFormState: FormWriteStatus.EDIT_PUBLISHED,
      };
    case MODAL_CLOSE:
      return {
        ...state,
        modalOpen: false,
        bannerFormState: FormWriteStatus.CREATE_DRAFT,
      };
    default:
      return state;
  }
};
