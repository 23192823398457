/* eslint-disable no-unused-vars */
import { mutate } from 'swr';
import { Product } from '../types';
import { useStateValue } from '../index';
import {
  draftProductAdded,
  draftProductDeleted,
  formSubmitted,
  formSubmittedWithSuccess,
  formSubmittedWithError,
  formReset,
  productDeleteConfirmed,
  productDeletedWithSuccess,
} from '../actions';
import {
  FormWriteStatus,
  FormStatus,
  PACKAGES_API,
} from '../constants';
import { mapProductFormFieldsToProduct } from '../mappers';
import httpClient from '../../../../utils/httpClient';
import useAuth from '../../../../store/auth/hooks/useAuth';
import useAlert from '../../../../store/alert/hooks/useAlert';

const useProductForm = () => {
  const { jwt } = useAuth();
  const { state, dispatch } = useStateValue();
  const { showSuccessAlert, showFailureAlert } = useAlert();

  const handle500 = ({ _ }: any) => {
    dispatch(formSubmittedWithError());
    showFailureAlert();
  };
  const refetch = () => mutate(`${PACKAGES_API}?page=${state.pagination.currentPage}&search_key=${state.query}`);
  const refetchDetails = () => mutate(`${PACKAGES_API}/${state.product.id}`);

  const deleteProduct = () => {
    const { id } = state.products.find((p: any) => p.selected);
    const url = `${PACKAGES_API}/${id}`;

    const handle200 = (response: any) => {
      if (response.data) {
        dispatch(productDeletedWithSuccess());
        refetch();
        showSuccessAlert();
      } else if (response.message) {
        dispatch(formSubmittedWithError());
        showFailureAlert();
      }
    };

    dispatch(productDeleteConfirmed());

    httpClient({ url, jwt, method: 'DELETE' })
      .then((res: any) => handle200(res))
      .catch((res) => handle500(res));
  };

  const deleteDraftProduct = () => {
    const products = state.products.filter((i: any) => i.selected === false);
    dispatch(draftProductDeleted(products));
  };

  const submitForm = ({ fields, pageTaxonomies, categoryTaxonomies, subcategoryTaxonomies }: any) => {
    const product = mapProductFormFieldsToProduct({
      pages: pageTaxonomies,
      categories: categoryTaxonomies,
      subcategories: subcategoryTaxonomies,
      data: fields,
    });

    if (state.productFormState === FormWriteStatus.CREATE_DRAFT) {
      const products = [product, ...state.products].map((p: any) => ({ ...p, selected: p.id === null }));
      dispatch(draftProductAdded({ products, product }));
      return;
    }

    if (state.productFormState === FormWriteStatus.EDIT_DRAFT) {
      const updatedProduct = { ...product, materials: state.product.materials };
      const products = state.products.map((p: any) => (p.id === null ? product : p));
      dispatch(draftProductAdded({ products, product: updatedProduct }));
      return;
    }

    if (state.productFormState === FormWriteStatus.EDIT_PUBLISHED) {
      const url = `${PACKAGES_API}/${product.id}`;

      const handle200 = (response: any) => {
        if (response.data) {
          const updatedProduct = { ...product, materials: state.product.materials };
          const products = state.products.map((p: any) => (p.id === updatedProduct.id ? updatedProduct : p));
          dispatch(formSubmittedWithSuccess({ products, product: updatedProduct }));
          refetch();
          refetchDetails();
          showSuccessAlert();
        } else if (response.message) {
          dispatch(formSubmittedWithError());
          showFailureAlert();
        }
      };

      const body: any = {};
      body.subcategoryId = product.subcategoryId;
      body.productId = product.productId;
      body.title = product.title;
      body.description = product.description;
      body.notes = product.notes;
      body.image = product.image;
      body.needAuth = product.needAuth;
      body.password = product.password;
      body.searchMetadata = product.metadata;
      body.isPublished = product.isPublished;

      dispatch(formSubmitted());

      httpClient({ url, jwt, method: 'PUT', body })
        .then((res: any) => handle200(res))
        .catch((error) => handle500(error));
    }
  };

  const submitCreateProduct = () => {
    const url = PACKAGES_API;
    const { product } = state;

    const body = {
      subcategoryId: product.subcategoryId,
      productId: product.productId,
      title: product.title,
      description: product.description,
      notes: product.notes,
      image: product.image,
      needAuth: product.needAuth ? 1 : 0,
      isPublished: product.isPublished ? 1 : 0,
      password: product.password,
      searchMetadata: product.metadata,
      materials: product.materials.map((material: any) => ({
        productId: material.productId,
        title: material.title,
        header: material.header,
        image: material.image,
        htmlData: material.html,
        materialUrls: material.assets.map((asset: any) => ({
          title: asset.title,
          content: asset.content,
          type: asset.type,
          size: asset.size,
          buttonType: asset.buttonType,
          instruction: asset.instruction,
          label: asset.label,
        })),
      })),
    };

    const handle200 = (response: any) => {
      if (response.data) {
        const { id } = response.data;
        const filteredProducts = state.products.filter((p: any) => p.selected === false);
        const updatedProduct = { ...product, status: 'PUBLISHED', id };
        const products = [updatedProduct, ...filteredProducts];
        dispatch(formSubmittedWithSuccess({ products, product: updatedProduct }));
        refetch();
        showSuccessAlert();
      } else if (response.message) {
        dispatch(formSubmittedWithError());
        showFailureAlert();
      }
    };

    dispatch(formSubmitted());

    httpClient({ url, jwt, method: 'POST', body })
      .then((response: any) => handle200(response))
      .catch((error) => handle500(error));
  };

  const resetFormState = () => dispatch(formReset());

  return {
    product: state.product as Product,
    submitting: state.formState === FormStatus.SUBMITTING,
    submittedWithSuccess: state.formState === FormStatus.SUBMITTED_SUCCESS,
    submittedWithError: state.formState === FormStatus.SUBMITTED_ERROR,
    submitForm,
    resetFormState,
    deleteProduct,
    deleteDraftProduct,
    submitCreateProduct,
  };
};

export default useProductForm;
